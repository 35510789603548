// create array of objects with client name and logo width and height from figma file
const clientLogoSizes = [
  {
    name: 'Olipop',
    width: 92,
    height: 36,
  },
  {
    name: 'Dave',
    width: 91,
    height: 24,
  },
  {
    name: 'Duke',
    width: 47,
    height: 40,
  },
  {
    name: 'Graza',
    width: 99,
    height: 27,
  },
  {
    name: 'Hims&Hers',
    width: 115,
    height: 18,
  },
  {
    name: 'Hydrow',
    width: 111,
    height: 27,
  },
  {
    name: 'JamesBeard',
    width: 107,
    height: 35,
  },
  {
    name: 'Lemonade',
    width: 116,
    height: 26,
  },
  {
    name: 'Magic Spoon',
    width: 92,
    height: 43,
  },
  {
    name: 'Magnolia Bakery',
    width: 104,
    height: 34,
  },
  {
    name: 'Native',
    width: 99,
    height: 22,
  },
  {
    name: 'Pattern',
    width: 110,
    height: 34,
  },
  {
    name: 'Ramp',
    width: 114,
    height: 31,
  },
  {
    name: 'Soundcloud',
    width: 126,
    height: 15,
  },
  {
    name: 'Spindrift',
    width: 115,
    height: 31,
  },
  {
    name: 'Stumptown',
    width: 116,
    height: 25,
  },
  {
    name: 'Sweetgreen',
    width: 121,
    height: 18,
  },
  {
    name: 'The Infatuation',
    width: 122,
    height: 23,
  },
  {
    name: 'Room to Read',
    width: 87.5,
    height: 56,
  },
  {
    name: 'Univision',
    width: 83.594202898551,
    height: 56,
  },
  {
    name: 'Goorin',
    width: 201,
    height: 40,
  },
  {
    name: 'MSU',
    width: 85.166666666667,
    height: 56,
  },
  {
    name: 'Berklee',
    width: 101,
    height: 24,
  },
  {
    name: 'Togethxr',
    width: 154.33333333333,
    height: 16,
  },
];

export const clientLogos = clientLogoSizes.map((client, idx) => {
  return {
    ...client,
    id: `${client.name}-${idx}`,
    alt: `${client.name} Logo`,
    src: `/assets/client-logos/Property 1=Logo, Property 2=${client.name}.svg`,
  };
});

export const limitedLogos = clientLogos.sort(() => Math.random() - Math.random()).slice(0, 16);
