import AnnotationBar from '../../../public/assets/homepage-hero/annotationbar.png';
import Bag from '../../../public/assets/homepage-hero/bag.jpg';
import Box1 from '../../../public/assets/homepage-hero/box1.png';
import Comment from '../../../public/assets/homepage-hero/comment.png';
import Comment2 from '../../../public/assets/homepage-hero/comment-2.png';
import Comment3 from '../../../public/assets/homepage-hero/comment-3.png';
import Cursor from '../../../public/assets/homepage-hero/cursor.png';
import CustomFields from '../../../public/assets/homepage-hero/customfields.png';
import Graphic from '../../../public/assets/homepage-hero/graphic.jpg';
import Lunch from '../../../public/assets/homepage-hero/lunch.jpg';
import ManInField from '../../../public/assets/homepage-hero/man-in-field.png';
import PeachCandles from '../../../public/assets/homepage-hero/peach-candles.png';
import Peppers from '../../../public/assets/homepage-hero/peppers.jpg';
import Photo from '../../../public/assets/homepage-hero/photo.jpg';
import Photo2 from '../../../public/assets/homepage-hero/photo2.jpg';
import Photo3 from '../../../public/assets/homepage-hero/photo3.jpg';
import Photo4 from '../../../public/assets/homepage-hero/photo4.jpg';
import Photo5 from '../../../public/assets/homepage-hero/photo5.jpg';
import ProfilePic from '../../../public/assets/homepage-hero/profile-pic.png';
import Shape1 from '../../../public/assets/homepage-hero/shape1.png';
import Shape2 from '../../../public/assets/homepage-hero/shape2.png';
import Status from '../../../public/assets/homepage-hero/status.png';
import UgcVideo from '../../../public/assets/homepage-hero/ugcvideo.jpg';
import VersionStackV2 from '../../../public/assets/homepage-hero/version-stack-v2.png';
import VersionStackV3 from '../../../public/assets/homepage-hero/version-stack-v3.png';
import VideoPlayer from '../../../public/assets/homepage-hero/videoplayer.png';

export const heroImages = {
  AnnotationBar,
  Bag,
  Box1,
  Comment2,
  Comment3,
  Comment,
  Cursor,
  CustomFields,
  Graphic,
  Lunch,
  ManInField,
  PeachCandles,
  Peppers,
  Photo,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
  ProfilePic,
  Shape1,
  Shape2,
  Status,
  UgcVideo,
  VersionStackV2,
  VersionStackV3,
  VideoPlayer,
};
