import { Box, Text } from '@air/marketing-primitives';
import { noop } from 'lodash';
import { ReactNode } from 'react';

import * as StyledHeaderMenuBanner from '../Header/HeaderMenuBanner.styles';

export type HeaderMenuBannerProps = Pick<StyledHeaderMenuBanner.RootProps, 'css'> & {
  cta?: ReactNode;
  description?: string;
  image?: ReactNode;
  isDemo?: boolean;
  onDemoDialogOpen?: () => void;
  title: string;
};

export const HeaderMenuBanner = ({
  css,
  cta,
  description,
  image,
  isDemo,
  onDemoDialogOpen = noop,
  title,
}: HeaderMenuBannerProps) => {
  return (
    <>
      <StyledHeaderMenuBanner.Root css={css}>
        {image && <Box css={{ flexShrink: 0, marginRight: 16 }}>{image}</Box>}
        <Box>
          <Text as="div" css={{ marginBottom: 4, color: '$pigeon900', fontWeight: '$semibold' }} size="ui-20">
            {title}
          </Text>
          <Text as="div" css={{ color: '$pigeon900' }} size="ui-14">
            {description}
          </Text>
          {cta && (
            <Box css={{ marginTop: 16 }} onClick={isDemo ? onDemoDialogOpen : undefined}>
              {cta}
            </Box>
          )}
        </Box>
      </StyledHeaderMenuBanner.Root>
    </>
  );
};
