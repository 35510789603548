'use client';
import { usePlasmicCanvas } from '@air/plasmic';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Image, { StaticImageData } from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

gsap.registerPlugin(ScrollTrigger);
import sceneOneImage1 from '../../../../public/assets/features-scroller/scene-1/scroller-scene-1-img-1.png';
import sceneOneImage2 from '../../../../public/assets/features-scroller/scene-1/scroller-scene-1-img-2.png';
import sceneOneImage3 from '../../../../public/assets/features-scroller/scene-1/scroller-scene-1-img-3.png';
import sceneTwoImageThumbV1 from '../../../../public/assets/features-scroller/scene-2/scroll-scene-2-thumb-v1.png';
import sceneTwoImageThumbV2 from '../../../../public/assets/features-scroller/scene-2/scroll-scene-2-thumb-v2.png';
import sceneTwoImageThumbV3 from '../../../../public/assets/features-scroller/scene-2/scroll-scene-2-thumb-v3.png';
import sceneTwoImageMain from '../../../../public/assets/features-scroller/scene-2/scroller-scene-2-main.png';
import sceneThreeBoards from '../../../../public/assets/features-scroller/scene-3/boards.png';
import sceneThreeCommentPanel from '../../../../public/assets/features-scroller/scene-3/comment-panel.png';
import sceneThreeCommentsSelected from '../../../../public/assets/features-scroller/scene-3/comments-selected.png';
import sceneThreeCustomFields from '../../../../public/assets/features-scroller/scene-3/custom fields.png';
import sceneThreeInfo from '../../../../public/assets/features-scroller/scene-3/info.png';
import sceneThreePill from '../../../../public/assets/features-scroller/scene-3/pill.png';
import sceneThreeTags from '../../../../public/assets/features-scroller/scene-3/tags.png';
import sceneFourBoardsPanel from '../../../../public/assets/features-scroller/scene-4/boards-panel.png';
import sceneFourBoardsSelected from '../../../../public/assets/features-scroller/scene-4/boards-selected.png';
import sceneFourComments from '../../../../public/assets/features-scroller/scene-4/comments.png';
import sceneFiveCustomFieldsPanel from '../../../../public/assets/features-scroller/scene-5/custom-fields-panel.png';
import sceneFiveCustomFieldsSelected from '../../../../public/assets/features-scroller/scene-5/custom-fields-selected.png';
import sceneSixApproval from '../../../../public/assets/features-scroller/scene-6/approval.png';
import sceneSixImage from '../../../../public/assets/features-scroller/scene-6/scene-6-main.png';
import sceneSixStatus from '../../../../public/assets/features-scroller/scene-6/status.png';

type toolbarIcon = {
  sectionName: string;
  panel: StaticImageData | null;
  mainIcon: StaticImageData;
  selectedIcon: StaticImageData | null;
  pillIcon?: StaticImageData | null;
};

const toolbarIcons: toolbarIcon[] = [
  {
    sectionName: 'boards',
    panel: sceneFourBoardsPanel,
    mainIcon: sceneThreeBoards,
    selectedIcon: sceneFourBoardsSelected,
    pillIcon: null,
  },
  {
    sectionName: 'comments',
    panel: sceneThreeCommentPanel,
    mainIcon: sceneFourComments,
    selectedIcon: sceneThreeCommentsSelected,
    pillIcon: sceneThreePill,
  },
  {
    sectionName: 'custom fields',
    panel: null,
    mainIcon: sceneThreeCustomFields,
    selectedIcon: sceneFiveCustomFieldsSelected,
    pillIcon: null,
  },
  {
    sectionName: 'info',
    panel: null,
    mainIcon: sceneThreeInfo,
    selectedIcon: null,
    pillIcon: null,
  },
  {
    sectionName: 'tags',
    panel: null,
    mainIcon: sceneThreeTags,
    selectedIcon: null,
    pillIcon: null,
  },
];

export const FeatureScrollerPanel = ({
  panel,
  sectionName,
  sceneNumber,
}: {
  panel: StaticImageData;
  sectionName: string;
  sceneNumber: number;
}) => {
  return (
    <div className="col-start-2 flex flex-row flex-nowrap items-center justify-end relative flex-shrink-0 flex-grow-0 h-full">
      <div
        className={`scene-${sceneNumber}-panel img flex rounded pl-box-shadow w-[93%] max-w-[392px] absolute top-1/2 translate-y-[-50%] -left-8`}
      >
        <Image src={panel} className="h-full w-full overflow-hidden" alt="" />
      </div>
      <div className={`scene-${sceneNumber}-toolbar toolbar grid gap-[10px] md:gap-6`}>
        {toolbarIcons.map((icon, idx) => {
          return (
            <div key={idx} className="w-4 h-4 md:w-10 md:h-10 relative">
              <div className="h-full w-full relative">
                <div className="relative w-4 h-4 md:w-10 md:h-10 pl-box-shadow rounded lg:rounded-[8px] overflow-hidden">
                  <Image
                    src={icon.sectionName === sectionName && icon.selectedIcon ? icon.selectedIcon : icon.mainIcon}
                    className="w-full h-full"
                    layout="fill"
                    alt={`${icon.sectionName} icon`}
                  />
                </div>
                {icon.pillIcon && (
                  <div className="absolute w-2 h-[6px] md:w-[28px] md:h-[21px] top-0 right-0 lg:top-[-4px] lg:right-[-4px] pl-box-shadow--lighter">
                    <div className="w-full h-full relative flex">
                      <Image src={icon.pillIcon} alt={`${icon.sectionName} icon`} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const _FeatureScroller = () => {
  const wrapper = useRef<HTMLDivElement>(null);
  const gallery = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const headline = { __html: '<h2>Watch your work <span class="pl-header-w-highlight__highlight">flow</span></h2>' };
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [windowWidth]);

  useIsomorphicLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      gsap.set('.scene-1-image', { opacity: 1, zIndex: 1 });
      gsap.set('.scene-1-image--primary', { zIndex: 2, scale: 1 });
      gsap.set('.scene-1-image--secondary', { yPercent: -50, x: 0, scale: 1 });
      gsap.set('.scene-2-image', { scale: 1.5, opacity: 0 });
      gsap.set('.scene-2-thumbs-header', { opacity: 0, x: '100%' });
      gsap.set('.scene-2-thumbs .img', { opacity: 0, y: -150 });
      gsap.set('.scene-2-wrapper', { marginLeft: 'auto', marginRight: 'auto' });
      gsap.set('.scene-3-panel', { opacity: 0 });
      gsap.set('.scene-3-toolbar', { opacity: 0 });
      gsap.set('.scene-4-panel', { opacity: 0 });
      gsap.set('.scene-4-toolbar', { opacity: 0 });
      gsap.set('.scene-5-panel', { opacity: 0 });
      gsap.set('.scene-5-toolbar', { opacity: 0 });
      gsap.set('.scene-6-wrapper', { opacity: 0 });
      gsap.set('.scene-6-badge', { opacity: 0 });
      gsap.set('.scene-6-comment', { opacity: 0 });
      gsap.set('.pl-header-w-highlight', { opacity: 1 });
      gsap.set('.scene-pagination-dot', { backgroundColor: '#E5E5E5' });
      let timeline = gsap.timeline({
        smoothChildTiming: true,
        ease: 'none',
        scrollTrigger: {
          trigger: '.pin',
          pin: true,
          start: 'top top+=100',
          end: '+=7500',
          scrub: 0.5,
          toggleActions: 'play none none reverse',
          snap: {
            snapTo: 'labels',
            duration: { min: 0.1, max: 3 },
            delay: 0,
            ease: 'power1.inOut',
          },
        },
      });
      const sceneOne = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.25, delay: 0 } });
        tl.to('.scene-pagination-dot--1', { backgroundColor: '#020039' });
        tl.to('.scene-1-image--secondary--right', {
          scale: 1.2,
          x: '-40%',
          yPercent: 0,
          opacity: 0,
          stagger: 0.1,
        });
        tl.to('.scene-1-image--secondary--left', {
          scale: 1.2,
          x: '40%',
          yPercent: 0,
          opacity: 0,
          stagger: 0.1,
        });
        tl.to('.scene-1-image--primary', { scale: 1.5 });
        tl.to('.scene-1-image', { scale: 0.3, opacity: 0 });
        return tl;
      };

      const sceneTwo = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.5 } });
        tl.to('.scene-pagination-dot--1', { backgroundColor: '#E5E5E5' });
        tl.to('.scene-pagination-dot--2', { backgroundColor: '#020039' });
        tl.to('.scene-2-image', { scale: 1, opacity: 1, duration: 0.25 });
        tl.to('.scene-2-thumbs-header', { opacity: 1, x: 0, duration: 0.1, delay: 0 });
        gsap.utils
          .toArray('.scene-2-thumbs .img')
          .reverse()
          .map((el: any) => {
            tl.to(el, { opacity: 1, y: 0, stagger: 0.1, duration: 0.1, delay: 0 });
          });
        tl.to('.scene-2-wrapper', { marginLeft: 0, duration: 0.75, delay: 0 });
        tl.to('.scene-3-toolbar', { opacity: 1, duration: 0.2, delay: 0 });
        return tl;
      };

      const sceneThree = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.5 } });
        tl.to('.scene-3-panel', { opacity: 1, delay: 1 });
        tl.to('.scene-pagination-dot--2', { backgroundColor: '#E5E5E5' });
        tl.to('.scene-pagination-dot--3', { backgroundColor: '#020039' });
        return tl;
      };

      const sceneFour = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.5 } });
        tl.to('.scene-3-panel', { opacity: 0, delay: 2 });
        tl.to('.scene-4-toolbar', { opacity: 1, delay: 0 });
        tl.to('.scene-pagination-dot--3', { backgroundColor: '#E5E5E5' });
        tl.to('.scene-pagination-dot--4', { backgroundColor: '#020039' });
        tl.to('.scene-3-toolbar', { opacity: 0, duration: 0, delay: 0 });
        tl.to('.scene-4-panel', { opacity: 1 });
        return tl;
      };

      const sceneFive = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.5 } });
        tl.to('.scene-4-panel', { opacity: 0, delay: 2 });
        tl.to('.scene-5-toolbar', { opacity: 1, delay: 0 });
        tl.to('.scene-pagination-dot--4', { backgroundColor: '#E5E5E5' });
        tl.to('.scene-pagination-dot--5', { backgroundColor: '#020039' });
        tl.to('.scene-4-toolbar', { opacity: 0, duration: 0, delay: 0 });
        tl.to('.scene-5-panel', { opacity: 1 });
        return tl;
      };
      const sceneSix = () => {
        let tl = gsap.timeline({ defaults: { duration: 0.5 } });
        tl.to('.scene-5-panel', { opacity: 0, delay: 2 });
        tl.to('.scene-pagination-dot--5', { backgroundColor: '#E5E5E5' });
        tl.to('.scene-pagination-dot--6', { backgroundColor: '#020039' });
        tl.to('.scene-5-toolbar', { opacity: 0, delay: 0 });
        tl.to('.scene-2-wrapper', { opacity: 0, delay: 0 });
        tl.to('.scene-6-wrapper', { opacity: 1 });
        tl.to('.scene-6-badge', { opacity: 1, duration: 0.5, delay: 0.75 });
        tl.to('.scene-6-comment', { opacity: 1, duration: 3, delay: 1 });
        tl.to('.scene-6-wrapper', { opacity: 0 });
        tl.to('.scene-pagination-dot--6', { backgroundColor: '#E5E5E5' });
        tl.to('.pl-header-w-highlight', { opacity: 0, duration: 0.5, delay: 0 });

        return tl;
      };

      timeline.add(sceneOne(), 'scene-1');
      timeline.add(sceneTwo(), 'scene-2');
      timeline.add(sceneThree(), 'scene-3');
      timeline.add(sceneFour(), 'scene-4');
      timeline.add(sceneFive(), 'scene-5');
      timeline.add(sceneSix(), 'scene-6');
    }, wrapper); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div ref={wrapper} className="py-[72px] overflow-x-hidden">
      <div ref={gallery} className="relative mx-auto">
        <div className="pin mx-auto">
          <div className="pin__inner max-w-[700px] h-[calc(100vh-100px)] flex flex-nowrap flex-col mx-auto pb-8">
            <div
              className="pl-header-w-highlight text-header-md lg:text-header-lg font-display font-semibold text-center w-full px-8 mb-12"
              dangerouslySetInnerHTML={headline}
            ></div>
            <div className="scenes w-full h-full relative">
              <div className="scene scene-1 absolute inset-0 flex flex-row flex-nowrap justify-center w-full h-full">
                <div className="scene-1-inner relative w-fit h-fit overflow-visible flex flex-col">
                  <div className="scene-1-image scene-1-image--secondary scene-1-image--secondary--right rounded overflow-hidden absolute pl-box-shadow w-[calc(100%*(295/375))] top-[65%] lg:top-[55%] -right-1/2 aspect-[156/161] max-w-[295px]">
                    <Image src={sceneOneImage1} className="rounded overflow-hidden pl-box-shadow" alt="scene 1 image" />
                  </div>
                  <div className="scene-1-image scene-1-image--secondary scene-1-image--secondary--left rounded overflow-hidden absolute pl-box-shadow top-1/2 -left-1/2 aspect-[250/167] w-[calc(100%*(470/375))] max-w-[470px] z-10">
                    <Image src={sceneOneImage3} className="rounded overflow-hidden pl-box-shadow" alt="scene 1 image" />
                  </div>
                  <div className="scene-1-image scene-1-image--primary mx-auto rounded overflow-hidden pl-box-shadow relative h-full w-auto aspect-[375/504] max-w-[198px] lg:max-w-[375px]">
                    <Image
                      src={sceneOneImage2}
                      style={{ objectFit: 'contain', maxHeight: '100%', width: 'auto' }}
                      className="rounded overflow-hidden pl-box-shadow"
                      alt="scene 1 image"
                    />
                  </div>
                </div>
              </div>
              <div className="scene scene-2 px-8 mx-auto absolute inset-0 w-full h-full">
                <div className="pin-wrapper-2 flex justify-center items-center h-full">
                  <div className="scene-2-wrapper aspect-[375/550] w-1/2 h-auto flex flex-col">
                    <div className="scene-2-image img rounded-[8px] bg-transparent overflow-hidden w-full h-auto relative mb-4 md:mb-6">
                      <Image
                        src={sceneTwoImageMain}
                        className="rounded-[8px] bg-transparent overflow-hidden pl-box-shadow"
                        alt=""
                      />
                    </div>
                    <p className="scene-2-thumbs-header text-body-sm uppercase font-copy font-semibold mb-2">
                      Versions
                    </p>
                    <div className="scene-2-thumbs flex flex-row flex-nowrap h-auto w-full max-h-[85px] pr-[10%] gap-2">
                      <div className="img rounded-[8px] bg-transparent relative w-auto h-full">
                        <Image
                          src={sceneTwoImageThumbV3}
                          className="rounded-[8px] bg-transparent overflow-hidden pl-box-shadow--lighter"
                          alt=""
                        />
                      </div>
                      <div className="img rounded-[8px] bg-transparent relative w-auto h-full overflow-hidden">
                        <Image
                          src={sceneTwoImageThumbV2}
                          className="rounded-[8px] bg-transparent overflow-hidden pl-box-shadow--lighter"
                          alt=""
                        />
                      </div>
                      <div className="img rounded-[8px] bg-transparent relative w-auto h-full overflow-hidden">
                        <Image
                          src={sceneTwoImageThumbV1}
                          className="rounded-[8px] bg-transparent overflow-hidden pl-box-shadow--lighter"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scene scene-3 px-12 mx-auto flex items-center justify-center absolute inset-0 w-full h-full">
                <div className="pin-wrapper-3 w-full h-full flex items-center lg:block">
                  <div className="scene-3-inner grid grid-cols-2 item-start lg:items-center h-full w-full">
                    <FeatureScrollerPanel panel={sceneThreeCommentPanel} sceneNumber={3} sectionName="comments" />
                  </div>
                </div>
              </div>
              <div className="scene scene-4 px-12 mx-auto flex items-center justify-center absolute inset-0 w-full h-full">
                <div className="pin-wrapper-4 w-full h-full flex items-center lg:block">
                  <div className="scene-4-inner grid grid-cols-2 item-start lg:items-center h-full w-full">
                    <FeatureScrollerPanel panel={sceneFourBoardsPanel} sceneNumber={4} sectionName="boards" />
                  </div>
                </div>
              </div>
              <div className="scene scene-5 px-12 mx-auto flex items-center justify-center absolute inset-0 w-full h-full">
                <div className="pin-wrapper-4 w-full h-full flex items-center lg:block">
                  <div className="scene-5-inner grid grid-cols-2 item-start lg:items-center h-full w-full">
                    <FeatureScrollerPanel
                      panel={sceneFiveCustomFieldsPanel}
                      sceneNumber={5}
                      sectionName="custom fields"
                    />
                  </div>
                </div>
              </div>
              <div className="scene scene-6 px-12 mx-auto absolute inset-0 w-full h-full">
                <div className="pin-wrapper-6 flex justify-center items-center h-full">
                  <div className="scene-6-wrapper w-full lg:max-w-[406px] flex flex-col items-center">
                    <div className="scene-6-image max-w-[406px] img rounded w-full mb-4 md:mb-6 relative">
                      <div className="scene-6-badge absolute top-4 right-4 w-[81px] z-10">
                        <Image
                          src={sceneSixStatus}
                          className="rounded overflow-hidden pl-box-shadow w-full h-auto"
                          alt=""
                        />
                      </div>
                      <div className="scene-6-comment absolute top-1/2 left-[-1rem] z-10 w-[calc(100%*(250/390))]">
                        <Image src={sceneSixApproval} className="rounded overflow-hidden pl-box-shadow" alt="" />
                      </div>
                      <Image
                        src={sceneSixImage}
                        className="rounded overflow-hidden pl-box-shadow"
                        style={{
                          position: 'relative',
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="scene-pagination absolute top-1/2 right-4 translate-y-[-50%] z-10">
              <div className="scene-pagination-inner flex flex-col flex-nowrap justify-center items-center gap-4">
                <div className="scene-pagination-dot scene-pagination-dot--1 w-2 h-2 rounded-full bg-gray-300"></div>
                <div className="scene-pagination-dot scene-pagination-dot--2 w-2 h-2 rounded-full bg-gray-300"></div>
                <div className="scene-pagination-dot scene-pagination-dot--3 w-2 h-2 rounded-full bg-gray-300"></div>
                <div className="scene-pagination-dot scene-pagination-dot--4 w-2 h-2 rounded-full bg-gray-300"></div>
                <div className="scene-pagination-dot scene-pagination-dot--5 w-2 h-2 rounded-full bg-gray-300"></div>
                <div className="scene-pagination-dot scene-pagination-dot--6 w-2 h-2 rounded-full bg-gray-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeatureScroller = () => {
  const { isEditor } = usePlasmicCanvas();

  if (isEditor) {
    return <div>Unable to load in Plasmic due to performance impact</div>;
  }

  return <_FeatureScroller />;
};
