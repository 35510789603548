import { Formik } from 'formik';

import { PlasmicHeaderNavigationItemType } from '../../../../constants/plasmic-navigation';
import { NavigationBuilderFormContent } from './NavigationBuilderFormContent';

type NavigationFormProps = {
  navigationItem: PlasmicHeaderNavigationItemType;
  onSubmit: (values: PlasmicHeaderNavigationItemType) => void;
  setHasChanged: (hasChanged: boolean) => void;
  onMoveUpNavigationItem: () => void;
  onMoveDownNavigationItem: () => void;
  onRemoveNavigationItem: () => void;
  onDuplicateNavigationItem: () => void;
};

export function NavigationBuilderForm({
  navigationItem,
  onSubmit,
  setHasChanged,
  onMoveUpNavigationItem,
  onMoveDownNavigationItem,
  onRemoveNavigationItem,
  onDuplicateNavigationItem,
}: NavigationFormProps) {
  return (
    <Formik initialValues={navigationItem} enableReinitialize onSubmit={onSubmit}>
      {({ values, initialValues, resetForm, setValues }) => (
        <NavigationBuilderFormContent
          values={values}
          initialValues={initialValues}
          setHasChanged={setHasChanged}
          onDiscard={resetForm}
          onMoveUpNavigationItem={onMoveUpNavigationItem}
          onMoveDownNavigationItem={onMoveDownNavigationItem}
          onRemoveNavigationItem={onRemoveNavigationItem}
          onDuplicateNavigationItem={onDuplicateNavigationItem}
          setValues={setValues}
        />
      )}
    </Formik>
  );
}
