import { DemoDialog } from '@air/marketing-tech-debt/src/components/dialogs/DemoDialog';
import { Video } from '@air/plasmic/src/components/react-player/Video';
import { ChilipiperLeadsource } from '@air/plasmic-chilipiper';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import { useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

import { heroImages } from '../../constants/heroImages';
import { getRandomNumber } from '../../utils/getRandomNumber';
const {
  AnnotationBar,
  Cursor,
  Graphic,
  ManInField,
  PeachCandles,
  Photo,
  Photo2,
  Photo3,
  Photo4,
  Photo5,
  ProfilePic,
  VersionStackV2,
  VersionStackV3,
  VideoPlayer,
} = heroImages;

export type HeroProps = {
  headline?: string;
  ctaLabel?: string;
  ctaType?: 'internal' | 'external';
  videoUrl?: string;
  videoUrl2?: string;
};

export const Hero = ({ headline, ctaLabel, videoUrl, videoUrl2 }: HeroProps) => {
  const [isDemoDialogOpened, setIsDemoDialogOpened] = useState(false);
  const hero = useRef<HTMLDivElement>(null);
  const [hasDoneInitialAnimation, setHasDoneInitialAnimation] = useState(false);
  const markupGraphicVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const getElementCenter = (element: HTMLDivElement) => {
    const dims = element.getBoundingClientRect();
    return {
      width: dims.width,
      height: dims.height,
      x: dims.width / 2,
      y: dims.height / 2,
    };
  };

  useIsomorphicLayoutEffect(() => {
    if (!hero.current) return;
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

    const ctx = gsap.matchMedia();
    const miscAnimations = {
      autoAlpha: 1,
      y: 0,
      z: 0,
      ease: 'expo',
    };

    ctx.add(
      '(min-width: 920px)',
      () => {
        let cardColumns = hero.current && gsap.utils.toArray('.card-column');
        const animatedMedia = gsap.utils.toArray('.animate-media');

        animatedMedia.forEach((media) => {
          const BOUND_PADDING = 275;
          const containerDims = getElementCenter(hero.current!);
          const mediaDims = (media as HTMLDivElement).getBoundingClientRect();

          /**
           * Asset is left of center
           */
          if (mediaDims.x < containerDims.x) {
            let heightBounds = 0;
            const lowerBoundWidth = mediaDims.width - BOUND_PADDING;
            const upperBoundWidth = containerDims.x - BOUND_PADDING - mediaDims.width / 2;
            const widthBounds = getRandomNumber(lowerBoundWidth, upperBoundWidth);

            if (mediaDims.top <= 200) {
              heightBounds = -getRandomNumber(-150, 50);
            } else {
              heightBounds = -getRandomNumber(100, 200);
            }

            const bounds = {
              x: widthBounds,
              y: heightBounds,
            };

            (media as HTMLDivElement).style.transform = `translate(${bounds.x}px, ${bounds.y}px)`;
          }

          /**
           * Asset is right of cetner
           */
          if (mediaDims.x > containerDims.x) {
            let heightBounds = 0;
            const lowerBoundWidth = -(containerDims.x + BOUND_PADDING - (mediaDims.left + mediaDims.width));
            const upperBoundWidth = mediaDims.width;
            const widthBounds = getRandomNumber(lowerBoundWidth, upperBoundWidth);

            if (mediaDims.top <= 200) {
              // heightBounds = getRandomNumber(0, 50)
              heightBounds = -getRandomNumber(-150, 50);
            } else {
              heightBounds = -getRandomNumber(50, 200);
            }

            const bounds = {
              x: -widthBounds,
              y: heightBounds,
            };

            (media as HTMLDivElement).style.transform = `translate(${bounds.x}px, ${bounds.y}px)`;
          }
        });

        if (!cardColumns) return;

        /**
         * Preset elements to hidden
         */
        gsap.set('.hero-title', { opacity: 0 });
        gsap.set('.status-tag', { opacity: 0, y: -100, z: 0 });
        gsap.set('.ui-element', { opacity: 0, y: -100, z: 0 });
        gsap.set('.feature-bubble', { opacity: 0, y: 100, z: 0 });
        gsap.set('.svg-comment', { opacity: 0 });
        gsap.set('.comment', { opacity: 0 });
        gsap.set('.animate-header', { opacity: 0 });

        const primaryTimeline = gsap.timeline();
        const tl = gsap.timeline();
        const headlineTl = gsap.timeline();
        const svgTimeline = gsap.timeline({
          onComplete: () => setHasDoneInitialAnimation(true),
        });

        tl.to(animatedMedia, {
          x: 'auto',
          y: 0,
          z: 0,
          rotate: 0,
          maxWidth: 'none',
          stagger: 0.008,
          delay: 1,
          duration: 0.55,
          ease: 'Elastic.easeOut.config(1, 1)',
        });
        tl.to('.animate-header', {
          opacity: 1,
          duration: 0.4,
        });
        tl.to(
          '.status-tag',
          Object.assign(miscAnimations, {
            delay: 0.13,
          }),
        );

        tl.to(
          '.feature-bubble',
          Object.assign(miscAnimations, {
            ease: 'Back.easeOut.config(2.1)',
            stagger: 0.1,
            duration: 0.75,
          }),
          '-=.15',
        );

        tl.to(
          '.comment-avatar',
          Object.assign(miscAnimations, {
            ease: 'Back.easeOut.config(4.3)',
            duration: 0.15,
          }),
        );

        tl.to(
          '.comment',
          Object.assign(miscAnimations, {
            ease: 'Back.easeOut.config(4.3)',
            display: 'flex',
            duration: 0.2,
          }),
          '-=.2',
        );

        /**
         * Headline Timeline
         */
        headlineTl.to('.hero-title', {
          opacity: 1,
          duration: 0.4,
          delay: 1.2,
        });

        /**
         * SVG Loop Animation related timeline
         */
        svgTimeline.to(
          '.svg-comment-avatar',
          Object.assign(miscAnimations, {
            duration: 0.15,
            delay: 1.15,
          }),
        );

        svgTimeline.to(
          '.svg-comment',
          Object.assign(miscAnimations, {
            ease: 'Back.easeOut.config(4.3)',
            display: 'flex',
            delay: 1.15,
          }),
          '-=.1',
        );

        svgTimeline.to(
          '.ui-element',
          Object.assign(miscAnimations, {
            stagger: 0,
            ease: 'power3.inOut',
            duration: 0.5,
          }),
        );

        /**
         * Add all timelines to primary timeline to run in sync.
         */
        primaryTimeline.add(headlineTl, 0).add(tl, 0).add(svgTimeline, 2);
      },
      hero,
    );

    ctx.add(
      '(max-width: 919px)',
      () => {
        let cardColumns = hero.current && [...hero.current.querySelectorAll('.card-column')];
        if (!cardColumns) return;

        gsap.set('.ui-element', { opacity: 0, y: -100 });
        gsap.set('.status-tag', { opacity: 0, y: -100 });
        gsap.set('.feature-bubble', { opacity: 0, y: 100 });
        gsap.set('.comment', { opacity: 0 });
        gsap.set('.comment-avatar', { opacity: 0, y: -10 });

        const primaryMobileTimeline = gsap.timeline();
        const svgAnimation = gsap.timeline({
          onComplete: () => setHasDoneInitialAnimation(true),
        });

        const tl = gsap.timeline();

        svgAnimation.to(
          '.ui-element',
          Object.assign(miscAnimations, {
            ease: 'power3.inOut',
            duration: 1.5,
          }),
        );

        tl.to('.feature-bubble', {
          opacity: 1,
          y: 0,
          ease: 'expo',
          stagger: 0.25,
          duration: 0.75,
          delay: 0.5,
        });

        tl.to('.status-tag', {
          opacity: 1,
          y: 0,
          ease: 'expo',
        });

        tl.to(
          '.comment-avatar',
          {
            opacity: 1,
            y: 0,
            display: 'flex',
            ease: 'expo',
          },
          '-=.25',
        );

        tl.to(
          '.comment',
          {
            opacity: 1,
            y: 0,
            display: 'flex',
            ease: 'expo',
          },
          '-=.25',
        );

        primaryMobileTimeline.add(svgAnimation, 1).add(tl, 1);
      },
      hero,
    );

    return () => ctx.revert();
  }, []);

  return (
    <div ref={hero} className="hero pt-[120px] lg:px-12 text-jay-900 lg:pb-16 relative">
      <div className="max-w-[1344px] mx-auto">
        <div className="relative z-0 lg:hidden px-8 text-center w-full flex flex-col mb-6 ">
          <h1 className="text-header-md font-display font-semibold mb-6">{headline}</h1>

          <DemoDialog
            isOpen={isDemoDialogOpened}
            leadsource={ChilipiperLeadsource.Homepage}
            onDismiss={() => setIsDemoDialogOpened(false)}
          />
          <button
            className="pl-button pl-button mx-auto"
            onClick={(event) => {
              event.preventDefault();
              setIsDemoDialogOpened(true);
            }}
          >
            {ctaLabel}
          </button>
        </div>
        <div className="relative">
          {/* Mobile Columns */}
          <div className="overflow-x-hidden overflow-y-visible pb-9 flex  md:w-full items-center justify-center relative">
            <div className="grid grid-cols-[175px_175px_175px] gap-3 lg:hidden relative">
              {/* Column 1 */}
              <div className="card-column flex flex-col gap-y-3">
                <div className="relative animate-media will-change-transform">
                  <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                    <Image src={Photo3} priority alt="Sweetgreen storefront" className="w-full object-cover" />
                  </div>
                  <div className="absolute left-1/2 translate-x-[-50%] top-16 lg:top-[25%] w-[calc(100%*(111/134))] lg:w-[calc(100%*(208/250))]">
                    <svg className="" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 75">
                      <motion.path
                        variants={markupGraphicVariants}
                        initial={{ pathLength: 0 }}
                        animate={hasDoneInitialAnimation ? 'visible' : 'hidden'}
                        d="M110.124 13.296c-13.15 0-26.359-.915-39.502-.46-5.376.187-10.737.72-16.108.92-2.883.107-5.77-.15-8.632.297-10.484 1.64-21.439 4.845-31.032 9.44-2.76 1.321-4.2 3.39-6.427 5.382-2.033 1.818-4.478 2.94-5.512 5.599-4.715 12.118 9.985 18.88 18.635 22.637 8.81 3.826 18.22 5.898 27.59 7.79 9.608 1.94 19.155 3.625 28.934 4.381 19.132 1.48 37.814 4.731 57.063 3.408 17.911-1.231 34.502-6.726 51.495-12.17 7.673-2.46 11.392-5.583 16.189-11.658 2.076-2.63 4.633-4.792 6.211-7.816 1.514-2.9.807-6.97.807-10.143 0-5.598-4.928-9.161-9.331-12.008-5.463-3.533-12.399-5.612-18.42-7.925-5.929-2.277-11.705-4.47-17.963-5.571-5.203-.916-10.389-2.157-15.651-2.705-4.816-.501-9.838-.291-14.682-.487-9.677-.391-19.412-.108-29.096-.108H92.538c-1.708 0-3.622-.289-5.163.487"
                        stroke="#33DBDB"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <div className="absolute top-5 lg:top-[12%] w-full left-[-50%] lg:left-[-30%] flex flex-row flex-nowrap items-end gap-2">
                    <div
                      className="svg-comment text-left bubble z-10 rounded-md p-2 text-body-md flex items-center w-fit whitespace-nowrap pl-box-shadow--drop bg-white border border-pigeon-200 text-pigeon-700 h-fit"
                      style={{ opacity: 0, transform: 'translateY(-25px)' }}
                    >
                      Brighten and add contrast
                    </div>
                    <div className="flex-shrink-0 flex-grow-0 w-8 h-8 svg-comment-avatar">
                      <Image
                        src={ProfilePic}
                        priority
                        alt="Profile Picture Example"
                        width={32}
                        height={32}
                        className="w-8 h-8 flex-shrink-0 flex-grow-0"
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-0 w-full right-[17%] ui-element">
                    <Image src={AnnotationBar} priority alt="Annotation Bar" className="w-full h-auto" />
                  </div>
                </div>

                <div className="relative animate-media">
                  <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                    <Image
                      src={Graphic}
                      priority
                      alt="Finding a bit of spring poster"
                      className="w-full object-cover"
                    />
                  </div>
                  <div className="absolute w-8 h-8 right-[-8%] bottom-[5%]">
                    <Image src={Cursor} priority alt="Cursor icon" className="w-full h-auto" />
                  </div>
                </div>

                <div className="rect w-full bg-peacock-50 rounded pl-box-shadow aspect-[176/44] animate-media h-fit overflow-hidden">
                  <Image src={PeachCandles} alt="Peach Candles" className="w-full object-cover" />
                </div>
              </div>

              {/* Column 2 */}
              <div className="card-column flex flex-col gap-y-3 pt-10 z-[1]">
                <div className="relative animate-media will-change-transform">
                  <div className="relative">
                    <div className="image-wrapper relative z-[-1] flex w-full pl-box-shadow rounded overflow-hidden">
                      <Image src={Photo} priority alt="Food on table" className="w-full object-cover" />
                      <div className="absolute status-tag top-4 right-4">
                        <div className="bubble z-[2] rounded px-2 py-1 text-body-md text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop bg-canary-100 text-canary-900">
                          In Progress
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-[-30%] bottom-8 flex flex-col gap-2">
                      <div className="feature-bubble z-[2] rounded-[50px] px-2 py-1 text-body-xs bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                        Marketing
                      </div>
                      <div className="feature-bubble z-[2] rounded-[50px] px-2 py-1 text-body-xs bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                        Product Photography
                      </div>
                      <div className="feature-bubble z-[2] rounded-[50px] px-2 py-1 text-body-xs bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                        Website
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative animate-media will-change-transform">
                  <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit ">
                    <Image src={Photo2} priority alt="Bowl of fresh salad" className="w-full object-cover" />
                  </div>
                  <div className="flex absolute top-[15%] w-full left-[-30%] flex-row flex-nowrap items-end gap-2">
                    <div className="comment text-left bubble z-[2] rounded-md px-2 py-1 text-body-xs border flex items-center w-fit max-w-[195px] pl-box-shadow--drop bg-white border-pigeon-200 text-pigeon-700 h-fit">
                      This looks perfect. Export to all platforms!
                    </div>
                    <div className="flex-shrink-0 flex-grow-0 w-8 h-8 comment-avatar">
                      <Image
                        src={ProfilePic}
                        priority
                        alt="Profile Picture Example"
                        width={32}
                        height={32}
                        className="w-8 h-8 flex-shrink-0 flex-grow-0"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 3 */}
              <div className="card-column flex flex-col gap-y-3 pt-3">
                <div className="relative z-[-1] rect w-full rounded pl-box-shadow aspect-square animate-media overflow-hidden h-fit">
                  <Image src={ManInField} alt="Man In Field" className="w-full object-cover" />
                </div>

                <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit animate-media">
                  <Image src={Photo4} alt="Salad socks" className="w-full object-cover" />
                </div>
                <div>
                  <p className="animate-header uppercase text-body-sm font-semibold">Versions</p>
                  <div className="flex flex-row flex-nowrap max-h-[85px] gap-2">
                    <div className="relative z-[-1] image-wrapper flex aspect-[69/85] basis-auto w-fit max-h-[85px] bg-transparent pl-box-shadow rounded overflow-hidden animate-media">
                      <Image src={VersionStackV3} alt="Version stack v3" className="h-full w-full object-cover" />
                    </div>
                    <div className="relative z-[-1] image-wrapper flex aspect-[126/85] basis-auto w-fit max-h-[85px] bg-transparent pl-box-shadow rounded overflow-hidden animate-media">
                      <Image src={VersionStackV2} alt="Version stack v2" className="h-full w-full object-cover" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Desktop Columns */}
          <div className=" min-w-screen w-fit px-8 sm:px-4 lg:px-0 flex-row flex-nowrap hidden lg:grid grid-cols-5 gap-x-3 xl:gap-x-6 auto-rows-min">
            <div className="hidden lg:block col col-span-4 row-span-1 mb-16">
              <div className="flex flex-col text-left pr-8 max-w-[993px] hero-title opacity-0">
                <h1 className="text-header-lg font-display font-semibold mb-10">{headline}</h1>
                <button
                  className="pl-button pl-button"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsDemoDialogOpened(true);
                  }}
                >
                  {ctaLabel}
                </button>
              </div>
            </div>

            {/* Column 1 */}
            <div className="card-column will-change-transform relative z-2 w-[200px] lg:w-full col flex flex-col pt-16 lg:pt-0 gap-6 row-start-2 row-span-3 col-span-1">
              <div className="relative animate-media will-change-transform translate-">
                <div className="image-wrapper relative z-[-1] flex w-full pl-box-shadow rounded overflow-hidden">
                  <Image src={Photo} priority alt="Food on table" className="w-full object-cover" />
                  <div className="absolute status-tag top-4 right-4">
                    <div className="bubble z-10 rounded px-2 py-1 xl:px-4 xl:py-2 text-body-sm xl:text-body-md text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop bg-canary-100 text-canary-900">
                      In Progress
                    </div>
                  </div>
                </div>
                <div className="absolute right-[-50%] bottom-8 flex flex-col gap-2">
                  <div className="feature-bubble z-10 rounded-[50px] px-2 py-1 xl:px-4 xl:py-2 text-body-sm xl:text-body-md bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                    Marketing
                  </div>
                  <div className="feature-bubble z-10 rounded-[50px] px-2 py-1 xl:px-4 xl:py-2 text-body-sm xl:text-body-md bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                    Product Photography
                  </div>
                  <div className="feature-bubble z-10 rounded-[50px] px-2 py-1 xl:px-4 xl:py-2 text-body-sm xl:text-body-md bg-white text-center leading-none flex items-center w-fit whitespace-nowrap pl-box-shadow--drop text-pigeon-600">
                    Website
                  </div>
                </div>
              </div>
              <div className="animate-media">
                <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                  <div className="air-video__container relative h-full w-full">
                    <Video
                      className="h-full w-full object-cover"
                      type="wistia"
                      autoPlay={true}
                      loop={true}
                      muted={true}
                      controls={false}
                      wistiaAutoPlay={true}
                      wistiaUrl={videoUrl2}
                      useAspectRatio={true}
                      wistiaFitStrategy="cover"
                      wistiaPlaybar={false}
                      wistiaMuted={true}
                      wistiaPlayButton={false}
                      wistiaEndVideoBehavior="loop"
                      wistiaControlsVisibleOnLoad={false}
                      wistiaPlaysInline={true}
                      ratioHeight={315}
                      ratioWidth={250}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Column 2 */}
            <div className="card-column relative z-2 w-[200px] lg:w-full z-[-1] col flex flex-col lg:justify-end lg:mb-[-4rem] gap-6 row-start-2 row-span-3 col-span-1 ">
              <div className="relative z-[-1] rect w-full rounded pl-box-shadow aspect-square animate-media h-fit overflow-hidden">
                <Image src={ManInField} alt="Man In Field" className="w-full object-cover" />
              </div>

              <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit animate-media">
                <Image src={Photo4} priority alt="Salad socks" className="w-full object-cover" />
              </div>
              <div>
                <p className="animate-header uppercase text-body-sm font-semibold">Versions</p>
                <div className="flex flex-row flex-nowrap max-h-[85px] gap-2">
                  <div className="relative z-[-1] image-wrapper flex aspect-[69/85] basis-auto w-fit max-h-[85px] bg-transparent pl-box-shadow rounded overflow-hidden animate-media">
                    <Image src={VersionStackV3} alt="Version stack v3" className="h-full w-full object-cover" />
                  </div>
                  <div className="relative z-[-1] image-wrapper flex aspect-[126/85] basis-auto w-fit max-h-[85px] bg-transparent pl-box-shadow rounded overflow-hidden animate-media">
                    <Image src={VersionStackV2} alt="Version stack v2" className="h-full w-full object-cover" />
                  </div>
                </div>
              </div>
            </div>

            {/* Column 3 */}
            <div className="card-column relative z-2 w-[200px] lg:w-full col flex flex-col pt-8 lg:pt-0 lg:mt-[-4rem] justify-center lg:justify-start gap-6 lg:mt-[-5rem] row-start-2 row-span-3 col-span-1">
              <div className="relative animate-media">
                <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit ">
                  <Image src={Photo2} priority alt="Bowl of fresh salad" className="w-full object-cover" />
                </div>
                <div className="flex absolute top-[15%] w-full left-[-30%] flex-row flex-nowrap items-end gap-2">
                  <div className="comment text-left bubble z-10 rounded-md p-1 px-2 xl:p-2 text-body-sm xl:text-body-md flex items-center w-fit max-w-[195px] pl-box-shadow--drop bg-white border-pigeon-200 border text-pigeon-700 h-fit">
                    This looks perfect. Export to all platforms!
                  </div>
                  <div className="flex-shrink-0 flex-grow-0 w-8 h-8 comment-avatar">
                    <Image
                      src={ProfilePic}
                      alt="Profile Picture Example"
                      width={32}
                      height={32}
                      className="w-8 h-8 flex-shrink-0 flex-grow-0"
                    />
                  </div>
                </div>
              </div>
              <div className="relative animate-media">
                <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                  <Image src={Graphic} priority alt="Finding a bit of spring poster" className="w-full object-cover" />
                </div>
                <div className="absolute bottom-0 w-8 h-8 right-[-8%] bottom-[5%]">
                  <Image src={Cursor} priority alt="Cursor icon" className="w-full h-auto" />
                </div>
              </div>
            </div>

            {/* Column 4 */}
            <div className="card-column relative z-2 w-[200px] lg:w-full col flex flex-col pt-8 lg:pt-0 gap-6 row-start-2 row-span-3 col-span-1">
              <div className="flex relative z-[-1] bg-canary-300 bg-opacity-50  image-wrapper w-full rounded pl-box-shadow overflow-hidden h-fit animate-media aspect-square ">
                <Image src={PeachCandles} priority alt="Peach Candles" className="w-full object-cover" />
              </div>
              <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit animate-media">
                <Image src={Photo5} priority alt="Sweetgreen billboard" className="w-full object-cover" />
              </div>
            </div>

            {/* Column 5 */}
            <div className="card-column relative z-2 w-[200px] lg:w-full col flex flex-col  justify-center lg:justify-start gap-6 col-span-1 row-start-1 row-span-4">
              <div className="relative animate-media">
                <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                  <Image src={Photo3} priority alt="Sweetgreen storefront" className="w-full object-cover" />
                </div>
                <div className="absolute left-1/2 translate-x-[-50%] top-16 lg:top-[25%] w-[calc(100%*(111/134))] lg:w-[calc(100%*(208/250))]">
                  <svg className="" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 75">
                    <motion.path
                      variants={markupGraphicVariants}
                      initial={{ pathLength: 0 }}
                      animate={hasDoneInitialAnimation ? 'visible' : 'hidden'}
                      d="M110.124 13.296c-13.15 0-26.359-.915-39.502-.46-5.376.187-10.737.72-16.108.92-2.883.107-5.77-.15-8.632.297-10.484 1.64-21.439 4.845-31.032 9.44-2.76 1.321-4.2 3.39-6.427 5.382-2.033 1.818-4.478 2.94-5.512 5.599-4.715 12.118 9.985 18.88 18.635 22.637 8.81 3.826 18.22 5.898 27.59 7.79 9.608 1.94 19.155 3.625 28.934 4.381 19.132 1.48 37.814 4.731 57.063 3.408 17.911-1.231 34.502-6.726 51.495-12.17 7.673-2.46 11.392-5.583 16.189-11.658 2.076-2.63 4.633-4.792 6.211-7.816 1.514-2.9.807-6.97.807-10.143 0-5.598-4.928-9.161-9.331-12.008-5.463-3.533-12.399-5.612-18.42-7.925-5.929-2.277-11.705-4.47-17.963-5.571-5.203-.916-10.389-2.157-15.651-2.705-4.816-.501-9.838-.291-14.682-.487-9.677-.391-19.412-.108-29.096-.108H92.538c-1.708 0-3.622-.289-5.163.487"
                      stroke="#33DBDB"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div className=" absolute top-5 lg:top-[12%] w-full left-[-50%] flex flex-row flex-nowrap items-end gap-2">
                  <div className="svg-comment text-left bubble z-10 rounded-md p-1 px-2 xl:p-2 text-body-sm xl:text-body-md flex items-center w-fit whitespace-nowrap pl-box-shadow--drop bg-white border border-pigeon-200 text-pigeon-700 h-fit">
                    Brighten and add contrast
                  </div>
                  <div className="flex-shrink-0 flex-grow-0 w-8 h-8 svg-comment-avatar" style={{ opacity: 0 }}>
                    <Image
                      src={ProfilePic}
                      priority
                      alt="Profile Picture Example"
                      width={32}
                      height={32}
                      className="w-8 h-8 flex-shrink-0 flex-grow-0"
                    />
                  </div>
                </div>
                <div className="absolute bottom-0 w-full right-[17%] ui-element">
                  <Image src={AnnotationBar} priority alt="Annotation Bar" className="w-full h-auto" />
                </div>
              </div>
              <div className="relative animate-media">
                <div className="relative ">
                  <div className="relative z-[-1] image-wrapper flex w-full pl-box-shadow rounded overflow-hidden h-fit">
                    <div className="air-video__container relative h-full w-full">
                      <Video
                        className="h-full w-full object-cover"
                        type={'wistia'}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        controls={false}
                        wistiaAutoPlay={true}
                        wistiaUrl={videoUrl}
                        useAspectRatio={true}
                        wistiaFitStrategy="cover"
                        wistiaPlaybar={false}
                        wistiaMuted={true}
                        wistiaPlayButton={false}
                        wistiaEndVideoBehavior="loop"
                        wistiaControlsVisibleOnLoad={false}
                        wistiaPlaysInline={true}
                        ratioHeight={424}
                        ratioWidth={250}
                      />
                    </div>
                  </div>
                  <div className="absolute bottom-[3%] w-full left-[-20%] flex flex-row flex-nowrap items-end gap-2">
                    <div className="comment text-left bubble z-10 rounded-md p-1 px-2 xl:p-2 text-body-sm xl:text-body-md flex items-center w-fit whitespace-nowrap pl-box-shadow--drop bg-white border border-pigeon-200 text-pigeon-700 h-fit">
                      Cut footage here
                    </div>
                    <div className="flex-shrink-0 flex-grow-0 w-8 h-8 comment-avatar" style={{ opacity: 0 }}>
                      <Image
                        src={ProfilePic}
                        priority
                        alt="Profile Picture Example"
                        width={32}
                        height={32}
                        className="w-8 h-8 flex-shrink-0 flex-grow-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="image-wrapper flex w-full h-fit pl-drop-shadow">
                  <Image src={VideoPlayer} priority alt="Video controls graphic" className="w-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
