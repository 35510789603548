import { Box } from '@air/marketing-primitives';
import { Button } from '@air/zephyr';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Container } from '~/old-marketing/components/globals/Container';
import { DefaultHeaderProps } from '~/old-marketing/components/globals/headers/DefaultHeader/DefaultHeader';
import { Logo } from '~/old-marketing/components/globals/Logo';

export type LandingHeaderProps = Pick<DefaultHeaderProps, 'appearance' | 'isFixed'> & {
  ctaText?: string;
  showCTA?: boolean;
};

export const LandingHeader = ({
  appearance = 'dark',
  showCTA = true,
  ctaText = 'Book demo',
  isFixed = true,
}: LandingHeaderProps) => {
  const [stickyRef, inView, entry] = useInView();
  const [shouldShowCTA, setShouldShowCTA] = useState(false);
  const isStickied = !inView && entry;
  const isLightText = appearance === 'light' && !(isStickied && isFixed);

  useEffect(() => {
    const setCTAVisibility = () => {
      if (window.scrollY > 200 && !shouldShowCTA) {
        setShouldShowCTA(true);
      }

      if (window.scrollY <= 200 && shouldShowCTA) {
        setShouldShowCTA(false);
      }
    };

    if (isFixed) {
      window.addEventListener('scroll', setCTAVisibility);

      return () => {
        window.removeEventListener('scroll', setCTAVisibility);
      };
    }
  }, [isFixed, shouldShowCTA]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <Box ref={stickyRef} />

      <Box
        as="header"
        css={{
          zIndex: 100,
          position: isFixed ? 'fixed' : 'absolute',
          top: 0,
          right: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          height: 72,
          backgroundColor: isFixed && isStickied ? 'rgba(250, 250, 250, 0.98)' : 'transparent',
          boxShadow: isFixed && isStickied ? '0 4px 10px rgba(0,0,0, 0.06)' : 'none',
          transitionProperty: 'background-color, box-shadow, color',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease',
        }}
      >
        <Container
          css={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'space-between',
          }}
        >
          <Logo
            css={{
              display: 'block',
              height: 30,
              marginRight: 24,
              color: isLightText ? '$white' : '$jay600',
            }}
          />

          {shouldShowCTA && showCTA && <Button onClick={scrollToTop}>{ctaText}</Button>}
        </Container>
      </Box>
    </>
  );
};
