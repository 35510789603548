import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Text } from '@air/marketing-primitives';
import Link from 'next/link';
import { ReactNode } from 'react';

import { StyledHeaderNavigationItem, StyledHeaderNavigationItemProps } from './HeaderNavigationItem.styles';

export type HeaderNavigationProps = Pick<
  StyledHeaderNavigationItemProps,
  'css' | 'onBlur' | 'onClick' | 'onFocus' | 'onMouseEnter' | 'onMouseLeave'
> & {
  href?: string;
  menu?: ReactNode;
  name: string;
  rightAdornment?: ReactNode;
};

export const HeaderNavigationItem = ({
  css,
  href,
  menu,
  name,
  onBlur,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  rightAdornment,
}: HeaderNavigationProps) => {
  const { trackClickedActionItem } = useTrackClickedActionItem();

  const onNavItemClick = () => {
    trackClickedActionItem({
      destination: href,
      destinationType: 'internal',
      location: window.location.href,
      name: name,
      pathName: window.location.pathname,
      zone: 'marketing',
      version: 'new-marketing-header',
    });
  };

  let navItem = undefined;
  if (href) {
    navItem = (
      <Link href={href} passHref>
        <Text as="a" onClick={onNavItemClick} size="ui-16" css={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
          {name}
        </Text>
      </Link>
    );
  } else {
    navItem = (
      <Text as="div" onClick={onNavItemClick} size="ui-16" css={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        {name}
      </Text>
    );
  }

  return (
    <StyledHeaderNavigationItem
      css={css}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {navItem}
      {rightAdornment}
      {menu}
    </StyledHeaderNavigationItem>
  );
};
