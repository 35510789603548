import { Tooltip, TooltipProps } from '@air/zephyr';

import { usePreviewMode } from '~/providers/PreviewModeProvider';

export type SectionFieldTooltipProps = Pick<TooltipProps, 'children'> & {
  field: TooltipProps['label'];
  isPreview?: boolean;
};

export const SectionFieldTooltip = ({ children, field }: SectionFieldTooltipProps) => {
  const { isPreview } = usePreviewMode();

  if (!isPreview) {
    return children;
  }

  return (
    <Tooltip label={`Contentful Field: ${field}`} side="top">
      {children}
    </Tooltip>
  );
};
