import { GoTo as GoToIcon } from '@air/icons';
import { Box } from '@air/zephyr';
import { IntersectionOptions, InView, PlainChildrenProps } from 'react-intersection-observer';

import * as StyledSection from '~/old-marketing/components/sections/Section.styles';
import { usePreviewMode } from '~/providers/PreviewModeProvider';
import { getContentfulUrl } from '~/utils/getContentfulUrl';

export const SECTION_CMS_LINK_CLASS_NAME = 'is-cms-linked';

export type SectionProps = Pick<IntersectionOptions, 'threshold' | 'triggerOnce'> &
  StyledSection.RootProps & {
    id: string;
    onInViewChange?: PlainChildrenProps['onChange'];
    templateKey: string;
  };

export const Section = ({
  children,
  css,
  id,
  onInViewChange,
  templateKey,
  threshold,
  triggerOnce,
  ...restOfProps
}: SectionProps) => {
  const { isPreview } = usePreviewMode();
  const contentfulUrl = getContentfulUrl(id);

  if (onInViewChange) {
    return (
      <InView as="section" id={id} onChange={onInViewChange} threshold={threshold} triggerOnce={triggerOnce}>
        <StyledSection.Root
          css={{
            boxShadow: isPreview ? 'inset 0 0 0 2px $colors$macaw200' : undefined,

            '&:hover': {
              [`& .${SECTION_CMS_LINK_CLASS_NAME}`]: {
                boxShadow: `0 0 0 2px $colors$macaw200`,
              },
            },

            ...css,
          }}
          {...restOfProps}
        >
          {isPreview && (
            <StyledSection.PreviewLabel>
              <StyledSection.PreviewLabelLink href={contentfulUrl}>{templateKey}</StyledSection.PreviewLabelLink>
            </StyledSection.PreviewLabel>
          )}
          {children}
        </StyledSection.Root>
      </InView>
    );
  }

  return (
    <StyledSection.Root
      as="section"
      id={id}
      css={{
        boxShadow: isPreview ? 'inset 0 0 0 2px $colors$macaw200' : undefined,

        '&:hover': {
          [`& .${SECTION_CMS_LINK_CLASS_NAME}`]: {
            boxShadow: `0 0 0 2px $colors$macaw200`,
          },
        },

        ...css,
      }}
      {...restOfProps}
    >
      {isPreview && (
        <StyledSection.PreviewLabel>
          <StyledSection.PreviewLabelLink href={contentfulUrl}>
            {templateKey}
            <Box as={GoToIcon} tx={{ display: 'block', width: 12, ml: 4 }} />
          </StyledSection.PreviewLabelLink>
        </StyledSection.PreviewLabel>
      )}
      {children}
    </StyledSection.Root>
  );
};

Section.toString = () => `${StyledSection.Root.className}`;
