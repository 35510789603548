import Link from 'next/link';

export const PaigeNavBar = () => {
  return (
    <div className="backdrop-blur-[15px] rounded-full mx-auto p-4 bg-pale-blue bg-opacity-60 flex items-center fixed top-4 left-4 right-4 z-50 max-w-screen-3xl lg:px-14">
      <div className="w-[77px] ml-2">
        <Link href="/">
          <svg className="cursor-pointer" viewBox="0 0 78 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g fill="#06113F">
              <path d="M32.792 12.997c.544-2.815.776-5.248.776-6.647 0-5.247-2.387-6.348-4.39-6.348-4.252 0-10.261 4.92-18.37 15.04C5.016 22.272.272 29.599.225 29.672a1.403 1.403 0 1 0 2.356 1.522C9.668 20.22 23.156 2.808 29.179 2.808c.474 0 1.583 0 1.583 3.542 0 1.383-.253 3.846-.83 6.656 0 0 1.632-.055 2.86-.009Zm16.619-2.66a2.207 2.207 0 1 1-4.414 0 2.207 2.207 0 0 1 4.414 0Z" />
              <path d="M55.841 20.607a25.482 25.482 0 0 1-2.527 3.017C49.91 27.11 45.956 29.19 42.74 29.19c-1.656 0-2.495-.708-2.495-2.104 0-1.477.81-4.288 4.666-9.296a1.403 1.403 0 0 0-.88-2.24v.001-.007c-9.487-1.588-22.165-2.323-28.685 3.197v.006c-3.25 2.752-3.503 5.83-3.503 6.709 0 3.726 2.838 6.535 6.601 6.535 5.114 0 9.31-3.854 12.137-11.144.286-.744.55-1.496.791-2.255a43.258 43.258 0 0 0-2.986.105c-1.896 5.457-5.058 10.488-9.942 10.488-2.491 0-3.795-1.876-3.795-3.73 0-1.18.66-3 2.51-4.566v-.006c2.994-2.535 9.609-5.016 24.143-2.922-2.594 3.701-3.862 6.703-3.862 9.125 0 3.624 2.855 4.91 5.3 4.91 4.014 0 8.6-2.337 12.58-6.412a27.676 27.676 0 0 0 3.37-4.176c-1.831-.104-2.849-.802-2.849-.802Z" />
              <path d="M77.338 25.05a1.405 1.405 0 0 0-1.98-.126c-3.078 2.709-6.248 4.262-8.695 4.262-1.453 0-2.19-.76-2.19-2.26 0-1.622.87-4.683 5.011-10.06a1.403 1.403 0 0 0-1.91-2.009c-2.09 1.45-5.145 2.424-7.603 2.424-3.111 0-4.529-1.892-4.529-3.65 0-2.142 1.106-2.707 1.69-2.707 1.504 0 1.66 1.669 1.671 2.18.018.767-.122 1.62-.396 2.523 0 0 1.36.478 2.86.155.241-.96.36-1.882.34-2.742-.066-2.898-1.906-4.922-4.474-4.922-2.234 0-4.496 1.893-4.496 5.512 0 1.96.852 3.691 2.398 4.876 1.33 1.02 3.083 1.58 4.936 1.58 1.38 0 2.88-.237 4.346-.664-1.76 2.935-2.648 5.448-2.648 7.504 0 3.5 2.508 5.067 4.994 5.067 4.271 0 8.442-3.109 10.549-4.962a1.403 1.403 0 0 0 .126-1.98Z" />
            </g>
          </svg>
        </Link>
      </div>
      <div className="ml-auto">
        <a
          className="inline-block rounded-full bg-gradient-to-r from-pale-blue-900 to-pale-blue-900 hover:to-pale-blue-600 py-4 px-8 text-white transition-all duration-1000 ease-out"
          href="#launch-plan"
          onClick={(event) => {
            event.preventDefault();
            document.getElementById('launch-plan')?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          Try Paige Today
        </a>
      </div>
    </div>
  );
};
