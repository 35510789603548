import {
  Apple,
  BoardMove,
  Cart,
  Check,
  Dart,
  DesktopApp,
  Device,
  Email,
  Emoji,
  Eye,
  Home,
  Info,
  Kanban,
  Link as LinkIcon,
  Lock,
  MagicWand,
  MagnifyingGlass,
  Megaphone,
  NavBlog,
  NavCulture,
  NavDiversity,
  NavPolicies,
  Palette,
  Popcorn,
  Send,
  Soccer,
  Stack,
  Team,
} from '@air/icons';
import { Box, CSS, Text } from '@air/marketing-primitives';
import Image from 'next/image';
import { ReactNode } from 'react';

import { HeaderNavigationItem } from '../../constants/navigation-types';
import {
  PlasmicHeaderNavigationMenuColumn,
  PlasmicHeaderNavigationMenuColumnItem,
  PlasmicHeaderNavigationMenuColumnItemCTA,
} from '../../constants/plasmic-navigation';
import { HeaderMenuBanner } from '../Header/HeaderMenuBanner';
import { HeaderMenuItemAdornment } from '../Header/HeaderMenuItemAdornment';

export type AdornmentIconNames =
  | 'Dart'
  | 'Palette'
  | 'Kanban'
  | 'Stack'
  | 'DesktopApp'
  | 'Apple'
  | 'Device'
  | 'LinkIcon'
  | 'Cart'
  | 'Megaphone'
  | 'Popcorn'
  | 'Home'
  | 'Soccer'
  | 'Apple'
  | 'NavBlog'
  | 'MagicWand'
  | 'Email'
  | 'Check'
  | 'Lock'
  | 'Send'
  | 'Team'
  | 'Info'
  | 'MagnifyingGlass'
  | 'Emoji'
  | 'Cart'
  | 'NavCulture'
  | 'NavDiversity'
  | 'Team'
  | 'NavPolicies'
  | 'Enterprise'
  | 'BoardMove'
  | 'Eye';

export type AdornmentIcons = { [key in AdornmentIconNames]: ReactNode };
export type AdornmentIcontStyle = { [key in AdornmentIconNames]?: CSS };

export const adornmentIcons: AdornmentIcons = {
  Dart: Dart,
  Palette: Palette,
  Kanban: Kanban,
  Enterprise: Kanban,
  Stack: Stack,
  DesktopApp: DesktopApp,
  Device: Device,
  LinkIcon: LinkIcon,
  Cart: Cart,
  Megaphone: Megaphone,
  Popcorn: Popcorn,
  Home: Home,
  Soccer: Soccer,
  Apple: Apple,
  NavBlog: NavBlog,
  MagicWand: MagicWand,
  Email: Email,
  Check: Check,
  Lock: Lock,
  Send: Send,
  Team: Team,
  Info: Info,
  MagnifyingGlass: MagnifyingGlass,
  Emoji: Emoji,
  NavCulture: NavCulture,
  NavDiversity: NavDiversity,
  NavPolicies: NavPolicies,
  Eye: Eye,
  BoardMove: BoardMove,
};

const adornmentIconStyles: AdornmentIcontStyle = {
  Enterprise: {
    transform: 'rotate(180deg)',
  },
};

export const getAdornment = (adornment: PlasmicHeaderNavigationMenuColumnItem['adornment']) => {
  if (adornment && adornment.iconName) {
    const iconName = adornment.iconName;
    const icon: any = adornmentIcons[iconName];
    return (
      <HeaderMenuItemAdornment
        size={adornment.size}
        css={{
          backgroundColor: adornment.backgroundColor,
          color: adornment.color,
          ...adornmentIconStyles[iconName],
        }}
      >
        <Box as={icon} css={{ display: 'block', width: 24 }} />
      </HeaderMenuItemAdornment>
    );
  }
};

export const getCTA = (cta: PlasmicHeaderNavigationMenuColumnItemCTA): ReactNode => {
  if (cta) {
    const { link, image } = cta;
    return (
      <HeaderMenuBanner
        isDemo={link.isDemo}
        description={cta.description}
        image={
          <Box css={{ marginBottom: image.marginBottom || 80 }}>
            <Image alt="air product" height={image.height} src={image.src} width={image.width} />
          </Box>
        }
        cta={
          <Text
            as="a"
            css={{ color: '$jay350', fontWeight: '$medium', textDecoration: 'none', cursor: 'pointer' }}
            href={link.href}
            size="ui-14"
          >
            {link.label}
          </Text>
        }
        title={cta.title}
      />
    );
  }
};

export const getColumnWithItems = (column: PlasmicHeaderNavigationMenuColumn) => {
  if ('items' in column) {
    return {
      ...column,
      items: column.items.map((item) => ({ ...item, adornment: getAdornment(item.adornment) })),
    };
  }

  return column;
};

type GetColumnsProp = {
  columns?: PlasmicHeaderNavigationMenuColumn[];
  cta?: PlasmicHeaderNavigationMenuColumnItemCTA;
};

export const getColumns = ({ columns, cta }: GetColumnsProp): HeaderNavigationItem['columns'] => {
  const _columns: HeaderNavigationItem['columns'] = [];
  if (columns) {
    columns.forEach((column) => _columns.push(getColumnWithItems(column)));
  }
  if (cta) {
    _columns.push({
      name: cta.name,
      children: getCTA(cta),
    });
  }

  return _columns;
};
