import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import ReactMarquee from 'react-fast-marquee';
import { useInView } from 'react-intersection-observer';

import { limitedLogos } from '~/marketing/constants/ClientLogos';
import { testimonials } from '~/marketing/constants/Testimonials';

import { randomizePosition } from '../../utils/randomizePosition';
import { logo } from '../types/customTypes';

export type ClientTestimonialsProps = {
  headline?: string;
};

export const ClientTestimonials = ({ headline }: ClientTestimonialsProps) => {
  const logosDesktop = useRef<HTMLDivElement>(null);
  const logosMobile = useRef<HTMLDivElement>(null);
  const [logos, setLogos] = useState<logo[]>([]);

  useEffect(() => {
    let mobileIntervalId: any;
    let desktopIntervalId: any;

    setLogos(limitedLogos);

    if (logosMobile.current) {
      const logoEls = [...logosMobile.current.querySelectorAll('.logo')];
      mobileIntervalId = setInterval(() => {
        logoEls.forEach((logo) => {
          let shouldBlur = Math.random() > 0.5;
          let delay = Math.floor(Math.random() * 1000);
          setTimeout(() => {
            if (shouldBlur) {
              logo.classList.add('blur-sm');
            } else {
              logo.classList.remove('blur-sm');
            }
          }, delay);
        });
      }, 3000);
    }

    if (logosDesktop.current) {
      const logoEls = [...logosDesktop.current.querySelectorAll('.logo')];
      desktopIntervalId = setInterval(() => {
        logoEls.forEach((logo) => {
          let shouldBlur = Math.random() > 0.5;
          let delay = Math.floor(Math.random() * 500);
          setTimeout(() => {
            if (shouldBlur) {
              logo.classList.add('blur-sm');
            } else {
              logo.classList.remove('blur-sm');
            }
          }, delay);
        });
      }, 2000);
    }

    return () => {
      clearInterval(mobileIntervalId);
      clearInterval(desktopIntervalId);
    };
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <div ref={ref} className="overflow-x-hidden py-[72px] bg-greenBird text-jay-900">
      <h2 className="font-display text-24 md:text-header-md font-semibold text-center text-jay-900 mb-[72px]">
        {headline}
      </h2>
      <div className="md:hidden">
        <ReactMarquee
          ref={logosMobile}
          gradient={false}
          direction="left"
          speed={25}
          className="md:hidden overflow-y-visible"
          play={inView}
          pauseOnHover={true}
        >
          <div className="mb-[72px] pl-logos-grid grid grid-cols-4 grid-rows-4 gap-4 max-w-[1248px] w-max mx-auto">
            {logos.map((logo) => (
              <div
                key={logo.id}
                className={`pl-float logo transition duration-[1.25s] ease h-[calc((332/4)-16*1px)] flex flex-col ${randomizePosition(
                  'flex',
                )} p-4`}
              >
                <Image src={logo.src} alt={logo.alt} width={logo.width} height={logo.height} />
              </div>
            ))}
          </div>
        </ReactMarquee>
      </div>
      <div
        ref={logosDesktop}
        className="mb-[72px] hidden md:grid pl-logos-grid grid-cols-4 grid-rows-4 gap-4 max-w-[1248px] w-full mx-auto px-8"
      >
        {logos.map((logo, idx) => {
          const isLargeLogo =
            logo.alt === 'MSU Logo' || logo.alt === 'Univision Logo' || logo.alt === 'Room to Read Logo';

          return (
            <div
              key={logo.id}
              className={`pl-float logo transition duration-[2s] ease h-[calc(((332/4)-16)*1px)] flex flex-col px-4 ${
                [0, 12].includes(idx) ? 'items-end' : [3, 15].includes(idx) ? 'items-start' : 'items-center'
              } ${isLargeLogo ? 'py-2' : 'py-4'}`}
            >
              <Image src={logo.src} alt={logo.alt} width={logo.width} height={logo.height} />
            </div>
          );
        })}
      </div>
      <div className="pl-testimonial-cards-marquee">
        <ReactMarquee
          play={inView}
          gradient={false}
          autoFill={true}
          direction="left"
          speed={25}
          pauseOnHover={true}
          pauseOnClick={true}
          className="pl-marquee-override"
        >
          <div className="flex flex-row flex-nowrap items-start">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author}
                className="pl-testimonial-card p-6 bg-white rounded pl-box-shadow--light w-[326px] mx-2"
              >
                <div className="flex flex-row flex-nowrap items-center mb-6">
                  <div className="flex-shrink-0">
                    <div className="w-12 h-12 mr-2">
                      <Image
                        src={testimonial.gravatar.src}
                        alt={testimonial.gravatar.alt}
                        width={testimonial.gravatar.width}
                        height={testimonial.gravatar.height}
                        className="object-cover rounded-full"
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-body-md font-bold">{testimonial.author}</p>
                    <p className="text-body-md">
                      @{testimonial.twitterHandle} •{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://twitter.com/${testimonial.twitterHandle}`}
                        className="inline-block relative text-body-md font-bold after:block after:content-[''] after:absolute after:h-[3px] after:bg-jay-900 after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                      >
                        Follow
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={testimonial.quote}
                  className="text-display-md font-display font-light pl-rich-text "
                ></div>
              </div>
            ))}
          </div>
        </ReactMarquee>
      </div>
      {/* Need style-jsx to override marquee overflow hidden style to fix box-shadow clipping on cards */}
      <style jsx>{`
        .pl-testimonial-cards-marquee :global(.marquee-container) {
          overflow-x: visible !important;
        }
      `}</style>
    </div>
  );
};
