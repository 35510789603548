import { ArrowDown, ArrowUp, Trash } from '@air/icons';

import { PlasmicHeaderNavigationMenuColumn } from '../../../../constants/plasmic-navigation';
import { Button } from './Button';
import { Input } from './Input';
import { NavigationItemColumnRow } from './NavigationItemColumnRow';

type NavigationItemColumnProps = {
  column: PlasmicHeaderNavigationMenuColumn;
  parentField: string;
  onRemoveCol: () => void;
  onMoveUpCol: () => void;
  onMoveDownCol: () => void;
  onAddNewRow: () => void;
  onRemoveRow: (rowIndex: number) => void;
  onMoveUpRow: (rowIndex: number) => void;
  onMoveDownRow: (rowIndex: number) => void;
  isFirstCol: boolean;
  isLastCol: boolean;
};

export const NavigationItemColumn = ({
  column,
  parentField,
  onRemoveCol,
  onMoveUpCol,
  onMoveDownCol,
  onAddNewRow,
  onRemoveRow,
  onMoveUpRow,
  onMoveDownRow,
  isFirstCol,
  isLastCol,
}: NavigationItemColumnProps) => {
  return (
    <div
      style={{ marginBottom: 40, padding: '30px 20px 10px', position: 'relative', background: 'aqua', marginLeft: 10 }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
        <Input placeholder="Column name" name={`${parentField}.name`} />
        <Input placeholder="Chunk Size" name={`${parentField}.chunkSize`} type="number" />
        <Input placeholder="Has divider" as="select" name={`${parentField}.hasDivider`}>
          <option value="" disabled>
            Choose divider
          </option>
          <option value="false">No divider</option>
          <option value="true">With divider</option>
        </Input>
        {/* <Input placeholder="Chunk Size" name={`${parentField}.chunkSize`} type="number" /> */}
      </div>
      <div style={{ paddingLeft: 40 }}>
        {column.items.map((_, i) => (
          <NavigationItemColumnRow
            isFirstRow={i === 0}
            isLastRow={i === column.items.length - 1}
            key={i}
            parentField={`${parentField}.items.${i}`}
            onRemoveRow={() => onRemoveRow(i)}
            onMoveUp={() => onMoveUpRow(i)}
            onMoveDown={() => onMoveDownRow(i)}
          />
        ))}
        <Button onClick={onAddNewRow} variant="success" style={{ marginTop: 20 }}>
          Add new row
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          gap: 15,
          position: 'absolute',
          left: -50,
          top: 0,
        }}
      >
        <Button onClick={onMoveUpCol} disabled={isFirstCol}>
          <ArrowUp width={25} height={35} />
        </Button>
        <Button onClick={onMoveDownCol} disabled={isLastCol}>
          <ArrowDown width={25} height={35} />
        </Button>
      </div>
      <Button variant="danger" style={{ position: 'absolute', right: 10, top: 10 }} onClick={onRemoveCol}>
        <Trash width={20} />
      </Button>
    </div>
  );
};
