import { CSS, styled } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export type RootProps = ComponentProps<typeof Root> & {
  css?: CSS;
};

export const Root = styled('svg', {
  zIndex: -1,
  position: 'absolute',
  right: 'calc(clamp(0px, 65vw, 900px) * -1)',
  bottom: 'calc(clamp(0px, 13.19vw, 190px) * -1)',
  display: 'block',
  width: 'clamp(0px, 115.72vw, 1666px)',
});
