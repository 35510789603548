import { PlasmicHeaderNavigationItemType } from '../../../constants/plasmic-navigation';
import NavigationModalContent from './NavigationBuilderModalContent';

export type NavigationBuilderModalProps = {
  navigation: PlasmicHeaderNavigationItemType[];
  updateNavigation: (navigation: PlasmicHeaderNavigationItemType[]) => void;
  closeModal: () => void;
  open: boolean;
};

export const NavigationBuilderModal = ({ open, ...restOfProps }: NavigationBuilderModalProps) => {
  if (!open) {
    return null;
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px 0px 10px',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        background: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <NavigationModalContent {...restOfProps} />
    </div>
  );
};
