import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box, Text } from '@air/marketing-primitives';
import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

import { StyledHeaderMenuItem, StyledHeaderMenuItemProps } from './HeaderMenuItem.styles';

export type HeaderMenuItemProps = Pick<StyledHeaderMenuItemProps, 'css'> &
  Pick<LinkProps, 'href'> & {
    adornment?: ReactNode;
    name: string;
    description?: string;
  };

export const HeaderMenuItem = ({ adornment, css, description, href, name }: HeaderMenuItemProps) => {
  const { trackClickedActionItem } = useTrackClickedActionItem();

  return (
    <Link href={href} passHref>
      <StyledHeaderMenuItem
        css={css}
        onClick={() =>
          trackClickedActionItem({
            destination: href,
            destinationType: 'internal',
            location: window.location.href,
            name: name,
            pathName: window.location.pathname,
            zone: 'marketing',
            version: 'new-marketing-header',
          })
        }
      >
        {adornment && <Box css={{ marginRight: 12 }}>{adornment}</Box>}
        <Box>
          {name && (
            <Text as="div" css={{ marginBottom: 2, color: '$pigeon900', fontWeight: '$semibold' }} size="ui-16">
              {name}
            </Text>
          )}
          {description && (
            <Text as="div" css={{ color: '$pigeon600' }} size="ui-14">
              {description}
            </Text>
          )}
        </Box>
      </StyledHeaderMenuItem>
    </Link>
  );
};
