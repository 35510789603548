import { ChevronDown } from '@air/icons';
import { useTrackClickedActionItem } from '@air/marketing-analytics';
import { Box, Logo } from '@air/marketing-primitives';
import { ChilipiperLeadsource } from '@air/plasmic-chilipiper';
import { usePlasmicCanvas } from '@air/plasmic-core';
import { Button } from '@air/zephyr';
import { ComponentMeta } from '@plasmicapp/host';
import { usePreventScroll } from '@react-aria/overlays';
import { motion } from 'framer-motion';
import { isUndefined, noop } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { PLASMIC_HEADER_NAVIGATION, PlasmicHeaderNavigationItemType } from '../../constants/plasmic-navigation';
import { AuthRoutes } from '../../constants/routes';
import { Container } from '../Container';
import { DemoDialog } from '../dialogs/DemoDialog';
import { HeaderMenu } from '../Header/HeaderMenu';
import { HeaderMobileHamburger } from '../Header/HeaderMobileHamburger';
import { HeaderMobileMenu } from '../Header/HeaderMobileMenu/HeaderMobileMenu';
import { HeaderNavigationItem } from '../Header/HeaderNavigationItem';
import { NavigationBuilderCustomAction } from './builder/NavigationBuilderCustomAction';
import { getColumns } from './helpers';
import * as StyledDefaultHeader from './PlasmicHeader.styles';

export type DefaultHeaderProps = {
  forceActiveMenuIndex?: number;
  appearance?: 'dark' | 'light';
  initialBackground?: string;
  scrolledBackground?: string;
  hasAuthNavigation?: boolean;
  hasMobileNavigation?: boolean;
  hasLogo?: boolean;
  hasNavigation?: boolean;
  isFixed?: boolean;
  isHiddenOnSticky?: boolean;
  plasmicNavigation?: PlasmicHeaderNavigationItemType[];
  leadsource?: ChilipiperLeadsource;
  router?: string;
};

export const PlasmicHeader = ({
  forceActiveMenuIndex,
  appearance,
  hasAuthNavigation,
  hasMobileNavigation,
  hasLogo,
  hasNavigation,
  isFixed,
  isHiddenOnSticky,
  plasmicNavigation,
  initialBackground = 'white',
  scrolledBackground = 'transparent',
  leadsource,
  router: chilipiperRouter,
}: DefaultHeaderProps) => {
  const router = useRouter();
  const { isEditor } = usePlasmicCanvas();
  const [activeMenu, setActiveMenu] = useState<number | undefined>(undefined);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);
  const [isDemoDialogOpened, setIsDemoDialogOpened] = useState(false);
  const [stickyRef, inView, entry] = useInView();
  const isStickied = !inView && entry;
  const isMenuOpened = !isUndefined(activeMenu);

  const isLightText = appearance === 'light' && !(isStickied && isFixed) && !isMobileMenuOpened && !isMenuOpened;
  const { trackClickedActionItem } = useTrackClickedActionItem();

  const navigation = useMemo(() => {
    if (plasmicNavigation) {
      return plasmicNavigation.map((nav) => {
        const { columns, cta, ...rest } = nav;
        return {
          ...rest,
          columns: getColumns({ columns: nav.columns, cta }),
        };
      });
    }
    return [];
  }, [plasmicNavigation]);

  /**
   * Prevents the page from scrolling when the mobile menu is opened.
   */
  usePreventScroll({ isDisabled: !isMobileMenuOpened });

  /**
   * Closes the mobile menu when a route change is completed.
   */
  useEffect(() => {
    const onMobileMenuClose = () => setIsMobileMenuOpened(false);

    router.events.on('routeChangeComplete', onMobileMenuClose);

    return () => router.events.off('routeChangeComplete', onMobileMenuClose);
  }, [router.events]);

  /**
   * This closes the mobile menu when the window is resized.
   */
  useEffect(() => {
    const onMobileMenuClose = () => {
      if (window.innerWidth > 1024) {
        setIsMobileMenuOpened(false);
      }
    };

    window.addEventListener('resize', onMobileMenuClose);

    return () => window.removeEventListener('resize', onMobileMenuClose);
  }, []);

  /**
   * This opens each menu in the editor canvas.
   */
  useEffect(() => {
    if (isEditor || true) {
      setActiveMenu(forceActiveMenuIndex);
    }
  }, [isEditor, forceActiveMenuIndex]);

  return (
    <>
      <Box ref={stickyRef} />
      <StyledDefaultHeader.Root
        css={{
          position: isFixed ? 'fixed' : 'absolute',
          backgroundColor: (isStickied && isFixed) || isMenuOpened ? initialBackground : scrolledBackground,
          boxShadow: isStickied && isFixed ? '0 4px 10px rgba(0, 0, 0, 0.06)' : 'none',
        }}
      >
        <Container
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          {hasLogo && (
            <Logo
              css={{
                display: 'block',
                height: 32,
                marginRight: 32,
                color: isLightText ? '$white' : '$macaw600',
              }}
            />
          )}

          {hasNavigation && (
            <StyledDefaultHeader.Navigation
              css={{
                flexGrow: 1,
                display: 'none',
                color: isLightText ? '$white' : '$pigeon800',
                transition: 'opacity 0.2s ease',
                paddingX: 12,

                '@desktop': {
                  display: 'flex',
                },
              }}
              style={{
                opacity: isHiddenOnSticky && isStickied ? 0 : 1,
                pointerEvents: isHiddenOnSticky && isStickied ? 'none' : 'auto',
              }}
            >
              {navigation.map((navItem, index) => {
                const hasMenu = !!navItem.columns?.length;

                return (
                  <HeaderNavigationItem
                    href={navItem.href}
                    key={index}
                    rightAdornment={
                      hasMenu && (
                        <Box
                          animate={{ rotate: isMenuOpened && activeMenu === index ? -180 : 0 }}
                          as={motion.div}
                          css={{ marginLeft: 4, transformOrigin: 'center' }}
                        >
                          <Box as={ChevronDown} css={{ display: 'block', width: 16 }} />
                        </Box>
                      )
                    }
                    menu={
                      hasMenu &&
                      activeMenu === index && (
                        <HeaderMenu
                          color={navItem.color}
                          columns={navItem.columns}
                          onDemoDialogOpen={() => setIsDemoDialogOpened(true)}
                          template={navItem.template as 'enterprise' | 'product' | 'resources'}
                        />
                      )
                    }
                    name={navItem.name}
                    onBlur={hasMenu ? () => setActiveMenu(undefined) : noop}
                    onFocus={hasMenu ? () => setActiveMenu(index) : noop}
                    onMouseEnter={hasMenu ? () => setActiveMenu(index) : noop}
                    onMouseLeave={hasMenu ? () => setActiveMenu(undefined) : noop}
                  />
                );
              })}
            </StyledDefaultHeader.Navigation>
          )}

          {hasAuthNavigation && (
            <StyledDefaultHeader.Navigation
              css={{
                display: 'none',
                color: isLightText ? '$white' : '$pigeon800',

                '@desktop': {
                  display: 'flex',
                },
              }}
            >
              <HeaderNavigationItem href={AuthRoutes.login} name="Login" />

              <Button
                data-testid="BOOK_A_DEMO"
                onClick={() => {
                  trackClickedActionItem({
                    destinationType: 'internal',
                    location: window.location.href,
                    name: 'Sign up for free',
                    pathName: window.location.pathname,
                    zone: 'marketing',
                    version: 'new-marketing-header',
                  });

                  setIsDemoDialogOpened(true);
                }}
                tx={{
                  mr: 12,
                  color: isLightText ? 'white' : undefined,
                  '&:hover:not(:disabled)': { color: isLightText ? 'white' : undefined },
                  height: 36,
                  paddingX: 12,
                }}
                variant="button-outline-blue"
              >
                Book a demo
              </Button>

              <Link href={AuthRoutes.signUp} passHref>
                <Button
                  as="a"
                  onClick={() =>
                    trackClickedActionItem({
                      destination: AuthRoutes.signUp,
                      destinationType: 'internal',
                      location: window.location.href,
                      name: 'Sign up for free',
                      pathName: window.location.pathname,
                      zone: 'marketing',
                      version: 'new-marketing-header',
                    })
                  }
                  tx={{
                    height: 36,
                    paddingX: 12,
                  }}
                >
                  Start for free
                </Button>
              </Link>
            </StyledDefaultHeader.Navigation>
          )}

          {hasMobileNavigation && (
            <HeaderMobileHamburger
              css={{
                display: 'block',

                '@desktop': {
                  display: 'none',
                },
              }}
              isOpened={isMobileMenuOpened}
              onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
            />
          )}
        </Container>
      </StyledDefaultHeader.Root>

      {hasMobileNavigation && <HeaderMobileMenu navigation={navigation} isOpened={isMobileMenuOpened} />}
      <DemoDialog
        id={chilipiperRouter}
        leadsource={leadsource}
        isOpen={isDemoDialogOpened}
        onDismiss={() => setIsDemoDialogOpened(false)}
      />
    </>
  );
};

export const PLASMIC_HEADER_META: ComponentMeta<any> = {
  importPath: 'www',
  name: 'PlasmicHeader',
  props: {
    forceActiveMenuIndex: {
      type: 'number',
      defaultValue: undefined,
      description: 'Navigation menu to open (index)',
    },
    plasmicNavigation: {
      type: 'object',
      defaultValue: PLASMIC_HEADER_NAVIGATION,
    },
    appearance: {
      type: 'choice',
      options: ['dark', 'light'],
      defaultValue: 'dark',
    },
    initialBackground: 'string',
    scrolledBackground: 'string',
    hasAuthNavigation: {
      type: 'boolean',
      defaultValue: true,
    },
    hasMobileNavigation: {
      type: 'boolean',
      defaultValue: true,
    },
    hasLogo: {
      type: 'boolean',
      defaultValue: true,
    },
    hasNavigation: {
      type: 'boolean',
      defaultValue: true,
    },
    isFixed: {
      type: 'boolean',
      defaultValue: true,
    },
    isHiddenOnSticky: {
      type: 'boolean',
      defaultValue: false,
    },
    leadsource: 'string',
    router: 'string',
  },
  actions: [
    {
      type: 'custom-action',
      control: NavigationBuilderCustomAction,
    },
  ],
};
