import { CSS } from '@air/marketing-primitives';

import { AdornmentIconNames } from '../components/plasmic-header/helpers';
import { BlogRoutes, MARKETING_BASE, Routes, SharedRoutes } from './routes';

export type PlasmicHeaderNavigationMenuColumn = {
  name: string;
  hasDivider?: boolean;
  chunkSize?: number;
  css?: CSS;
  items: PlasmicHeaderNavigationMenuColumnItem[];
};

export type PlasmicHeaderNavigationMenuColumnItem = {
  adornment?: {
    iconName: AdornmentIconNames;
    backgroundColor?: string;
    color?: string;
    size?: 'small' | 'large';
  };
  description?: string;
  name: string;
  href: string;
};

export type PlasmicHeaderNavigationMenuColumnItemCTA = {
  name: string;
  title: string;
  description: string;
  link: {
    label: string;
    isDemo?: boolean;
    href?: string;
  };
  image: {
    src: string;
    height: number;
    width: number;
    marginBottom?: number;
  };
};

export type PlasmicHeaderNavigationItemType = {
  name: string;
  href?: string;
} & {
  color?: string;
  template?: string;
  columns?: PlasmicHeaderNavigationMenuColumn[];
  cta?: PlasmicHeaderNavigationMenuColumnItemCTA;
};

export const HEADER_CTA_IMAGES = {
  'heart-in-circle': `${MARKETING_BASE}/assets/marketing/header-menu-product.svg`,
  'cat-with-laptop': `${MARKETING_BASE}/assets/marketing/header-menu-enterprise.svg`,
  'flying-birds': `${MARKETING_BASE}/assets/marketing/header-menu-resources.svg`,
};

export const PLASMIC_HEADER_NAVIGATION: PlasmicHeaderNavigationItemType[] = [
  {
    name: 'Product',
    color: '#F0F3FF',
    columns: [
      {
        name: 'Features',
        chunkSize: 4,
        items: [
          {
            adornment: {
              iconName: 'Dart',
            },
            name: 'Smart search',
            description: 'We auto-tag your content',
            href: Routes.featuresSmartSearch,
          },
          {
            adornment: {
              iconName: 'Palette',
            },
            name: 'Dynamic feedback',
            description: 'Timestamp and point-based comments',
            href: Routes.featuresDynamicFeedback,
          },
          {
            adornment: {
              iconName: 'Kanban',
            },
            name: 'Kanban',
            description: 'Organize assets for your workflow',
            href: Routes.featuresKanban,
          },
          {
            adornment: {
              iconName: 'Stack',
            },
            name: 'Versions',
            description: 'Every asset is a folder for its own drafts',
            href: Routes.featuresVersions,
          },
        ],
      },
      {
        name: '',
        chunkSize: 4,
        css: {
          marginTop: 20,
        },
        items: [
          {
            adornment: {
              iconName: 'DesktopApp',
            },
            name: 'Air Lift for Mac',
            description: 'Simple, fast uploads from your desktop to Air',
            href: Routes.desktop,
          },
          {
            adornment: {
              iconName: 'Apple',
            },
            name: 'Air for iPhone',
            description: 'Simple, fast uploads from your iPhone to Air',
            href: Routes.iphone,
          },
          {
            adornment: {
              iconName: 'Device',
            },
            name: 'Air for Android',
            description: 'Simple, fast uploads from your Android to Air',
            href: SharedRoutes.playStore,
          },
          {
            adornment: {
              iconName: 'LinkIcon',
            },
            name: 'Integrations',
            description: 'Seamlessly integrate Air with your favorite tools',
            href: Routes.integrations,
          },
        ],
      },
    ],
    cta: {
      name: 'see air in action',
      title: 'Talk to a human',
      description: 'We’ll show you how Air can put time back in your day',
      link: {
        label: 'Book a demo →',
        isDemo: true,
      },
      image: {
        src: HEADER_CTA_IMAGES['heart-in-circle'],
        width: 160,
        height: 161,
      },
    },
    template: 'product',
  },
  {
    name: 'Customers',
    color: '$canary050',
    columns: [
      {
        name: 'By industry',
        chunkSize: 3,
        hasDivider: true,
        items: [
          {
            adornment: { iconName: 'Cart', backgroundColor: '$cuckoo100', color: '$cuckoo600' },
            name: 'E-commerce & DTC',
            description: 'Centralize retail & marketing work',
            href: Routes.industryRetail,
          },
          {
            adornment: { iconName: 'Megaphone', backgroundColor: '$peacock300', color: '$peacock700' },
            name: 'Agencies',
            description: 'Create a workspace for each client relationship',
            href: Routes.industryAgencies,
          },
          {
            adornment: { iconName: 'Popcorn', backgroundColor: '$canary300', color: '$canary700' },
            name: 'Media & Entertainment',
            description: 'Build a central library for all your visuals',
            href: Routes.industryMedia,
          },
          {
            adornment: { iconName: 'Home', backgroundColor: '$parrot050', color: '$parrot600' },
            name: 'Non-profits',
            description: 'Spend less time in the weeds, more time doing good work',
            href: Routes.industryNonprofits,
          },
          {
            adornment: { iconName: 'Soccer', backgroundColor: '$macaw040', color: '$macaw350' },
            name: 'Athletics & Sports',
            description: 'Every photo and video, stored and easily shareable',
            href: Routes.industryAthletics,
          },
          {
            adornment: { iconName: 'Apple', backgroundColor: '$flamingo200', color: '$flamingo800' },
            name: 'Education ',
            href: Routes.industryEducation,
            description: 'Store and distribute visuals easily',
          },
        ],
      },
      {
        name: 'By team',
        chunkSize: 4,
        items: [
          {
            adornment: { iconName: 'Dart', size: 'small' },
            name: 'Marketing',
            description: 'Accelerate campaign management',
            href: Routes.departmentMarketing,
          },
          {
            adornment: { iconName: 'Palette', size: 'small' },
            name: 'Creative',
            description: 'Spend more time creating & less time organizing',
            href: Routes.departmentDesignCreative,
          },
          {
            adornment: { iconName: 'Enterprise', size: 'small' },
            // transform: 'rotate(180deg)'
            name: 'Enterprise',
            description: 'Security, services and pricing for scale',
            href: Routes.enterprise,
          },
        ],
      },
    ],
    cta: {
      name: 'Customer stories',
      title: 'Top brands love Air',
      description: 'Read how Air saves teams time and headaches',
      link: {
        label: 'Learn more ->',
        href: Routes.customerStories,
      },
      image: {
        height: 161,
        src: HEADER_CTA_IMAGES['cat-with-laptop'],
        width: 160,
      },
    },
    template: 'enterprise',
  },
  {
    name: 'Learn',
    color: '$peacock100',
    columns: [
      {
        name: 'Switch from',
        chunkSize: 4,
        items: [
          {
            name: 'Dropbox',
            href: Routes.comparisonDropboxAlternative,
          },
          {
            name: 'Google Drive',
            href: Routes.comparisonGoogleDriveAlternative,
          },
          {
            name: 'Box',
            href: Routes.comparisonBox,
          },
          {
            name: 'DAM',
            href: Routes.comparisonDAM,
          },
        ],
      },
      {
        name: 'Resources',
        chunkSize: 4,
        hasDivider: true,
        items: [
          {
            name: 'Help center',
            href: SharedRoutes.helpCenter,
          },
          {
            name: 'Status page',
            href: SharedRoutes.status,
          },
          {
            name: 'Security',
            href: Routes.security,
          },
          {
            name: 'Release notes',
            href: Routes.releaseNotes,
          },
        ],
      },
      {
        name: 'Blog',
        chunkSize: 5,
        items: [
          {
            adornment: { iconName: 'NavBlog', size: 'small' },
            name: 'Blog homepage',
            href: Routes.blog,
          },
          {
            adornment: { iconName: 'Palette', size: 'small' },
            name: 'Creative ops',
            href: BlogRoutes.creativeOps,
          },
          {
            adornment: { iconName: 'MagicWand', size: 'small' },
            name: 'Building brands',
            href: BlogRoutes.buildingBrands,
          },
          {
            adornment: { iconName: 'Email', size: 'small' },
            name: 'Air news',
            href: BlogRoutes.airNews,
          },
        ],
      },
    ],
    cta: {
      name: 'Air does it better',
      title: 'DAM not your jam?',
      description: 'Switch to Air and we’ll buy out your contract',
      link: {
        label: 'Learn more →',
        href: '/landing/sem-lp-gated-buyout-4',
      },
      image: {
        height: 151,
        width: 150,
        src: HEADER_CTA_IMAGES['cat-with-laptop'],
        marginBottom: 80,
      },
    },
    template: 'resources',
  },
  {
    name: 'Why Air?',
    color: '$peacock100',
    columns: [
      {
        name: 'Use cases',
        chunkSize: 4,
        items: [
          {
            adornment: {
              iconName: 'Team',
              backgroundColor: '$macaw060',
              color: '$macaw500',
              size: 'small',
            },
            name: 'External collaboration',
            href: Routes.tourOnboardFreelancers,
          },
          {
            adornment: {
              iconName: 'Check',
              backgroundColor: '$peacock300',
              color: '$peacock700',
              size: 'small',
            },
            name: 'Creative approvals',
            href: Routes.tourCreativeApprovals,
          },
          {
            adornment: {
              iconName: 'Lock',
              backgroundColor: '$flamingo200',
              color: '$canary800',
              size: 'small',
            },
            name: 'Usage & access',
            href: Routes.tourRightsManagement,
          },
          {
            adornment: {
              iconName: 'Send',
              backgroundColor: '$canary300',
              color: '$flamingo900',
              size: 'small',
            },
            name: 'Project management',
            href: Routes.tourCreativeRequests,
          },
        ],
      },
      {
        name: 'Company',
        chunkSize: 5,
        hasDivider: true,
        items: [
          {
            adornment: { iconName: 'Info', size: 'small' },
            name: 'About us',
            href: Routes.about,
          },
          {
            adornment: { iconName: 'MagnifyingGlass', size: 'small' },
            name: 'Careers',
            href: Routes.careers,
          },
          {
            adornment: { iconName: 'Emoji', size: 'small' },
            name: 'Investors',
            href: Routes.operators,
          },
          {
            adornment: { iconName: 'Cart', size: 'small' },
            name: 'Air merch',
            href: Routes.airShop,
          },
        ],
      },
      {
        name: 'Life at Air',
        items: [
          {
            adornment: { iconName: 'NavCulture', size: 'small' },
            name: 'Culture',
            href: Routes.culture,
          },
          {
            adornment: { iconName: 'NavDiversity', size: 'small' },
            name: 'Diversity',
            href: Routes.diversity,
          },
          {
            adornment: { iconName: 'Team', size: 'small' },
            name: 'Inclusion',
            href: Routes.inclusion,
          },
          {
            adornment: { iconName: 'NavPolicies', size: 'small' },
            name: 'Policies',
            href: Routes.culturePolicies,
          },
        ],
      },
    ],
    cta: {
      name: 'Join our team',
      title: 'Explore open roles',
      description: 'We’re growing fast and always hiring',
      link: {
        label: 'Learn more →',
        href: Routes.careers,
      },
      image: {
        height: 151,
        src: HEADER_CTA_IMAGES['flying-birds'],
        width: 150,
        marginBottom: 100,
      },
    },
    template: 'why-air',
  },
  {
    name: 'Pricing',
    href: Routes.pricing,
  },
];
