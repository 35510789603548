import { CSS, styled, Text } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export const SECTION_ELEMENT_TAG = 'section';

export type RootProps = ComponentProps<typeof Root> & {
  css?: CSS;
};

export const Root = styled(SECTION_ELEMENT_TAG, {
  zIndex: 1,
  position: 'relative',
  boxSizing: 'border-box',
  overflow: 'hidden',
});

export const PreviewLabel = styled(Text, {
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '$macaw200',
  borderBottomRightRadius: 4,
  paddingY: 4,
  paddingX: 8,
  color: '$white',
  fontWeight: 'semibold',
});

PreviewLabel.defaultProps = {
  size: 'ui-12',
  transform: 'uppercase',
};

export const PreviewLabelLink = styled('a', {
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  fontSize: 'inherit',
  textDecoration: 'none',
});

PreviewLabelLink.defaultProps = {
  target: '_blank',
};
