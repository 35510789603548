import { styled } from '@air/marketing-primitives';
import { motion } from 'framer-motion';

export const StyledHeaderMenu = styled(motion.div, {
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  width: '100%',
  backgroundColor: 'white',
  paddingTop: 40,
  paddingBottom: 20,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  boxShadow: '0 10px 10px rgba(0, 0, 0, 0.06)',
  transformStyle: 'preserve-3d',
  transformOrigin: 'top',
  overflow: 'hidden',
});
