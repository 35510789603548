import { ArrowDown, ArrowUp } from '@air/icons';

import { adornmentIcons } from '../../helpers';
import { Button } from './Button';
import { Input } from './Input';

const headerAllIcons = Object.keys(adornmentIcons).map((key) => ({
  label: key,
  value: key,
}));

const iconsOptions = [{ label: 'Select icon', value: '' }, ...headerAllIcons];

const ICON_SIZES = ['Default', 'small', 'large'].map((key) => ({
  label: key,
  value: key === 'Default' ? '' : key,
}));

export type NavigationItemColumnRowProps = {
  parentField: string;
  onRemoveRow: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  isFirstRow: boolean;
  isLastRow: boolean;
};

export const NavigationItemColumnRow = ({
  parentField,
  onRemoveRow,
  onMoveUp,
  onMoveDown,
  isFirstRow,
  isLastRow,
}: NavigationItemColumnRowProps) => {
  return (
    <div style={{ padding: 20, backgroundColor: '#f3f3f3', marginBlock: 20, maxWidth: 800, position: 'relative' }}>
      <div style={{ display: 'flex', gap: 10 }}>
        <Input name={`${parentField}.name`} placeholder="title" />
        <Input name={`${parentField}.description`} placeholder="description" style={{ flex: 1 }} />
        <Input name={`${parentField}.href`} placeholder="href" style={{ flex: 1 }} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, gap: 10 }}>
        <Input as="select" name={`${parentField}.adornment.iconName`} placeholder="Icon name">
          {iconsOptions.map((icon) => (
            <option value={icon.value} key={icon.label}>
              {icon.label}
            </option>
          ))}
        </Input>

        <Input as="select" name={`${parentField}.adornment.size`} placeholder="Icon size" style={{ minWidth: 100 }}>
          {ICON_SIZES.map((icon) => (
            <option value={icon.value} key={icon.value}>
              {icon.label}
            </option>
          ))}
        </Input>
        <Input name={`${parentField}.adornment.color`} placeholder="Icon color" />
        <Input name={`${parentField}.adornment.backgroundColor`} placeholder="Icon background" />
      </div>
      <Button style={{ position: 'absolute', top: 0, left: -40 }} onClick={onMoveUp} disabled={isFirstRow}>
        <ArrowUp width={20} height={25} />
      </Button>
      <Button style={{ position: 'absolute', bottom: 0, left: -40 }} onClick={onMoveDown} disabled={isLastRow}>
        <ArrowDown width={20} height={25} />
      </Button>
      <Button
        variant="danger"
        style={{
          position: 'absolute',
          right: -10,
          top: -15,
        }}
        onClick={onRemoveRow}
      >
        Delete
      </Button>
    </div>
  );
};
