const config = require('@air/tailwind-config');
config.theme.fontFamily = {
  ...config.theme.fontFamily,
  display: ['Gelica', 'Georgia', 'serif'],
  copy: [
    'Inter',
    'system-ui',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Ubuntu',
    'Cantarell',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji',
  ],
  mono: [
    'Menlo',
    'Consolas',
    'Roboto Mono',
    'Ubuntu Monospace',
    'Noto Mono',
    'Oxygen Mono',
    'Liberation Mono',
    'monospace',
  ],
};

config.theme.extend.fontSize = {
  ...config.theme.extend.fontSize,
  'header-lg': [56, { lineHeight: '64px' }],
  'header-md': [32, { lineHeight: '36px' }],
  'body-xs': [10, { lineHeight: '15px', letterSpacing: '-.06px' }],
  'body-sm': [12, { lineHeight: '18px', letterSpacing: '.36px' }],
  'body-md': [14, { lineHeight: '20px' }],
  'body-md-plus': [18, { lineHeight: '22px' }],
  'body-lg': [24, { lineHeight: '32px' }],
  'display-md': [16, { lineHeight: '24px' }],
};

config.theme.extend.colors = {
  ...config.theme.extend.colors,
  greenBird: '#EEFCDD',
  grayBird: '#CCCCD7',
  'pale-blue': {
    200: '#F7FCFF',
    DEFAULT: '#A9CDEB',
    400: '#939DFF',
    500: '#7C95FF',
    600: '#3E418A',
    900: '#010256',
    950: '#06113F',
  },
  athensGray: '#F2F2F5',
  lightGrayBird: '#F6F6F6',
};

config.theme.extend.boxShadow = {
  ...config.theme.extend.boxShadow,
  'paige-glow': '0px 6px 30px 0px rgba(147,157,255,0.2)',
  tag: '0px 4px 20px 3px rgba(0,0,0,0.1)',
};

config.theme.extend.screens = {
  '3xl': '1296px',
};

module.exports = config;
