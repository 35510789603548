import { FC, useContext } from 'react';

import { BlurbContext } from './HomeScaryQuotesSectionItemProvider';

type HomeScaryQuotesSectionItemProps = {
  blurbId: string;
};

export const HomeScaryQuotesSectionItem: FC<HomeScaryQuotesSectionItemProps> = ({ blurbId, children }) => {
  const { id: idToRender } = useContext(BlurbContext);

  return idToRender === blurbId ? <>{children}</> : null;
};
