import { COMPONENT_META, VideoProps } from '@air/plasmic/src/components/react-player/Video';

type propsFromMetaType = {
  [key: string]: {
    defaultValue: any;
  };
};

const defaultVideoProps = Object.entries(COMPONENT_META.props as propsFromMetaType)
  .map(([key, value]: [string, propsFromMetaType]) => {
    return {
      [key]: value?.defaultValue,
    };
  })
  .reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  });

type VideoTab = {
  headline: string;
  copy: string;
  video: VideoProps;
};

export const videoTabsData: VideoTab[] = [
  {
    headline: 'Collect',
    copy: "Imagine all of your team's assets and creative tools in one intuitive workspace. No more having to remember which JPEG is the right one—Air knows. ",
    video: {
      ...defaultVideoProps,
      className: 'pl-video-tab__video',
      type: 'wistia',
      urlVideo: 'https://air-72.wistia.com/medias/7msrv2nkbf',
      wistiaUrl: 'https://air-72.wistia.com/medias/7msrv2nkbf',
      ratioWidth: 843,
      ratioHeight: 400,
      useAspectRatio: true,
    },
  },
  {
    headline: 'Approve',
    copy: "Air centralizes comments, feedback, and approval around the assets themselves. You'll never lose the thread on your edits, and your overcrowded browser windows will thank you.",
    video: {
      ...defaultVideoProps,
      className: 'pl-video-tab__video',
      type: 'wistia',
      urlVideo: 'https://air-72.wistia.com/medias/lf5gtnvb3p',
      wistiaUrl: 'https://air-72.wistia.com/medias/lf5gtnvb3p',
      ratioWidth: 663,
      ratioHeight: 468,
      useAspectRatio: true,
    },
  },
  {
    headline: 'Share',
    copy: "Putting your work out there has never been easier, thanks to Air's advanced cross-functional sharing capabilities. With just a few clicks, your content is out in the world.",
    video: {
      ...defaultVideoProps,
      className: 'pl-video-tab__video',
      type: 'wistia',
      urlVideo: 'https://air-72.wistia.com/medias/b7dhkqzrhg',
      wistiaUrl: 'https://air-72.wistia.com/medias/b7dhkqzrhg',
      ratioWidth: 663,
      ratioHeight: 468,
      useAspectRatio: true,
    },
  },
];
