import { PlasmicComponent } from '@plasmicapp/loader-nextjs';
import { useController } from 'react-hook-form';

export const Select = ({
  name: baseName,
  required,
  value: baseValue,
  onChange: originalOnChange,
  ...restOfProps
}: any) => {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: baseName,
    rules: { required },
    defaultValue: baseValue ?? '',
  });

  const handleChange = (value: string) => {
    onChange(value);
    originalOnChange(value);
  };

  return (
    <PlasmicComponent
      component="Form / Select"
      componentProps={{
        onChange: handleChange,
        onBlur,
        ...restOfProps,
        name,
        value,
        ref,
      }}
      forceOriginal
    />
  );
};
