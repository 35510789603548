export const testimonials = [
  {
    author: 'Kendall Dickieson',
    twitterHandle: 'flexiblefoodie',
    gravatar: {
      src: '/assets/testimonials/kendall.png',
      alt: 'Kendall Dickieson',
      width: 100,
      height: 100,
    },
    quote: {
      __html: '<p>life hack: @airHQ</p>',
    },
  },
  {
    author: 'Jarod Steffes',
    twitterHandle: 'jarodsteffes',
    gravatar: {
      src: '/assets/testimonials/jarod.png',
      alt: 'Jarod Steffes',
      width: 100,
      height: 100,
    },
    quote: {
      __html:
        '<p>We are officially on the @airHQ and to say the least, the hype is REAL! Incredibly easy to use, manage, and find assets over Google Drive or Dropbox. Highly recommend to anyone!</p><p>The best hidden feature we found: can compress images directly in the dashboard before downloading 👍</p>',
    },
  },
  {
    author: 'Adam',
    twitterHandle: 'adzreed',
    gravatar: {
      src: '/assets/testimonials/adam.png',
      alt: 'Adam',
      width: 100,
      height: 100,
    },
    quote: {
      __html: "<p>Best thing we've done this month is move our asset library from Google Drive to Air.</p>",
    },
  },
  {
    author: 'TinyAtlasQuarterly',
    twitterHandle: 'TinyAtlasQtrly',
    gravatar: {
      src: '/assets/testimonials/tiny_atlas.png',
      alt: 'TinyAtlasQuarterly',
      width: 100,
      height: 100,
    },
    quote: {
      __html:
        '<p>So happy to have Air every time I use it. Commenting and @ ing people on videos while watching/editing?!! The best thing that has happened to CMS in a long while.</p>',
    },
  },
  {
    author: 'Mordechai Hoffman',
    twitterHandle: 'mordechaihoff',
    gravatar: {
      src: '/assets/testimonials/mordechaihoff.jpeg',
      alt: 'Mordechai Hoffman',
      width: 100,
      height: 100,
    },
    quote: {
      __html: "<p>@airHQ has gotten a ton of love lately, and rightfully so. It's a great product</p>",
    },
  },
  {
    author: 'Alexa Kilroy',
    twitterHandle: 'AlexaKilroy',
    gravatar: {
      src: '/assets/testimonials/AlexaKilroy.jpeg',
      alt: 'Alexa Kilroy',
      width: 100,
      height: 100,
    },
    quote: {
      __html: '<p>@airHQ does what frame does + way more and it is incredible</p>',
    },
  },
  {
    author: 'Jason Wong',
    twitterHandle: 'EggrolI',
    gravatar: {
      src: '/assets/testimonials/Eggroll.jpeg',
      alt: 'Jason Wong',
      width: 100,
      height: 100,
    },
    quote: {
      __html:
        "<p>Air.inc completely replaced Google Drive for me <br/> There's no reason why you would use G-Drive after moving to Air. It's extremely affordable and helps organize all your assets across teams. The searchability of Air is light years ahead of anyone else</p>",
    },
  },
  {
    author: 'Kevin Lee',
    twitterHandle: 'kevinleeme',
    gravatar: {
      src: '/assets/testimonials/kevinleeme.jpeg',
      alt: 'Kevin Lee',
      width: 100,
      height: 100,
    },
    quote: {
      __html:
        "<p>I've raved about @airHQ before and how it's fundamentally changed the way our team organizes our creative assets. The difference feels like migrating from Evernote to Notion or from a dog that sleeps all day to dunking on hoops like Air Bud - it's that drastic</p>",
    },
  },
  {
    author: 'Eli Weiss',
    twitterHandle: 'eliweiss',
    gravatar: {
      src: '/assets/testimonials/eliweiss.jpeg',
      alt: 'Eli Weiss',
      width: 100,
      height: 100,
    },
    quote: {
      __html: "<p>Love the air team - use it for our podcast content and it's a breeze</p>",
    },
  },
  {
    author: 'Rabah Rahil',
    twitterHandle: 'rabahrahil',
    gravatar: {
      src: '/assets/testimonials/rabahrahil.jpeg',
      alt: 'Rabah Rahil',
      width: 100,
      height: 100,
    },
    quote: {
      __html: '<p>Love @airHQ and the peeps are incredible</p>',
    },
  },
];
