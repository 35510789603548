// Create a function that randomizes the position of each image within its grid cell COPILOT helped with this
export const randomizePosition = (cssProperty: 'flex' | 'object-fit') => {
  const flexAlignClasses = ['items-start', 'items-end', 'items-center'];
  const flexJustifyClasses = ['justify-start', 'justify-end', 'justify-center'];

  const flexPossibleCombinations = flexAlignClasses.map((alignClass) => {
    // Create an array of strings with the possible combinations of flex alignment and justify classes
    return flexJustifyClasses.map((justifyClass) => {
      return `${alignClass} ${justifyClass}`;
    });
  });

  const objectFitClasses = [
    'object-center',
    'object-top',
    'object-bottom',
    'object-left',
    'object-right',
    'object-top-left',
    'object-top-right',
    'object-bottom-left',
    'object-bottom-right',
  ];
  // Create an array with possible object-position values
  const tailwindPositionClasses = cssProperty === 'object-fit' ? objectFitClasses : flexPossibleCombinations.flat();
  // Shuffle the array
  tailwindPositionClasses.sort(() => Math.random() - 0.5);
  // Return the shuffled array
  return tailwindPositionClasses[0];
};
