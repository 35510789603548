import { CSS, styled, Text } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export const HEADER_NAVIGATION_ITEM_ELEMENT_TAG = 'div';

export type StyledHeaderNavigationItemProps = ComponentProps<typeof HEADER_NAVIGATION_ITEM_ELEMENT_TAG> & {
  css?: CSS;
};

export const StyledHeaderNavigationItem = styled(HEADER_NAVIGATION_ITEM_ELEMENT_TAG, {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginRight: 12,
  paddingY: 4,
  paddingX: 8,
  color: 'inherit',
  transition: 'color 0.2s ease',

  [`& > ${Text}`]: {
    color: 'inherit',
    fontWeight: '$medium',
    textDecoration: 'none',
  },
});
