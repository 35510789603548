import { Box, Text } from '@air/marketing-primitives';

import { CompetitionGraphic } from '../competitionGraphic/CompetitionGraphic';

export type FiftyFiftyTextGraphicProps = {
  headline?: string;
  ctaText?: string;
  ctaLink?: string;
};

export const FiftyFiftyTextGraphic = ({ headline, ctaText, ctaLink }: FiftyFiftyTextGraphicProps) => {
  const copy = {
    __html:
      "<p>It's not their fault: GDrive and Dropbox simply weren't built for the creative process. Your basic cloud storage system doesn't know <em>how</em> to help you collect content, approve assets, or share versions for feedback. So, all that extra organization? It's left up to <em>you</em>.</p><p>Air doesn't just store. It thinks the way <em>you</em> think.</p>",
  };
  return (
    <div className="py-[72px] lg:py-[144px] overflow-x-hidden text-jay-900 bg-peacock-50">
      <div className="container flex flex-col lg:flex-row flex-nowrap mx-auto items-center">
        <div className="relative lg:basis-1/2 flex-grow-0 flex items-center justify-center min-h-[380px] px-8 text-center mb-12 lg:mb-0">
          <h2 className="text-header-md font-display font-semibold max-w-[315px] mb-20 lg:mb-0 relative z-[2]">
            {headline}
          </h2>
          <div className="absolute inset-0 w-full h-full z-[1]">
            <CompetitionGraphic />
          </div>
        </div>
        <div className="px-8 lg:basis-1/2 flex-grow-0">
          <div className="lg:min-w-[432px] lg:w-[calc(100%*(432/(1440/2)))] mx-auto">
            <Box
              className="text-body-md lg:text-body-lg pl-rich-text text-16 leading-[24px]"
              dangerouslySetInnerHTML={copy}
            ></Box>
            <Text as="a" href={ctaLink} className="pl-button pl-button--dark mt-8">
              {ctaText}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
