import Image from 'next/image';
import Link from 'next/link';
import AirFlowImage from 'public/assets/feature-wall/airflow.png';
import AirFlowImageLg from 'public/assets/feature-wall/airflow-lg.png';
import CollaboratorsImage from 'public/assets/feature-wall/collaborators.png';
import ConfigurableImage from 'public/assets/feature-wall/configuration.png';
import IntegrationsImageLg from 'public/assets/feature-wall/integrations-lg.png';
import IntegrationsImageSm from 'public/assets/feature-wall/integrations-sm.png';
import PricingImage from 'public/assets/feature-wall/pricing.png';
import SecurityImage from 'public/assets/feature-wall/security.png';
import UsageReportsImageLg from 'public/assets/feature-wall/usage-lg.png';
import UsageReportsImageSm from 'public/assets/feature-wall/usage-sm.png';

const images = {
  airflow: {
    src: AirFlowImage,
    alt: 'Air Flow for MacOS',
    width: 1031,
    height: 680,
  },
  airflowLg: {
    src: AirFlowImageLg,
    alt: 'Air Flow for MacOS',
    width: 894,
    height: 680,
  },
  integrationsSm: {
    src: IntegrationsImageSm,
    alt: 'Integrations',
    width: 277,
    height: 276,
  },
  integrationsLg: {
    src: IntegrationsImageLg,
    alt: 'Integrations',
    width: 332,
    height: 616.5,
  },
  security: {
    src: SecurityImage,
    alt: 'Enterprise-grade security',
    width: 927,
    height: 715,
  },
  configuration: {
    src: ConfigurableImage,
    alt: 'Configurable user permissions',
    width: 300,
    height: 145,
  },
  pricing: {
    src: PricingImage,
    alt: 'Simple, transparent pricing',
    width: 654,
    height: 378,
  },
  usageSm: {
    src: UsageReportsImageSm,
    alt: 'Usage reports',
    width: 604,
    height: 513,
  },
  usageLg: {
    src: UsageReportsImageLg,
    alt: 'Usage reports',
    width: 591,
    height: 513.5,
  },
  collaborators: {
    src: CollaboratorsImage,
    alt: 'Libraries for partners and collaborators',
    width: 910,
    height: 831,
  },
};

type CardContainerProps = {
  title: string;
  copy?: string;
  classes?: string;
  cardGrid?: boolean;
  children?: React.ReactNode;
  overlayChildren?: React.ReactNode;
  link: string;
};

const CardContainer = ({ title, copy, cardGrid, classes, link, children }: CardContainerProps) => {
  return (
    <div
      className={`px-6 py-4 lg:px-11 lg:py-8 xl:py-10 bg-athensGray rounded-lg overflow-hidden relative h-full ${
        cardGrid ? `lg:grid lg:grid-cols-8 lg:gap-x-6` : ''
      } ${classes ? classes : ''}`}
    >
      <div className={`mb-7 flex items-start justify-between gap-x-4 ${cardGrid ? 'lg:col-span-4' : ''}`}>
        <div>
          <p className="text-header-md text-jay-900 font-display font-semibold z-[1] relative">{title}</p>
          {copy && <p className="text-body-md text-jay-900 mt-3">{copy}</p>}
        </div>

        <div
          className={`flex-shrink-0 lg:absolute lg:left-[30px] lg:bottom-[15px] xl:left-[50px] xl:bottom-[45px] relative z-[2]`}
        >
          <Link href={link}>
            <a className="relative z-[2] cursor-pointer transition-transform hover:-translate-y-1 block duration-200 ease-out">
              <span className="sr-only">Open Button</span>
              <svg
                className="text-jay-500 hover:text-jay-600 transition-colors duration-300 ease-in-out"
                width="48"
                height="48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="24" cy="24" r="24" fill="currentColor" />
                <path
                  d="M14 25c10.5-2 21 0 21 0m0 0c-3-2.5-7.5-8-7.5-8m7.5 8c-3 2-7.5 7-7.5 7"
                  stroke="#fff"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </Link>
        </div>
      </div>

      {children}
    </div>
  );
};

const AirFlow = () => {
  return (
    <CardContainer
      title="Air Flow for MacOS"
      copy="Native desktop app so that you can sync files locally and push updates to the cloud"
      cardGrid={true}
      link="/air-flow"
      classes="lg:min-h-[300px]"
    >
      <div className="-ml-16 -mb-7 lg:ml-auto lg:-mr-11 lg:-mb-11 xl:-mb-10 text-right lg:col-span-4 lg:hidden">
        <Image
          src={images.airflow.src}
          alt={images.airflow.alt}
          width={images.airflow.width}
          height={images.airflow.height}
        />
      </div>
      <div className="absolute bottom-0 right-0 lg:max-w-[51%] lg:ml-auto lg:-mr-5 lg:-mb-6 xl:-mb-8 xl:-mr-7 text-right lg:col-span-4 hidden lg:block">
        <Image
          src={images.airflowLg.src}
          alt={images.airflowLg.alt}
          width={images.airflowLg.width}
          height={images.airflowLg.height}
        />
      </div>
    </CardContainer>
  );
};

const Integrations = () => {
  return (
    <CardContainer title="Integrations" copy=" Plug Air into 1,000+ other tools" link="/integrations">
      <div className="lg:hidden table mx-auto">
        <Image
          className="lg:hidden table mx-auto"
          src={images.integrationsSm.src}
          alt={images.integrationsSm.alt}
          width={images.integrationsSm.width}
          height={images.integrationsSm.height}
        />
      </div>
      <div className="hidden lg:inline-block pb-12">
        <Image
          src={images.integrationsLg.src}
          alt={images.integrationsLg.alt}
          width={images.integrationsLg.width}
          height={images.integrationsLg.height}
        />
      </div>
    </CardContainer>
  );
};

const EnterpriseSecurity = () => {
  return (
    <CardContainer title="Enterprise-grade security" link="/security">
      <div className="-mx-6 -mb-4 lg:-mb-10 lg:-mx-8 xl:-mx-14 xl:-mb-14">
        <Image
          src={images.security.src}
          alt={images.security.alt}
          width={images.security.width}
          height={images.security.height}
        />
      </div>
    </CardContainer>
  );
};

const Configuration = () => {
  return (
    <CardContainer title="Configurable user permissions" link="/features/versions">
      <div className="table mx-auto">
        <Image
          src={images.configuration.src}
          alt={images.configuration.alt}
          width={images.configuration.width}
          height={images.configuration.height}
        />
      </div>
    </CardContainer>
  );
};

const Pricing = () => {
  return (
    <CardContainer
      title="Simple, transparent pricing"
      copy="Get started for free, and try before you buy. Our self-serve plans are perfect for growing teams and our enterprise product can be fit to your needs."
      link="/pricing"
    >
      <div className="relative -mx-6 lg:max-w-[275px] lg:-mr-11 lg:ml-auto lg:-mb-10 -bottom-[28px] lg:-bottom-[8px]">
        <Image
          src={images.pricing.src}
          alt={images.pricing.alt}
          width={images.pricing.width}
          height={images.pricing.height}
        />
      </div>
    </CardContainer>
  );
};

const UsageReports = () => {
  return (
    <CardContainer
      title="Usage reports"
      copy="For our enterprise customers, we create custom usage reports to help you understand who is using your content and how it's being used."
      link="/enterprise"
    >
      <div className="mt-8 pb-6 lg:hidden">
        <Image
          src={images.usageSm.src}
          alt={images.usageSm.alt}
          width={images.usageSm.width}
          height={images.usageSm.height}
        />
      </div>
      <div className="lg:mt-24 lg:-mr-11 hidden lg:block">
        <Image
          src={images.usageLg.src}
          alt={images.usageLg.alt}
          width={images.usageLg.width}
          height={images.usageLg.height}
        />
      </div>
    </CardContainer>
  );
};

const PartnerLibraries = () => {
  return (
    <CardContainer title="Libraries for partners and collaborators" link="/features/libraries">
      <div className="-mr-6 lg:-mr-11 lg:-mb-16 text-right">
        <Image
          src={images.collaborators.src}
          alt={images.collaborators.alt}
          width={images.collaborators.width}
          height={images.collaborators.height}
        />
      </div>
    </CardContainer>
  );
};

type FeatureWallProps = {
  headline: string;
};

export const FeatureWall = ({ headline }: FeatureWallProps) => {
  return (
    <div className="bg-jay-900 text-white py-16 lg:py-24">
      <p className="text-center text-header-md text-white font-display font-semibold pb-12 lg:text-header-lg lg:pb-20">
        {headline}
      </p>
      <div className="max-w-[1225px] mx-auto px-8">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-12 lg:auto-rows-auto lg:gap-4">
          <div className="col-span-1 lg:col-span-8 row-span-1">
            <AirFlow />
          </div>
          <div className="col-span-1 lg:col-span-4 row-span-2">
            <Integrations />
          </div>
          <div className="col-span-1 lg:col-span-4 row-span-1">
            <EnterpriseSecurity />
          </div>
          <div className="col-span-1 lg:col-span-4 row-span-1">
            <Configuration />
          </div>

          <div className="col-span-1 lg:col-span-6 row-span-1">
            <Pricing />
          </div>

          <div className="col-span-1 lg:col-span-6 row-span-2">
            <UsageReports />
          </div>

          <div className="col-span-1 lg:col-span-6 row-span-1">
            <PartnerLibraries />
          </div>
        </div>
      </div>
    </div>
  );
};
