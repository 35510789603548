import { CSS, styled } from '@air/marketing-primitives';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';

export type RootProps = ComponentProps<typeof Root> & {
  css?: CSS;
};

export const Root = styled('button', {
  display: 'block',
  flexShrink: 0,
  backgroundColor: 'transparent',
  width: 40,
  height: 40,
  border: 0,
  borderRadius: 4,
  paddingX: 8,
  color: '$jay700',
  cursor: 'pointer',
});

export const Bar = styled(motion.div, {
  backgroundColor: 'currentColor',
  height: 2,
  marginY: 6,
  transformOrigin: 'center',
});
