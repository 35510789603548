import { ChilipiperLeadsource, ChilipiperRouter, useChilipiper } from '@air/plasmic-chilipiper';
import * as Dialog from '@radix-ui/react-dialog';
import { Form, Formik, useField } from 'formik';
import { type ReactNode, ComponentProps } from 'react';
import { object, string } from 'yup';

const Input = (props: ComponentProps<'input'> & Required<Pick<ComponentProps<'input'>, 'name'>>) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full relative">
      <input
        className="h-8 bg-transparent border-b border-b-[#D9D9D9] text-14 w-full outline-none focus:bg-white/10 text-[#D9D9D9]"
        style={{
          fontFamily: 'PP Mori',
        }}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-10 absolute top-[calc(100%+8px)] text-red-800">{meta.error}</div>
      ) : null}
    </div>
  );
};

const Select = (props: ComponentProps<'select'> & Required<Pick<ComponentProps<'select'>, 'name'>>) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full relative">
      <select
        className="h-8 bg-transparent border-b border-b-[#D9D9D9] text-14 w-full outline-none focus:bg-white/10 text-[#D9D9D9]"
        style={{
          fontFamily: 'PP Mori',
        }}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-10 absolute top-[calc(100%+8px)] text-red-800">{meta.error}</div>
      ) : null}
    </div>
  );
};

const validationSchema = object({
  firstName: string().default('').required('First name is required'),
  lastName: string().default('').required('Last name is required'),
  email: string().email().default('').required('Email address is required'),
  company: string().default('').required('Company is required'),
  company_size: string().default('').required('Company Size is required'),
});

export type WebbyModalProps = {
  button: ReactNode;
  id?: string;
  leadsource?: ChilipiperLeadsource;
};

export const WebbyModal = ({
  button,
  id = ChilipiperRouter['limit-fields-aug-22'],
  leadsource = ChilipiperLeadsource.Default,
}: WebbyModalProps) => {
  const { createLead } = useChilipiper({
    router: id,
    leadsource,
  });
  console.log(leadsource);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{button}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="z-10 bg-white/50 backdrop-blur-sm fixed inset-0" />
        <Dialog.Content className="flex flex-col gap-6 bg-[#141414] fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[475px] translate-x-[-50%] translate-y-[-50%] focus:outline-none z-10 rounded-xl p-10 overflow-y-auto">
          <div className="flex md:hidden justify-end mb-4">
            <Dialog.Close className="w-5 h-5 border-2 border-[#D9D9D9] rounded-full text-[#D9D9D9] items-center justify-center flex -mr-4 -mt-4">
              <svg className="w-2 h-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
                <path
                  fill="#D9D9D9"
                  stroke="#D9D9D9"
                  d="M17.406 1.593a.531.531 0 0 0-.75 0L9.5 8.75 2.344 1.593a.528.528 0 0 0-.75.01.529.529 0 0 0 0 .741L8.748 9.5l-7.156 7.156a.531.531 0 0 0 .751.75L9.5 10.252l7.155 7.156a.531.531 0 0 0 .751-.751L10.251 9.5l7.155-7.156a.537.537 0 0 0 0-.755v.004Z"
                />
              </svg>
            </Dialog.Close>
          </div>

          <Dialog.Title
            className="uppercase text-center text-[78px] md:text-[107px] font-medium"
            style={{
              color: '#D9D9D9',
              fontFamily: 'Druk Cyr',
              lineHeight: '90%',
            }}
          >
            ENTER TO WIN
          </Dialog.Title>
          <Dialog.Description
            className="text-12 text-center"
            style={{
              color: '#D9D9D9',
              fontFamily: 'PP Mori',
              lineHeight: '140%',
            }}
          >
            Submissions close May 17th. Winners will be randomly selected and contacted by May 24th. Open only to
            winners of the 2024 Webby Awards.
          </Dialog.Description>
          <Formik
            initialValues={validationSchema.cast(undefined)!}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              createLead({
                values,
                onSuccess: () => {
                  setSubmitting(false);
                  resetForm();
                },
              });
            }}
            validationSchema={validationSchema}
          >
            <Form className="flex flex-col gap-8">
              <div className="flex gap-8">
                <Input name="firstName" placeholder="First name" required />
                <Input name="lastName" placeholder="Last name" required />
              </div>
              <Input name="email" placeholder="Email" required />
              <Input name="company" placeholder="Company" required />
              <Select name="company_size" required>
                <option value="" selected disabled hidden>
                  Company Size
                </option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-100">51-100</option>
                <option value="101-500">101-500</option>
                <option value="501-1000">501-1000</option>
                <option value="1001+">1001+</option>
              </Select>
              <button
                className="h-11 rounded-full uppercase bg-[#D9D9D9] text-[#141414] text-[30px] px-8 flex items-center mx-auto font-medium"
                style={{
                  fontFamily: 'Druk Cyr',
                }}
                type="submit"
              >
                Buy my webby
              </button>
            </Form>
          </Formik>
        </Dialog.Content>
        <Dialog.Close className="fixed top-10 right-10 z-10 w-10 h-10 border-2 border-[#141414] rounded-full text-[#141414] items-center justify-center hidden md:flex">
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 19">
            <path
              fill="#141414"
              stroke="#141414"
              d="M17.406 1.593a.531.531 0 0 0-.75 0L9.5 8.75 2.344 1.593a.528.528 0 0 0-.75.01.529.529 0 0 0 0 .741L8.748 9.5l-7.156 7.156a.531.531 0 0 0 .751.75L9.5 10.252l7.155 7.156a.531.531 0 0 0 .751-.751L10.251 9.5l7.155-7.156a.537.537 0 0 0 0-.755v.004Z"
            />
          </svg>
        </Dialog.Close>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
