import { registerComponent, RegisterComponentParams } from '@air/plasmic-core';

import { PLASMIC_HEADER_META, PlasmicHeader } from './components/plasmic-header/PlasmicHeader';

export const registerPlasmicHeader = ({ loader }: Pick<RegisterComponentParams, 'loader'>) => {
  return registerComponent({
    component: PlasmicHeader,
    loader,
    meta: PLASMIC_HEADER_META,
  });
};
