/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';

export const DrukCyr = () => {
  return (
    <Head>
      <link href="/fonts/druk-cyr/druk-cyr.css" rel="stylesheet" key="druk-cyr" />
    </Head>
  );
};
