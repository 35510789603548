import { Box, Text } from '@air/marketing-primitives';
import { COMPONENT_META, Video } from '@air/plasmic/src/components/react-player/Video';
import { useInView } from 'react-intersection-observer';

type propsFromMetaType = {
  [key: string]: {
    defaultValue: any;
  };
};

const videoProps = Object.entries(COMPONENT_META.props as propsFromMetaType)
  .map(([key, value]: [string, propsFromMetaType]) => {
    return {
      [key]: value?.defaultValue,
    };
  })
  .reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  });

// export type RichText = {
//   __html: string;
// };

export type FiftyFiftyTextVideoProps = {
  headline?: string;
  content?: string;
  ctaLabel?: string;
  ctaLink?: string;
  ctaType?: 'internal' | 'external';
  videoUrl?: string;
  videoType: 'wistia' | 'youtube' | 'air';
};

export const FiftyFiftyTextVideo = ({
  content,
  headline,
  ctaLabel,
  ctaLink,
  videoUrl,
  videoType,
}: FiftyFiftyTextVideoProps) => {
  const { ref, inView } = useInView({
    rootMargin: '200px 0px',
  });

  const copy = {
    __html:
      content ??
      "<p>It's a visual workspace that optimizes how teams collect, approve, and share content.</p><p>We call this kind of work creative operations, or “Creative Ops.” You're probably a little too familiar with it: all the thankless, manual parts of the creative process like finding the most recent file or trying to give someone feedback.  Air keeps all of it organized, returning hours to your day.</p>",
  };

  return (
    <div className="flex flex-col lg:flex-row flex-nowrap py-[72px] lg:py-[144px] overflow-x-hidden">
      <div className="order-2 lg:order-1 px-8 text-jay-900 flex-grow flex-shrink-0 lg:basis-1/2 lg:my-auto">
        <div className="lg:min-w-[432px] lg:w-[calc(100%*(432/(1440/2)))] mx-auto">
          <h2 className="font-display text-header-md font-semibold mb-8">{headline}</h2>
          <Box className="text-body-md-plus mb-10 pl-rich-text" dangerouslySetInnerHTML={copy}></Box>
          <Text as="a" href={ctaLink} className="pl-button pl-button--dark">
            {ctaLabel}
          </Text>
        </div>
      </div>
      <div
        ref={ref}
        className="order-1 lg:order-2 pl-8 lg:pl-0 flex-grow h-fit mb-12 lg:mb-0 rounded-[8px] pl-box-shadow overflow-hidden"
      >
        <Video
          {...videoProps}
          className="pl-off-canvas-video w-full h-full overflow-hidden"
          ratioHeight={547}
          ratioWidth={820}
          type={videoType}
          autoPlay={true}
          loop={true}
          muted={true}
          controls={false}
          wistiaAutoPlay={true}
          urlVideo={videoUrl}
          wistiaUrl={videoUrl}
          wistiaFitStrategy="cover"
          wistiaPlaybar={false}
          wistiaMuted={true}
          wistiaPlayButton={false}
          wistiaEndVideoBehavior="loop"
          wistiaControlsVisibleOnLoad={false}
          wistiaPlaysInline={true}
          playOnVisible={inView}
        />
      </div>
    </div>
  );
};
