import { useState } from 'react';

import { PlasmicHeaderNavigationItemType } from '../../../constants/plasmic-navigation';
import { Button } from './form/Button';
import { NavigationBuilderModal } from './NavigationBuilderModal';

export const NavigationBuilderCustomAction = ({ studioOps, componentProps }: any) => {
  // A form that adds a new tab with the given key
  const [open, setOpen] = useState(false);
  const updateNavigation = (navigation: PlasmicHeaderNavigationItemType[]) => {
    studioOps.updateProps({
      plasmicNavigation: navigation,
    });
  };

  const closeModal = () => setOpen(false);

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Edit navigation</Button>
      <NavigationBuilderModal
        open={open}
        navigation={componentProps.plasmicNavigation}
        updateNavigation={updateNavigation}
        closeModal={closeModal}
      />
    </div>
  );
};
