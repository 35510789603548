import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

import useIsomorphicLayoutEffect from '~/marketing/utils/useIsomorphicLayoutEffect';
import useTailwindBreakpoint from '~/marketing/utils/useTailwindBreakpoint';

import { BubbleText } from './BubbleText';

gsap.registerPlugin(ScrollTrigger);

export const Features = () => {
  let tailwindbrk: any = useTailwindBreakpoint();
  let [currentBg, updateBg] = useState("url('/assets/paige/features-bg.svg')");
  let [position, updatePosition] = useState('');
  const featuresWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (['sm', 'md'].includes(tailwindbrk)) {
      updateBg("url('/assets/paige/features-bg.svg')");
      updatePosition('0px');
    } else {
      updateBg("url('/assets/paige/features-bg-desktop.svg')");
      updatePosition('-750px');
    }
  }, [tailwindbrk]);

  useIsomorphicLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      const veggieTl = gsap.timeline({
        repeat: 0,
        scrollTrigger: {
          trigger: '.veggie-on-shelf',
          start: 'top bottom',
        },
      });
      veggieTl.fromTo(
        '.veggie-on-shelf',
        {
          opacity: 0,
          y: 100,
          scale: 0.4,
        },
        {
          ease: 'back.out(1.7)',
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1,
        },
      );
      veggieTl.fromTo(
        '.veggie-on-shelf .group-tag',
        {
          scale: 0.1,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 0.3,
          stagger: 0.15,
          delay: 0.1,
          ease: 'back.out(1.7)',
        },
      );

      const faceRecTl = gsap.timeline({
        repeat: 0,
        scrollTrigger: {
          trigger: '.face-rec',
          start: 'top bottom',
        },
      });
      faceRecTl.fromTo(
        '.face-rec',
        {
          opacity: 0,
          y: 100,
          scale: 0.4,
        },
        {
          ease: 'back.out(1.7)',
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1,
        },
      );
      faceRecTl.fromTo(
        '.face-rec .dection-box',
        {
          scale: 1.3,
          opacity: 0,
        },
        {
          scale: 1,
          ease: 'back.out(1.7)',
          opacity: 1,
          duration: 0.3,
          stagger: 0.2,
          delay: 0.1,
        },
      );
      faceRecTl.fromTo(
        '.face-rec .group-tag',
        {
          scale: 0.1,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 0.3,
          stagger: 0.15,
          delay: 0.1,
          ease: 'back.out(1.7)',
        },
      );

      const objectRecTl = gsap.timeline({
        repeat: 0,
        scrollTrigger: {
          trigger: '.object-rec',
          start: 'top bottom',
        },
      });
      objectRecTl.fromTo(
        '.object-rec',
        {
          opacity: 0,
          y: 100,
          scale: 0.4,
        },
        {
          // ease: "power1.inOut",
          ease: 'back.out(1.7)',
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1,
        },
      );
      objectRecTl.fromTo(
        '.object-rec .group-tag',
        {
          scale: 0.1,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 0.3,
          stagger: 0.15,
          delay: 0.1,
          ease: 'back.out(1.7)',
        },
      );

      const headlineAnimation = gsap.timeline({
        repeat: 0,
        scrollTrigger: {
          trigger: '.features-title',
          start: 'top bottom',
        },
      });

      headlineAnimation.fromTo(
        '.features-title',
        {
          y: 150,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.75,
          delay: 0.1,
        },
      );

      headlineAnimation.fromTo(
        '.features-title .features-title-r',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.75,
          delay: 0.85,
        },
      );
    }, featuresWrapper); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, []); // <- empty dependency Array so it doesn't re-run on every render

  return (
    <div
      ref={featuresWrapper}
      className="-mt-20 pt-20 bg-no-repeat bg-center bg-cover lg:bg-auto"
      style={{
        backgroundImage: currentBg,
        backgroundPositionY: position,
      }}
    >
      <div className="main-title flex justify-center my-52 lg:mt-48 lg:mb-40">
        <h3 className="features-title text-center text-40 font-display bg-gradient-to-r from-pale-blue-950 to-pale-blue-500 text-transparent bg-clip-text lg:text-48">
          <span className="lg:hidden">
            A new suite of <br /> features,
            <br /> powered by AI<span className="features-title-r text-pale-blue-600">r</span>
          </span>
          <span className="hidden lg:inline-block">
            A new suite of features,
            <br /> powered by AI<span className="features-title-r text-pale-blue-600">r</span>
          </span>
        </h3>
      </div>

      <div className="my-32 lg:flex lg:items-center w-full max-w-screen-2xl mx-auto">
        <div className="flex-shrink-0 lg:w-1/2 lg:max-w-[460px]">
          <h4 className="text-32 leading-9 font-display font-light px-10 text-center mb-4 text-pale-blue-950 lg:text-left lg:text-header-lg">
            AI-powered tagging system
          </h4>
          <p className="text-14 leading-[22px] px-10 text-center text-pale-blue-950 lg:text-left lg:text-20 lg:leading-[34px]">
            Paige automatically tags every element, so you don&apos;t have to.
          </p>
        </div>

        <div className="veggie-on-shelf relative flex justify-center mx-auto lg:mr-0 mt-10 w-[365px] lg:flex-shrink-0 2xl:w-[600px]">
          <div className="rounded-2xl overflow-hidden">
            <span className="2xl:hidden">
              <Image src="/assets/paige/veggie-on-shelf.jpg" width="265" height="344" alt="Veggies on a shelf" />
            </span>
            <span className="hidden 2xl:block">
              <Image src="/assets/paige/veggie-on-shelf.jpg" width="416" height="534" alt="Veggies on a shelf" />
            </span>
          </div>
          <div className="group-tag absolute -top-5 left-7">
            <BubbleText>Fridge</BubbleText>
          </div>
          <div className="group-tag absolute top-10 left-4">
            <BubbleText>Veggies</BubbleText>
          </div>
          <div className="veggie-search-tag absolute top-[148px] 2xl:top-[237px]">
            <BubbleText>
              <span className="flex items-center">
                <svg
                  className="mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.7607 19.4825L17.4212 16.17C18.7175 14.5537 19.3453 12.5021 19.1754 10.4372C19.0056 8.37227 18.051 6.45088 16.508 5.06813C14.965 3.68538 12.9509 2.94636 10.8797 3.00303C8.8086 3.05971 6.83789 3.90777 5.37283 5.37283C3.90777 6.83789 3.05971 8.8086 3.00303 10.8797C2.94636 12.9509 3.68538 14.965 5.06813 16.508C6.45088 18.051 8.37227 19.0056 10.4372 19.1754C12.5021 19.3453 14.5537 18.7175 16.17 17.4212L19.4825 20.7337C19.5662 20.8181 19.6658 20.8851 19.7755 20.9308C19.8852 20.9765 20.0028 21 20.1216 21C20.2405 21 20.3581 20.9765 20.4678 20.9308C20.5775 20.8851 20.6771 20.8181 20.7607 20.7337C20.923 20.5659 21.0137 20.3416 21.0137 20.1081C21.0137 19.8747 20.923 19.6504 20.7607 19.4825ZM11.1202 17.4212C9.87392 17.4212 8.65568 17.0516 7.61948 16.3593C6.58328 15.6669 5.77566 14.6828 5.29875 13.5315C4.82184 12.3801 4.69706 11.1132 4.94018 9.89088C5.18331 8.6686 5.78343 7.54586 6.66464 6.66464C7.54586 5.78343 8.6686 5.18331 9.89088 4.94018C11.1132 4.69706 12.3801 4.82184 13.5315 5.29875C14.6828 5.77566 15.6669 6.58328 16.3593 7.61948C17.0516 8.65568 17.4212 9.87392 17.4212 11.1202C17.4212 12.7913 16.7573 14.394 15.5757 15.5757C14.394 16.7573 12.7913 17.4212 11.1202 17.4212Z"
                    fill="#06113F"
                  />
                </svg>
                <span className="tracking-tight">
                  <TypeAnimation
                    sequence={['Find photo of veggies in the fridge', 3000, '.', 100]}
                    wrapper="span"
                    speed={10}
                    omitDeletionAnimation={false}
                    repeat={Infinity}
                  />
                </span>
              </span>
            </BubbleText>
          </div>
          <div className="group-tag absolute bottom-10 right-2">
            <BubbleText>Vegetables</BubbleText>
          </div>
          <div className="group-tag absolute -bottom-5 right-7">
            <BubbleText>Greens</BubbleText>
          </div>
        </div>
      </div>

      <div className="my-32 lg:flex lg:items-center w-full max-w-screen-2xl mx-auto">
        <div className="flex-shrink-0 lg:w-1/2 lg:max-w-[460px]">
          <h4 className="text-32 leading-9 font-display font-light px-10 text-center mb-4 text-pale-blue-950 lg:text-left lg:text-header-lg">
            Facial Recognition
          </h4>
          <p className="text-14 leading-[22px] px-10 text-center text-pale-blue-950 lg:text-left lg:text-20 lg:leading-[34px]">
            You tag your clients, models, or teammates just once, and Paige instantly tags them everywhere else.
          </p>
        </div>

        <div className="face-rec relative flex justify-center mx-auto lg:mr-0 mt-10 w-[365px] lg:flex-shrink-0 2xl:w-[600px]">
          <div className="rounded-2xl overflow-hidden">
            <span className="2xl:hidden [&>span]:!block [&>span]:!max-w-[264px]">
              <Image src="/assets/paige/magic-spoon-models.png" width="702" height="852" alt="Face detection" />
            </span>
            <span className="hidden 2xl:block [&>span]:!block [&>span]:!max-w-[416px]">
              <Image src="/assets/paige/magic-spoon-models.png" width="702" height="852" alt="Face detection" />
            </span>
          </div>
          <div className="dection-box absolute w-[59px] h-[59px] top-[77px] left-[132px] 2xl:top-[130px] 2xl:left-[222px] 2xl:w-[92px] 2xl:h-[92px]">
            <svg viewBox="0 0 152 146" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 28V0h5v28H0Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0 0h28v5H0V0ZM124 0h28v5h-28V0Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M152 0v28h-5V0h5ZM152 118v28h-5v-28h5Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M152 146h-28v-5h28v5ZM28 146H0v-5h28v5Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0 146v-28h5v28H0Z" fill="#33DBDB" />
            </svg>
          </div>
          <div className="dection-box absolute w-[59px] h-[59px] top-[62px] right-[109px] 2xl:top-[98px] 2xl:right-[187px] 2xl:w-[92px] 2xl:h-[92px]">
            <svg viewBox="0 0 152 146" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 28V0h5v28H0Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0 0h28v5H0V0ZM124 0h28v5h-28V0Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M152 0v28h-5V0h5ZM152 118v28h-5v-28h5Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M152 146h-28v-5h28v5ZM28 146H0v-5h28v5Z" fill="#33DBDB" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0 146v-28h5v28H0Z" fill="#33DBDB" />
            </svg>
          </div>
          <div className="group-tag absolute bottom-[96px] lg:bottom-[122px] left-0">
            <BubbleText>
              <span className="flex items-center -ml-2 -my-[6px]">
                <Image
                  className="rounded-full mr-2"
                  src="/assets/paige/people/megan-king.png"
                  width={30}
                  height={30}
                  alt="Fake person avatar"
                ></Image>
                <span className="pl-2">Megan King</span>
              </span>
            </BubbleText>
          </div>
          <div className="group-tag absolute bottom-10 right-0">
            <BubbleText>
              <span className="flex items-center -ml-2 -my-[6px]">
                <Image
                  className="rounded-full mr-2"
                  src="/assets/paige/people/amara-rubio.png"
                  width={30}
                  height={30}
                  alt="Fake person avatar"
                ></Image>
                <span className="pl-2">Amara Rubio</span>
              </span>
            </BubbleText>
          </div>
        </div>
      </div>

      <div className="my-32 relative z-20 lg:flex lg:items-center w-full max-w-screen-2xl mx-auto">
        <div className="flex-shrink-0 lg:w-1/2 lg:max-w-[460px]">
          <h4 className="text-32 leading-9 font-display font-light px-10 text-center mb-4 text-pale-blue-950 lg:text-left lg:text-header-lg">
            Object Recognition
          </h4>
          <p className="text-14 leading-[22px] px-10 text-center text-pale-blue-950 lg:text-left lg:text-20 lg:leading-[34px]">
            Paige isn’t just good with faces. You can also train the AI to recognize objects like your products.
          </p>
        </div>

        <div className="object-rec relative flex justify-center mx-auto lg:mr-0 mt-10 w-[365px] lg:flex-shrink-0 2xl:w-[600px]">
          <div className="rounded-2xl overflow-hidden">
            <span className="2xl:hidden [&>span]:!block [&>span]:!max-w-[264px]">
              <Image
                src="/assets/paige/magic-spoon-cereal-peanut-butter.png"
                width="264"
                height="344"
                alt="Object Recognition"
              />
            </span>
            <span className="hidden 2xl:block [&>span]:!block [&>span]:!max-w-[416px]">
              <Image
                src="/assets/paige/magic-spoon-cereal-peanut-butter.png"
                width="888"
                height="1078"
                alt="Object Recognition"
              />
            </span>
          </div>
          <div className="group-tag absolute bottom-[26px] left-0 2xl:top-[150px] 2xl:left-[365px] 2xl:bottom-auto">
            <BubbleText>Grain-Free cereal</BubbleText>
          </div>

          <div className="group-tag absolute bottom-[85px] left-0 2xl:top-[210px] 2xl:left-[365px] 2xl:bottom-auto">
            <BubbleText>Magic Spoon</BubbleText>
          </div>

          <div className="group-tag absolute -bottom-8 left-0 2xl:top-[295px] 2xl:left-16 2xl:bottom-auto">
            <BubbleText>Peanut Butter</BubbleText>
          </div>
        </div>
      </div>

      <div className="w-full relative -mt-44 z-10 px-10 rounded-b-[30px] overflow-hidden bg-gradient-to-br from-[#A486B1] to-[#7594C2]">
        <div className="relative z-20 pt-32 pb-20 max-w-screen-3xl mx-auto lg:px-14 bg-gradient-to-b from-white from-70% to-[#8291be] to-95% text-transparent bg-clip-text bg-fixed">
          <p className="text-[34px] leading-[42px] font-display font-light mb-4 mt-6 md:text-[58px] md:leading-[64px] md:mb-8 xl:text-[90px] xl:leading-[95px] lg:mb-14 lg:pt-6">
            Tweak this. Crop that. Where’s the final_final version again??
          </p>
          <p className="text-[24px] font-display font-body font-light leading-[34px] md:text-[38px] md:leading-[48px] xl:text-[58px] xl:leading-[70px] lg:pb-10">
            Participants in our AI design program get Air’s powerful search, intuitive visual workspace, and white-glove
            customer support - alongside a host of AI features built around their specific needs.
          </p>
        </div>
      </div>
    </div>
  );
};
