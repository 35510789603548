import { useEffect, useState } from 'react';

export const CountDown = () => {
  const deadline: Date = new Date('Nov 15, 2023');
  // const now = new Date()
  const minute = 60 * 1000;

  const hour = 60 * minute;

  const day = 24 * hour;

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [now]);

  const getDays = (val: Date) => {
    return Math.floor((deadline.getTime() - val.getTime()) / day);
  };

  const getRemainingHours = (val: Date) => {
    return Math.floor(((deadline.getTime() - val.getTime()) % day) / hour);
  };

  const getRemainingMinutes = (val: Date) => {
    return Math.floor(((deadline.getTime() - val.getTime()) % hour) / minute);
  };

  const getRemainingSeconds = (val: Date) => {
    return Math.floor(((deadline.getTime() - val.getTime()) % minute) / 1000);
  };

  return (
    <div className="text-pale-blue-950 text-center py-24 px-8 max-w-screen-xl mx-auto">
      <p className="font-body text-[20px] leading-8 mb-8">Time left to apply:</p>
      <div className="grid grid-cols-2 gap-4 xl:grid-cols-6">
        <div className="hidden xl:flex justify-center items-center">
          <hr className="h-px bg-pale-blue-950 w-10" />
        </div>
        <div className="">
          <p className="font-display font-light text-[90px] leading-tight mb-3">{getDays(now)}</p>
          <p className="font-body text-[20px] leading-8">Days</p>
        </div>
        <div className="">
          <p className="font-display font-light text-[90px] leading-tight mb-3">{getRemainingHours(now)}</p>
          <p className="font-body text-[20px] leading-8">Hours</p>
        </div>
        <div className="">
          <p className="font-display font-light text-[90px] leading-tight mb-3">{getRemainingMinutes(now)}</p>
          <p className="font-body text-[20px] leading-8">Minutes</p>
        </div>
        <div className="">
          <p className="font-display font-light text-[90px] leading-tight mb-3">{getRemainingSeconds(now)}</p>
          <p className="font-body text-[20px] leading-8">Seconds</p>
        </div>
        <div className="hidden xl:flex justify-center items-center">
          <hr className="h-px bg-pale-blue-950 w-10" />
        </div>
      </div>
    </div>
  );
};
