import { VisuallyHidden } from '@air/marketing-primitives';

import * as StyledHeaderMobileHamburger from './HeaderMobileHamburger.styles';

export type HeaderMobileHamburgerProps = Pick<StyledHeaderMobileHamburger.RootProps, 'css' | 'onClick'> & {
  isOpened: boolean;
};

export const HeaderMobileHamburger = ({ css, isOpened, onClick }: HeaderMobileHamburgerProps) => {
  return (
    <StyledHeaderMobileHamburger.Root css={css} onClick={onClick}>
      <VisuallyHidden>Menu</VisuallyHidden>
      <StyledHeaderMobileHamburger.Bar animate={{ rotate: isOpened ? 45 : 0, y: isOpened ? 8 : 0 }} />
      <StyledHeaderMobileHamburger.Bar animate={{ width: isOpened ? 0 : 'auto', opacity: isOpened ? 0 : 1 }} />
      <StyledHeaderMobileHamburger.Bar animate={{ rotate: isOpened ? -45 : 0, y: isOpened ? -8 : 0 }} />
    </StyledHeaderMobileHamburger.Root>
  );
};
