import { CSS, styled, Text } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export const HEADER_MENU_ITEM_ELEMENT_TAG = 'a';

export type StyledHeaderMenuItemProps = ComponentProps<typeof HEADER_MENU_ITEM_ELEMENT_TAG> & {
  css?: CSS;
};

export const StyledHeaderMenuItem = styled(HEADER_MENU_ITEM_ELEMENT_TAG, {
  display: 'flex',
  paddingY: 4,
  textDecoration: 'none',

  '&:hover': {
    /**
     * Targets and applies the underline to only the title
     */
    [`& ${Text}`]: {
      '&:first-of-type': {
        textDecoration: 'underline',
      },
    },
  },
});
