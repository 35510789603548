import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  variant?: 'success' | 'danger' | 'neutral';
};
export const Button = ({ children, type = 'button', variant = 'neutral', disabled, style, ...rest }: ButtonProps) => {
  const primaryStyle = {
    background: '#0070f3',
    color: 'white',
    border: '1px solid #0070f3',
  };

  const dangerStyle = {
    background: 'red',
    color: 'white',
    border: '1px solid red',
  };

  const neutrealStyle = {
    color: 'black',
    background: '#f5eded',
    border: '1px solid #c0c0c0',
  };

  const variantStyle = {
    success: primaryStyle,
    danger: dangerStyle,
    neutral: neutrealStyle,
  };

  const commonStyle = {
    padding: '5px 7px',
    borderRadius: 5,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.7 : 1,
    outline: 'none',
  };

  return (
    <button type={type} style={{ ...commonStyle, ...variantStyle[variant], ...style }} {...rest}>
      {children}
    </button>
  );
};
