import { CSS, Text, TextProps } from '@air/marketing-primitives';

import { SECTION_CMS_LINK_CLASS_NAME } from '~/old-marketing/components/sections/Section';
import { SectionFieldTooltip } from '~/old-marketing/components/sections/SectionFieldTooltip';
import { usePreviewMode } from '~/providers/PreviewModeProvider';
import { clamp } from '~/utils/clamp';

export type SectionTitleSizes =
  | 'heading-72'
  | 'heading-64'
  | 'heading-56'
  | 'heading-48'
  | 'heading-40'
  | 'heading-32'
  | 'heading-28'
  | 'heading-24';

export type SectionTitleProps = Partial<Pick<TextProps, 'children' | 'css' | 'dangerouslySetInnerHTML'>> & {
  /**
   * When passing the `as` prop to the element, `isSubsection`
   * conditional rendering will be overwritten.
   */
  as?: string;

  /**
   * If the section title is linked to a CMS, the className
   * `is-cms-linked` gets added to the element.
   */
  isCMSLinked?: boolean;

  /**
   * Switches the section title to use either `h3` instead
   * of `h2` when the section title is used in a subsection.
   */
  isSubsection?: boolean;

  /**
   * Predefined responsive values for the section title. Each
   * size comes with a minimum and maximum value.
   *
   * Reference:
   * - `heading-56` = `32px` / `56px`
   * - `heading-48` = `32px` / `48px`
   * - `heading-40` = `32px` / `40px`
   */
  size?: SectionTitleSizes;
};

const TEXT_SIZE_CSS: {
  [key in SectionTitleSizes]: CSS;
} = {
  'heading-72': {
    fontSize: clamp(40, 72),
  },
  'heading-64': {
    fontSize: clamp(40, 64),
  },
  'heading-56': {
    fontSize: clamp(40, 56),
  },
  'heading-48': {
    fontSize: clamp(32, 48),
  },
  'heading-40': {
    fontSize: clamp(32, 40),
  },
  'heading-32': {
    fontSize: clamp(28, 32),
  },
  'heading-28': {
    fontSize: clamp(24, 28),
  },
  'heading-24': {
    fontSize: clamp(20, 24),
  },
};

const TEXT_SIZE_VARIANTS: {
  [key in SectionTitleSizes]: {
    desktop: SectionTitleSizes;
    mobile: 'heading-40' | 'heading-32' | 'heading-28' | 'heading-24' | 'heading-20';
  };
} = {
  'heading-72': {
    desktop: 'heading-72',
    mobile: 'heading-40',
  },
  'heading-64': {
    desktop: 'heading-64',
    mobile: 'heading-40',
  },
  'heading-56': {
    desktop: 'heading-56',
    mobile: 'heading-40',
  },
  'heading-48': {
    desktop: 'heading-48',
    mobile: 'heading-32',
  },
  'heading-40': {
    desktop: 'heading-40',
    mobile: 'heading-32',
  },
  'heading-32': {
    desktop: 'heading-32',
    mobile: 'heading-28',
  },
  'heading-28': {
    desktop: 'heading-28',
    mobile: 'heading-24',
  },
  'heading-24': {
    desktop: 'heading-24',
    mobile: 'heading-20',
  },
};

export const SectionTitle = ({
  as,
  children,
  css,
  dangerouslySetInnerHTML,
  isCMSLinked = true,
  isSubsection = false,
  size = 'heading-48',
}: SectionTitleProps) => {
  const { isPreview } = usePreviewMode();

  const element = (
    <Text
      as={as ?? isSubsection ? 'h3' : 'h2'}
      className={isPreview && isCMSLinked ? SECTION_CMS_LINK_CLASS_NAME : ''}
      css={{ fontWeight: '$semibold', ...TEXT_SIZE_CSS[size], ...css }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      size={{
        '@initial': TEXT_SIZE_VARIANTS[size].mobile,
        '@desktop': TEXT_SIZE_VARIANTS[size].desktop,
      }}
    >
      {children}
    </Text>
  );

  return isCMSLinked ? <SectionFieldTooltip field="Title">{element}</SectionFieldTooltip> : element;
};
