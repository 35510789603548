import { ReactChild, ReactFragment, ReactPortal } from 'react';

export const BubbleText = (props: {
  children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  return (
    <div className="rounded-full bg-pale-blue-200 text-pale-blue-950 bg-opacity-60 py-4 px-7 font-body text-base leading-5 shadow-tag backdrop-blur">
      {props.children}
    </div>
  );
};
