import { Field, FieldAttributes } from 'formik';

export const Input = ({ style, disabled, ...rest }: FieldAttributes<any>) => {
  const inputStyle = {
    border: '1px solid #c0c0c0',
    opacity: disabled ? 0.5 : 1,
    ...style,
  };
  return <Field disabled={disabled} {...rest} style={inputStyle} />;
};
