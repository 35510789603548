import { Text } from '@air/marketing-primitives';
import { Formik } from 'formik';
import { object, string } from 'yup';

const validationSchema = object()
  .shape({
    email: string().email('Please enter a valid email address').required('Please enter a valid email address'),
  })
  .required()
  .default({ email: '' });

export type BannerSignUpProps = {
  headline?: string;
  submitLabel?: string;
  linkHeader?: string;
  linkLabel?: string;
  linkRoute?: string;
};

export const BannerSignUp = ({ headline, submitLabel, linkHeader, linkLabel, linkRoute }: BannerSignUpProps) => {
  return (
    <div className="bg-jay-900 px-8 py-[4.5rem] items-center text-center">
      <div className="flex flex-col flex-nowrap max-w-[659px] w-full items-center mx-auto">
        <h2 className="font-display text-header-md font-semibold text-white mb-12">{headline}</h2>
        <Formik
          initialValues={validationSchema.cast(undefined)!}
          onSubmit={(values) => {
            if (values.email) {
              window.location.href = `https://app.air.inc/sign-up?email=${encodeURIComponent(values.email)}`;
            } else {
              window.location.href = 'https://app.air.inc/sign-up';
            }
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit} className="w-full mb-12 md:flex flex-row flex-nowrap relative">
              <input
                name="email"
                placeholder="Work email address"
                required
                onChange={handleChange}
                value={values.email}
                className="pl-input-text md:rounded-tr-none md:rounded-br-none mb-8 md:mb-0"
              />
              <button
                className="pl-button md:rounded-tl-none md:rounded-bl-none whitespace-nowrap bg-jay-500 w-full md:w-fit mx-auto md:mr-0"
                type="submit"
              >
                {submitLabel}
              </button>
            </form>
          )}
        </Formik>

        <div className="flex flex-col items-center md:flex-row md:flex-nowrap">
          <Text as="p" className="text-body-sm lg:text-body-md text-white md:mr-2">
            {linkHeader}
          </Text>
          <Text
            as="a"
            href={linkRoute}
            className="text-body-sm lg:text-body-md text-peacock-400 relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-peacock-400 after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
          >
            {linkLabel}
          </Text>
        </div>
      </div>
    </div>
  );
};
