import { registerPlasmicHeader } from '@air/marketing-tech-debt';
import {
  registerAccordion,
  registerAccordionContent,
  registerAccordionDebugger,
  registerAccordionHeader,
  registerAccordionItem,
  registerAccordionTrigger,
  registerAllComponents,
  registerAnimated,
  registerAnimateOnClick,
  registerCircleVideoPreview,
  registerClickEvent,
  registerDialogClose,
  registerDialogOverlay,
  registerMarquee,
  registerTooltip,
  registerTooltipProvider,
  registerTrail,
  registerTransition,
  registerVideo,
} from '@air/plasmic';
import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';

import { DrukCyr } from '~/components/fonts/DrukCyr';
import { PPMori } from '~/components/fonts/PPMori';
import { WebbyModal } from '~/components/WebbyModal';
import { Select } from '~/lib/plasmic/substitutions/Select';
import { BannerSignUp as PlBannerSignUp } from '~/marketing/components/bannerSignUp/BannerSignUp';
import { ClientTestimonials as PlClientTestimonials } from '~/marketing/components/clientTestimonials/ClientTestimonials';
import { FeatureScroller as PlFeatureScroller } from '~/marketing/components/featureScroller/FeatureScroller';
import { FeatureWall as PlFeatureWall } from '~/marketing/components/featureWall/FeatureWall';
import { FiftyFiftySignUpVideo as PlFiftyFiftySignUpVideo } from '~/marketing/components/fiftyFiftySignUpVideo/FiftyFiftySignUpVideo';
import { FiftyFiftyTextGraphic as PlFiftyFiftyTextGraphic } from '~/marketing/components/fiftyFiftyTextGraphic/FiftyFiftyTextGraphic';
import { FiftyFiftyTextVideo as PlFiftyFiftyTextVideo } from '~/marketing/components/fiftyFiftyTextVideo/FiftyFiftyTextVideo';
import { Hero as PlHero } from '~/marketing/components/hero/Hero';
import { Apply as PaigeApply } from '~/marketing/components/paige/Apply';
import { Features as PaigeFeatures } from '~/marketing/components/paige/Features';
import { Hero as PaigeHero } from '~/marketing/components/paige/Hero';
import { LaunchPlan as PaigeLaunchPlan } from '~/marketing/components/paige/LaunchPlan';
import { VideoText as PaigeVideoText } from '~/marketing/components/paige/VideoText';
import { PaigeNavBar as PaigeNavBar } from '~/marketing/components/paigeNavBar/PaigeNavBar';
import { VideoTabs as PlVideoTabs } from '~/marketing/components/videoTabs/VideoTabs';
import { DefaultHeader } from '~/old-marketing/components/globals/headers/DefaultHeader/DefaultHeader';
import { LandingHeader } from '~/old-marketing/components/globals/headers/LandingHeader/LandingHeader';
import { HomeScaryQuotesSectionItem } from '~/old-marketing/components/sections/templates/HomeScaryQuotesSectionTemplate/HomeScaryQuotesSectionItem';
import { HomeScaryQuotesSectionTemplate } from '~/old-marketing/components/sections/templates/HomeScaryQuotesSectionTemplate/HomeScaryQuotesSectionTemplate';

const Plasmic = initPlasmicLoader({
  projects: [
    {
      id: process.env.PLASMIC_PROJECT_ID!,
      token: process.env.PLASMIC_API_TOKEN!,
    },
  ],
  preview: process.env.NODE_ENV !== 'production',
});

/**
 * Component registrations
 */
registerAllComponents({
  componentMap: {
    PaigeNavBar: {
      component: PaigeNavBar,
      meta: {
        importPath: 'www',
        name: 'PaigeNavBar',
        props: {},
      },
    },

    PaigeHero: {
      component: PaigeHero,
      meta: {
        importPath: 'www',
        name: 'PaigeHero',
        props: {},
      },
    },

    PaigeVideoText: {
      component: PaigeVideoText,
      meta: {
        importPath: 'www',
        name: 'PaigeVideoText',
        props: {
          videoUrlMobile: {
            type: 'string',
            defaultValue: 'https://air-72.wistia.com/medias/fbabwaogr8',
          },
          videoUrlDesktop: {
            type: 'string',
            defaultValue: 'https://air-72.wistia.com/medias/l4tgzrdl8m',
          },
          videoType: {
            type: 'string',
            defaultValue: 'wistia',
          },
        },
      },
    },

    PaigeFeatures: {
      component: PaigeFeatures,
      meta: {
        importPath: 'www',
        name: 'PaigeFeatures',
        props: {},
      },
    },

    PaigeLaunchPlan: {
      component: PaigeLaunchPlan,
      meta: {
        importPath: 'www',
        name: 'PaigeLaunchPlan',
        props: {},
      },
    },

    PaigeApply: {
      component: PaigeApply,
      meta: {
        importPath: 'www',
        name: 'PaigeApply',
        props: {
          applicationLink: {
            type: 'string',
            defaultValue: 'applicationLink',
          },
        },
      },
    },

    PlHero: {
      component: PlHero,
      meta: {
        importPath: 'www',
        name: 'PlHero',
        props: {
          headline: {
            type: 'string',
            defaultValue: 'Manage your creative assets and creative process in one place.',
          },
          ctaLabel: {
            type: 'string',
            defaultValue: 'Get started',
          },
          ctaLink: {
            type: 'href',
            defaultValue: '/sign-up',
          },
          ctaType: {
            type: 'string',
            defaultValue: 'internal',
          },
          videoUrl: {
            type: 'href',
            defaultValue: 'https://air-72.wistia.com/medias/atk1653j5j',
          },
          videoUrl2: {
            type: 'href',
            defaultValue: 'https://air-72.wistia.com/medias/5jhpuunhqm',
          },
        },
      },
    },
    PlBannerSignUp: {
      component: PlBannerSignUp,
      meta: {
        importPath: 'www',
        name: 'PlBannerSignUp',
        props: {
          headline: {
            type: 'string',
            defaultValue: 'Need to talk to a human first?',
          },
          linkHeader: {
            type: 'string',
            defaultValue: 'Ready to get going?',
          },
          linkRoute: {
            type: 'href',
            defaultValue: '#',
          },
          linkLabel: {
            type: 'string',
            defaultValue: 'Start for free →',
          },
          submitLabel: {
            type: 'string',
            defaultValue: 'Book a demo',
          },
        },
      },
    },
    PlFeatureWall: {
      component: PlFeatureWall,
      meta: {
        importPath: 'www',
        name: 'PlFeatureWall',
        props: {
          headline: {
            type: 'string',
            defaultValue: 'You create, Air automates',
          },
        },
      },
    },
    PlFiftyFiftySignUpVideo: {
      component: PlFiftyFiftySignUpVideo,
      meta: {
        importPath: 'www',
        name: 'PlFiftyFiftySignUpVideo',
        props: {
          headline: {
            type: 'string',
            defaultValue: 'Ready to save your team time, money, and stress?',
          },
          linkHeader: {
            type: 'string',
            defaultValue: 'Want to say hi first?',
          },
          linkRoute: {
            type: 'href',
            defaultValue: '#',
          },
          linkLabel: {
            type: 'string',
            defaultValue: 'Book a demo →',
          },
          submitLabel: {
            type: 'string',
            defaultValue: 'Start for free',
          },
          videoUrl: {
            type: 'href',
            defaultValue: 'https://air-72.wistia.com/medias/l313gf8wr6',
          },
          videoType: {
            type: 'string',
            defaultValue: 'wistia',
          },
        },
      },
    },
    PlFiftyFiftyTextVideo: {
      component: PlFiftyFiftyTextVideo,
      meta: {
        importPath: 'www',
        name: 'PlFiftyFiftyTextVideo',
        props: {
          headline: {
            type: 'string',
            defaultValue: 'Air is the only way to automate Creative Ops',
          },
          content: {
            type: 'string',
            defaultValue: `<p>It's a visual workspace that optimizes how teams collect, approve, and share content.</p><p>We call this kind of work creative operations, or “Creative Ops.” You're probably a little too familiar with it: all the thankless, manual parts of the creative process like finding the most recent file or trying to give someone feedback.  Air keeps all of it organized, returning hours to your day.</p>`,
          },
          ctaLabel: {
            type: 'string',
            defaultValue: 'Get started',
          },
          ctaLink: {
            type: 'href',
            defaultValue: '#',
          },
          ctaType: {
            type: 'string',
            defaultValue: 'internal',
          },
          videoUrl: {
            type: 'href',
            defaultValue: 'https://air-72.wistia.com/medias/jy6emty2xj',
          },
          videoType: {
            type: 'string',
            defaultValue: 'wistia',
          },
        },
      },
    },
    PlClientTestimonials: {
      component: PlClientTestimonials,
      meta: {
        importPath: 'www',
        name: 'PlClientTestimonials',
        props: {
          headline: {
            type: 'string',
            defaultValue: "But don't just take it from us.",
          },
        },
      },
    },
    PlVideoTabs: {
      component: PlVideoTabs,
      meta: {
        importPath: 'www',
        name: 'PlVideoTabs',
        props: {},
      },
    },
    PlFiftyFiftyTextGraphic: {
      component: PlFiftyFiftyTextGraphic,
      meta: {
        importPath: 'www',
        name: 'PlFiftyFiftyTextGraphic',
        props: {
          ctaText: {
            type: 'string',
            defaultValue: 'Compare',
          },
          ctaLink: {
            type: 'href',
            defaultValue: '/comparison/dropbox-alternative',
          },
          headline: {
            type: 'string',
            defaultValue: 'Still stuck navigating GDrive and Dropbox?',
          },
        },
      },
    },
    PlFeatureScroller: {
      component: PlFeatureScroller,
      meta: {
        importPath: 'www',
        name: 'PlFeatureScroller',
        props: {},
      },
    },
    DefaultHeader: {
      component: DefaultHeader,
      meta: {
        importPath: 'www',
        name: 'DefaultHeader',
        props: {
          appearance: {
            type: 'choice',
            options: ['dark', 'light'],
          },
          ctaText: 'string',
          leadsource: 'string',
          hasAuthNavigation: 'boolean',
          hasMobileNavigation: 'boolean',
          hasDemoOnlyCTANavigation: 'boolean',
          hasDemoOnlyCTANavigationOnMobile: 'boolean',
          hasLogo: 'boolean',
          hasNavigation: 'boolean',
          isFixed: 'boolean',
          isHiddenOnSticky: 'boolean',
          router: 'string',
        },
      },
    },
    LandingHeader: {
      component: LandingHeader,
      meta: {
        importPath: 'www',
        name: 'LandingHeader',
        props: {
          appearance: {
            type: 'choice',
            options: ['dark', 'light'],
          },
          showCTA: 'boolean',
          ctaText: 'string',
          isFixed: 'boolean',
        },
      },
    },
    HomeScaryQuotesSectionItem: {
      component: HomeScaryQuotesSectionItem,
      meta: {
        name: 'HomeScaryQuotesSectionItem',
        importPath: 'www',
        props: {
          blurbId: {
            displayName: 'Blurb id from references',
            type: 'string',
          },
          children: {
            type: 'slot',
          },
        },
      },
    },
    HomeScaryQuotesSection: {
      component: HomeScaryQuotesSectionTemplate,
      meta: {
        name: 'HomeScaryQuotesSection',
        importPath: 'www',
        props: {
          version: {
            type: 'number',
            defaultValue: 1,
          },
          blurbIdToShow: {
            displayName: 'Which blurb id to render?',
            type: 'string',
          },
          children: {
            type: 'slot',
            allowedComponents: ['HomeScaryQuotesSectionItem'],
          },
          references: {
            type: 'array',
            defaultValue: [
              {
                contentTitle: '“I waste days on manual, mindless tasks”',
                label: 'We get it. Your job is full of chores.',
                title: '“I waste days on manual, mindless tasks”',
                assets: [
                  {
                    title: 'doorway animated',
                    description: '',
                    file: {
                      url: '//assets.ctfassets.net/l2ai7bec55u7/UIDAyBeBla8B8JsPxYxAf/542bd52a8584a0b92cccb28ab388cfc4/lf30_oq3m0fma.json',
                      details: {
                        size: 40828,
                      },
                      fileName: 'lf30_oq3m0fma.json',
                      contentType: 'application/json',
                    },
                    updatedAt: '2021-10-26T20:35:42.497Z',
                    createdAt: '2021-10-26T20:35:42.497Z',
                  },
                ],
                backgroundImages: [
                  {
                    title: 'scary1',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/l2ai7bec55u7/5deeyKqIt3D3OptW4B6NDJ/dbf3385295f303d6cc1398362d92477e/scary1.svg',
                      details: {
                        size: 1812,
                        image: {
                          width: 1440,
                          height: 608,
                        },
                      },
                      fileName: 'scary1.svg',
                      contentType: 'image/svg+xml',
                    },
                    updatedAt: '2021-11-02T16:54:49.052Z',
                    createdAt: '2021-10-13T03:05:54.081Z',
                  },
                ],
                id: 'MpaVZ4rCVOjGsd6Z3dEnN',
                __typename: 'blurb',
                updatedAt: '2021-10-26T20:46:33.812Z',
                createdAt: '2021-10-14T18:09:12.919Z',
              },
            ],
          },
        },
      },
    },
    WebbyModal: {
      component: WebbyModal,
      meta: {
        name: 'WebbyModal',
        importPath: 'www',
        props: {
          button: 'slot',
          id: 'string',
          leadsource: 'string',
        },
      },
    },
    DrukCyrFont: {
      component: DrukCyr,
      meta: {
        name: 'DrukCyrFont',
        importPath: 'www',
        props: {},
      },
    },
    PPMoriFont: {
      component: PPMori,
      meta: {
        name: 'PPMoriFont',
        importPath: 'www',
        props: {},
      },
    },
  },
  loader: Plasmic,
});

registerAccordion({ loader: Plasmic });
registerAccordionContent({ loader: Plasmic });
registerAccordionHeader({ loader: Plasmic });
registerAccordionDebugger({ loader: Plasmic });
registerAccordionItem({ loader: Plasmic });
registerAccordionTrigger({ loader: Plasmic });
registerAnimated({ loader: Plasmic });
registerAnimateOnClick({ loader: Plasmic });
registerClickEvent({ loader: Plasmic });
registerDialogClose({ loader: Plasmic });
registerDialogOverlay({ loader: Plasmic });
registerMarquee({ loader: Plasmic });
registerCircleVideoPreview({ loader: Plasmic });
registerTransition({ loader: Plasmic });
registerTrail({ loader: Plasmic });
registerTooltip({ loader: Plasmic });
registerTooltipProvider({ loader: Plasmic });
registerVideo({ loader: Plasmic });
registerPlasmicHeader({ loader: Plasmic });

Plasmic.substituteComponent(Select, 'Form / Select');

export { Plasmic };
