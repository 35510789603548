import { styled } from '@air/marketing-primitives';

export const Root = styled('header', {
  zIndex: 4,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',
  backgroundColor: 'white',
  width: '100%',
  height: 80,
  transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
  perspective: 800,
});

export const Navigation = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});
