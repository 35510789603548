export const CompetitionGraphic = () => {
  return (
    <div className="w-full h-full">
      <svg
        className="lg:hidden object-contain h-full w-full"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 350 408"
      >
        <g filter="url(#filter0_d_1102_226869)" className="pl-box-shadow--lighter">
          <rect x="186.398" y="326.732" width="63.283" height="62.52" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="m207.781 334.463-14.741 9.65 10.259 8.34 14.741-9.293-10.259-8.697Zm-14.741 26.091 14.741 9.769 10.259-8.697-14.741-9.173-10.259 8.101Zm25 1.072 10.377 8.697 14.623-9.65-10.142-8.22-14.858 9.173Zm25-17.513-14.623-9.65-10.377 8.697 14.858 9.293 10.142-8.34Zm-24.882 19.419-10.377 8.697-4.364-2.978v3.336l14.741 8.935 14.74-8.935v-3.336l-4.481 2.978-10.259-8.697Z"
            fill="#007EE5"
          />
        </g>
        <g filter="url(#filter1_d_1102_226869)" className="pl-box-shadow--lighter">
          <rect x="124.961" y="242" width="63.283" height="62.52" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="M160.273 257.471a13.75 13.75 0 0 0-6.846 1.838 13.452 13.452 0 0 0-4.964 4.985 7.555 7.555 0 0 0-3.247-.738 7.523 7.523 0 0 0-4.81 1.75 7.285 7.285 0 0 0-2.515 4.393 10.211 10.211 0 0 0-4.573 3.711 9.959 9.959 0 0 0-1.716 5.578 9.986 9.986 0 0 0 3.007 7.117 10.336 10.336 0 0 0 7.244 2.945c.43-.004.859-.035 1.285-.091h28.483c.15.01.299.017.449.021.143-.004.286-.011.429-.021h.682v-.05a9.575 9.575 0 0 0 6.009-3.093 9.347 9.347 0 0 0 2.412-6.255v-.018a9.346 9.346 0 0 0-2.2-5.995 9.558 9.558 0 0 0-5.563-3.236 13.233 13.233 0 0 0-4.146-9.112 13.688 13.688 0 0 0-9.42-3.729Z"
            fill="url(#paint0_linear_1102_226869)"
          />
        </g>
        <g filter="url(#filter2_d_1102_226869)" className="pl-box-shadow--lighter">
          <rect x="99.926" y="294.916" width="63.283" height="63.283" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="m110.347 342.389 2.205 3.78a5.19 5.19 0 0 0 1.89 1.876l7.876-13.529h-15.751c0 .881.229 1.762.688 2.558l3.092 5.315Z"
            fill="#0066DA"
          />
          <path
            d="m131.567 318.599-7.875-13.529a5.182 5.182 0 0 0-1.89 1.876l-14.547 25.012a5.12 5.12 0 0 0-.688 2.558h15.751l9.249-15.917Z"
            fill="#00AC47"
          />
          <path
            d="M148.692 348.045a5.182 5.182 0 0 0 1.89-1.876l.917-1.563 4.381-7.532a5.122 5.122 0 0 0 .687-2.558h-15.751l3.352 6.537 4.524 6.992Z"
            fill="#EA4335"
          />
          <path
            d="m131.567 318.599 7.875-13.529a5.058 5.058 0 0 0-2.577-.682h-10.596c-.916 0-1.804.255-2.577.682l7.875 13.529Z"
            fill="#00832D"
          />
          <path
            d="M140.817 334.516h-18.499l-7.876 13.529a5.064 5.064 0 0 0 2.578.682h29.095c.916 0 1.804-.256 2.577-.682l-7.875-13.529Z"
            fill="#2684FC"
          />
          <path
            d="m148.606 319.452-7.273-12.506a5.185 5.185 0 0 0-1.891-1.876l-7.875 13.529 9.25 15.917h15.722c0-.881-.229-1.762-.688-2.558l-7.245-12.506Z"
            fill="#FFBA00"
          />
        </g>
        <g filter="url(#filter3_d_1102_226869)" className="pl-box-shadow--lighter">
          <g clipPath="url(#clip0_1102_226869)">
            <path d="M212.256 8.736h-63.283v62.52h63.283V8.737Z" fill="#000177" />
            <path
              d="M185.624 22.022c5.537-3.647 7.91-2.865 7.91 2.866 0 4.95-1.318 9.899-1.318 9.899h5.274s1.054-5.47 1.054-9.9c.264-8.596-3.955-10.42-7.383-10.42-6.855 0-16.612 7.815-29.532 23.706-4.746 5.992-9.756 13.025-12.92 17.454v9.117s14.502-20.319 22.413-29.436c5.801-6.513 10.81-10.942 14.502-13.286Z"
              fill="#33DBDB"
            />
            <path
              d="M169.276 44.165c-5.274 4.428-5.801 9.378-5.801 10.68 0 5.731 4.483 10.42 10.283 10.68h.528c15.293 0 20.567-20.058 20.83-21.1 0 0-5.273-.26-5.537.26-.263.522-.527 1.303-.791 2.085-2.637 6.773-7.383 13.546-14.502 13.546-2.901 0-5.537-2.084-5.537-5.21v-.26c0-1.824 1.054-4.43 3.955-7.034 4.746-3.908 15.029-7.815 37.969-4.429 0 0 1.055.26 1.583.26v-5.21c-14.239-2.084-32.96-2.605-42.98 5.732Z"
              fill="#33DBDB"
            />
          </g>
        </g>
        <path
          d="M101.444 271.693a1 1 0 1 0 0-2v2Zm24.263-230.292a1 1 0 0 0 0-1.415l-6.364-6.364a1 1 0 1 0-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364Zm-24.263 228.292c-33.965 0-59.449-14.231-76.115-35.553C8.644 212.793.762 184.289 2.158 155.742 3.552 127.2 14.218 98.687 34.533 77.32 54.833 55.969 84.822 41.693 125 41.693v-2c-40.689 0-71.215 14.475-91.917 36.249C12.397 97.7 1.575 126.687.16 155.645c-1.415 28.953 6.57 57.948 23.593 79.727 17.042 21.802 43.105 36.321 77.691 36.321v-2ZM235.38 41.693a1 1 0 0 0 0 2v-2Zm-22.087 253.293a.999.999 0 0 0 0 1.414l6.364 6.364a.999.999 0 1 0 1.414-1.414l-5.657-5.657 5.657-5.657a.999.999 0 1 0-1.414-1.414l-6.364 6.364ZM235.38 43.693c40.605 0 69.836 15.69 88.323 39.15 18.508 23.488 26.313 54.851 23.857 86.272-4.913 62.848-50.813 125.578-133.56 125.578v2c84.012 0 130.578-63.769 135.554-127.422 2.488-31.828-5.407-63.715-24.28-87.665-18.893-23.977-48.722-39.913-89.894-39.913v2Z"
          fill="#020039"
        />
        <defs>
          <filter
            id="filter0_d_1102_226869"
            x="173.136"
            y="318.112"
            width="89.808"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226869" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226869" result="shape" />
          </filter>
          <filter
            id="filter1_d_1102_226869"
            x="111.699"
            y="233.379"
            width="89.808"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226869" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226869" result="shape" />
          </filter>
          <filter
            id="filter2_d_1102_226869"
            x="86.663"
            y="286.296"
            width="89.808"
            height="89.807"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226869" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226869" result="shape" />
          </filter>
          <filter
            id="filter3_d_1102_226869"
            x="135.71"
            y=".116"
            width="89.808"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226869" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226869" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_1102_226869"
            x1="181.754"
            y1="279.587"
            x2="131.584"
            y2="278.883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3E82F4" />
            <stop offset="1" stopColor="#93DCF7" />
          </linearGradient>
          <clipPath id="clip0_1102_226869">
            <rect x="148.973" y="8.736" width="63.283" height="62.52" rx="4" fill="#fff" />
          </clipPath>
        </defs>
      </svg>

      <svg
        className="hidden lg:block object-contain h-full w-full"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 572 441"
      >
        <g filter="url(#filter0_d_1102_226868)" className="pl-box-shadow--lighter">
          <rect x="214.473" y="359.732" width="63.283" height="62.52" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="m235.855 367.463-14.741 9.65 10.26 8.34 14.74-9.293-10.259-8.697Zm-14.741 26.091 14.741 9.769 10.259-8.697-14.74-9.173-10.26 8.101Zm25 1.072 10.378 8.697 14.622-9.65-10.141-8.22-14.859 9.173Zm25-17.513-14.622-9.65-10.378 8.697 14.859 9.293 10.141-8.34Zm-24.882 19.419-10.377 8.697-4.363-2.978v3.336l14.74 8.935 14.741-8.935v-3.336l-4.481 2.978-10.26-8.697Z"
            fill="#007EE5"
          />
        </g>
        <g filter="url(#filter1_d_1102_226868)" className="pl-box-shadow--lighter">
          <rect x="153.035" y="275" width="63.283" height="62.52" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="M188.348 290.471a13.734 13.734 0 0 0-6.846 1.838 13.437 13.437 0 0 0-4.964 4.985 7.545 7.545 0 0 0-3.248-.738 7.513 7.513 0 0 0-4.809 1.75 7.286 7.286 0 0 0-2.516 4.393 10.208 10.208 0 0 0-4.572 3.711 9.943 9.943 0 0 0-1.716 5.578 9.985 9.985 0 0 0 3.006 7.117 10.337 10.337 0 0 0 7.245 2.945c.429-.004.859-.035 1.285-.092h28.482c.15.011.3.018.45.022.143-.004.285-.011.428-.022h.682v-.049a9.58 9.58 0 0 0 6.01-3.093 9.357 9.357 0 0 0 2.412-6.255v-.018a9.353 9.353 0 0 0-2.201-5.995 9.558 9.558 0 0 0-5.563-3.236 13.229 13.229 0 0 0-4.146-9.112 13.688 13.688 0 0 0-9.419-3.729Z"
            fill="url(#paint0_linear_1102_226868)"
          />
        </g>
        <g filter="url(#filter2_d_1102_226868)" className="pl-box-shadow--lighter">
          <rect x="128" y="327.916" width="63.283" height="63.283" rx="4" fill="#fff" shapeRendering="crispEdges" />
          <path
            d="m138.422 375.389 2.205 3.78a5.182 5.182 0 0 0 1.89 1.876l7.875-13.529h-15.75c0 .881.229 1.762.687 2.558l3.093 5.315Z"
            fill="#0066DA"
          />
          <path
            d="m159.642 351.599-7.876-13.529a5.19 5.19 0 0 0-1.89 1.876l-14.547 25.012a5.108 5.108 0 0 0-.687 2.558h15.75l9.25-15.917Z"
            fill="#00AC47"
          />
          <path
            d="M176.766 381.045a5.175 5.175 0 0 0 1.89-1.876l.917-1.563 4.381-7.532a5.113 5.113 0 0 0 .688-2.558H168.89l3.352 6.537 4.524 6.992Z"
            fill="#EA4335"
          />
          <path
            d="m159.642 351.599 7.875-13.529a5.062 5.062 0 0 0-2.578-.682h-10.595c-.917 0-1.804.255-2.578.682l7.876 13.529Z"
            fill="#00832D"
          />
          <path
            d="M168.891 367.516h-18.499l-7.875 13.529a5.06 5.06 0 0 0 2.577.682h29.095c.917 0 1.804-.256 2.577-.682l-7.875-13.529Z"
            fill="#2684FC"
          />
          <path
            d="m176.681 352.452-7.274-12.506a5.182 5.182 0 0 0-1.89-1.876l-7.875 13.529 9.249 15.917h15.722c0-.881-.229-1.762-.687-2.558l-7.245-12.506Z"
            fill="#FFBA00"
          />
        </g>
        <g filter="url(#filter3_d_1102_226868)" className="pl-box-shadow--lighter">
          <g clipPath="url(#clip0_1102_226868)">
            <path d="M446.283 9.334H383v62.52h63.283V9.334Z" fill="#000177" />
            <path
              d="M419.651 22.62c5.537-3.648 7.91-2.866 7.91 2.865 0 4.95-1.318 9.9-1.318 9.9h5.273s1.055-5.471 1.055-9.9c.264-8.596-3.955-10.42-7.383-10.42-6.856 0-16.612 7.815-29.532 23.706-4.746 5.991-9.756 13.025-12.92 17.453v9.118s14.502-20.32 22.413-29.437c5.8-6.512 10.81-10.941 14.502-13.286Z"
              fill="#33DBDB"
            />
            <path
              d="M403.302 44.762c-5.273 4.429-5.801 9.378-5.801 10.68 0 5.732 4.483 10.42 10.284 10.681h.527c15.294 0 20.567-20.058 20.831-21.1 0 0-5.274-.26-5.537.26-.264.521-.528 1.303-.791 2.084-2.637 6.773-7.383 13.546-14.503 13.546-2.9 0-5.537-2.084-5.537-5.21v-.26c0-1.824 1.055-4.429 3.955-7.034 4.746-3.907 15.03-7.815 37.97-4.428 0 0 1.055.26 1.582.26v-5.21c-14.239-2.084-32.96-2.605-42.98 5.731Z"
              fill="#33DBDB"
            />
          </g>
        </g>
        <path
          className="pl-box-shadow--lighter"
          d="M126 307a1 1 0 1 0 0-2v2ZM353.828 45.487a1 1 0 0 0-.262-1.39l-7.432-5.075a1 1 0 0 0-1.128 1.651l6.607 4.512-4.512 6.606a1 1 0 0 0 1.652 1.128l5.075-7.432ZM148 21c37.832 0 67.026 10.3 97.908 18.614 30.876 8.313 63.293 14.58 107.28 6.292l-.371-1.966c-43.568 8.21-75.653 2.018-106.389-6.257C215.698 29.411 186.168 19 148 19v2Zm-22 284c-44.706 0-76.843-17.743-97.159-44.295C8.503 234.124-.05 198.63 2.622 163.075 7.97 91.945 58.162 21 148 21v-2C56.837 19 6.03 91.055.628 162.925c-2.702 35.945 5.932 71.951 26.625 98.995C47.968 288.993 80.706 307 126 307v-2ZM471 41.924a1 1 0 0 0 0 2v-2ZM244.293 311.293a.999.999 0 0 0 0 1.414l6.364 6.364a.999.999 0 1 0 1.414-1.414L246.414 312l5.657-5.657a.999.999 0 1 0-1.414-1.414l-6.364 6.364ZM407 344c-46.85 0-71.748-8.216-92.668-16.431C293.35 319.331 276.175 311 245 311v2c30.734 0 47.559 8.169 68.601 16.431C334.702 337.716 359.85 346 407 346v-2Zm64-300.076c34.834 0 61.161 18.635 77.875 46.71 16.73 28.104 23.8 65.64 20.005 103.227-3.794 37.581-18.441 75.121-45.029 103.253C497.282 325.226 458.735 344 407 344v2c52.265 0 91.343-18.986 118.305-47.513 26.943-28.507 41.734-66.486 45.565-104.425 3.83-37.931-3.288-75.914-20.276-104.45C533.589 61.048 506.666 41.924 471 41.924v2Z"
          fill="#020039"
        />
        <defs>
          <filter
            id="filter0_d_1102_226868"
            x="201.21"
            y="351.112"
            width="89.808"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226868" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226868" result="shape" />
          </filter>
          <filter
            id="filter1_d_1102_226868"
            x="139.773"
            y="266.379"
            width="89.808"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226868" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226868" result="shape" />
          </filter>
          <filter
            id="filter2_d_1102_226868"
            x="114.738"
            y="319.296"
            width="89.808"
            height="89.807"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226868" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226868" result="shape" />
          </filter>
          <filter
            id="filter3_d_1102_226868"
            x="369.738"
            y=".713"
            width="89.807"
            height="89.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4.642" />
            <feGaussianBlur stdDeviation="6.631" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1102_226868" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1102_226868" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_1102_226868"
            x1="209.829"
            y1="312.587"
            x2="159.659"
            y2="311.883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3E82F4" />
            <stop offset="1" stopColor="#93DCF7" />
          </linearGradient>
          <clipPath id="clip0_1102_226868">
            <rect x="383" y="9.334" width="63.283" height="62.52" rx="4" fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
