import { ArrowLeft, ArrowRight, Trash } from '@air/icons';
import { FieldArray } from 'formik';
import { isEqual } from 'lodash';
import { SetStateAction } from 'react';
import { useUpdateEffect } from 'react-use';

import {
  HEADER_CTA_IMAGES,
  PlasmicHeaderNavigationItemType,
  PlasmicHeaderNavigationMenuColumn,
} from '../../../../constants/plasmic-navigation';
import { Form } from '../../../Form';
import { TEMPLATE_COLUMNS } from '../../../Header/HeaderMenu';
import { AdornmentIconNames } from '../../helpers';
import { Button } from './Button';
import { Input } from './Input';
import { NavigationItemColumn } from './NavigationItemColumn';

const allTemplates = Object.keys(TEMPLATE_COLUMNS).map((key) => ({ label: key, value: key }));
const templateOptions = [{ label: 'No template', value: '' }, ...allTemplates];

const allImages = Object.keys(HEADER_CTA_IMAGES).map((key) => ({
  label: key,
  value: HEADER_CTA_IMAGES[key as keyof typeof HEADER_CTA_IMAGES],
}));

const imageOptions = [{ label: 'Select image', value: '' }, ...allImages];

export type NavigationBuilderFormContentProps = {
  values: PlasmicHeaderNavigationItemType;
  initialValues: PlasmicHeaderNavigationItemType;
  setHasChanged: (hasChanged: boolean) => void;
  onDiscard: () => void;
  onMoveUpNavigationItem: () => void;
  onMoveDownNavigationItem: () => void;
  onRemoveNavigationItem: () => void;
  onDuplicateNavigationItem: () => void;
  setValues: (values: SetStateAction<PlasmicHeaderNavigationItemType>, shouldValidate?: boolean | undefined) => void;
};

export const NavigationBuilderFormContent = ({
  values,
  initialValues,
  setValues,
  onDiscard,
  setHasChanged,
  onMoveUpNavigationItem,
  onMoveDownNavigationItem,
  onRemoveNavigationItem,
  onDuplicateNavigationItem,
}: NavigationBuilderFormContentProps) => {
  useUpdateEffect(() => {
    setHasChanged(!isEqual(values, initialValues));
  }, [values, initialValues]);

  const onCreateCTA = () => {
    setValues({
      ...values,
      cta: {
        name: '',
        title: '',
        description: '',
        link: {
          isDemo: false,
          label: '',
          href: '',
        },
        image: {
          src: '',
          height: 160,
          width: 160,
          marginBottom: 0,
        },
      },
    });
  };

  const onDeleteCTA = () => {
    setValues({
      ...values,
      cta: undefined,
    });
  };

  return (
    <Form>
      <div>
        <div style={{ display: 'flex', gap: 10, marginBottom: 30 }}>
          <Button onClick={onDuplicateNavigationItem}>Duplicate</Button>
          <Button onClick={onMoveUpNavigationItem}>
            <ArrowLeft width={25} />
          </Button>
          <Button onClick={onMoveDownNavigationItem}>
            <ArrowRight width={25} />
          </Button>
          <Button variant="danger" onClick={onRemoveNavigationItem}>
            <Trash width={25} />
          </Button>
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <Input placeholder="label" name="name" />
          <Input placeholder="link" name="href" value={values.href || ''} />
          <Input name="template" as="select" placeholder="Template">
            {templateOptions.map((type) => (
              <option value={type.value} key={type.value}>
                {type.label}
              </option>
            ))}
          </Input>
          <Input name="color" placeholder="text color" />
        </div>
        <FieldArray
          name="columns"
          render={(arrayHelpers) => (
            <section style={{ paddingLeft: 50, marginBlock: 20 }}>
              {values.columns?.map((column, index) => (
                <NavigationItemColumn
                  key={index}
                  column={column}
                  parentField={`columns.${index}`}
                  onMoveUpCol={() => arrayHelpers.swap(index, index - 1)}
                  onMoveDownCol={() => arrayHelpers.swap(index, index + 1)}
                  onRemoveCol={() => arrayHelpers.remove(index)}
                  onAddNewRow={() => {
                    const newValue = { ...column, items: column.items || [] };
                    newValue.items.push({
                      name: '',
                      href: '',
                      adornment: {
                        iconName: '' as AdornmentIconNames,
                      },
                    });
                    arrayHelpers.replace(index, newValue);
                  }}
                  onRemoveRow={(rowIndexToRemove: number) => {
                    const newValue = { ...column, items: column.items || [] };
                    newValue.items.splice(rowIndexToRemove, 1);
                    arrayHelpers.replace(index, newValue);
                  }}
                  onMoveUpRow={(rowIndex: number) => {
                    const newValue = { ...column, items: column.items || [] };
                    const temp = newValue.items[rowIndex];
                    newValue.items[rowIndex] = newValue.items[rowIndex - 1];
                    newValue.items[rowIndex - 1] = temp;
                    arrayHelpers.replace(index, newValue);
                  }}
                  onMoveDownRow={(rowIndex: number) => {
                    const newValue = { ...column, items: column.items || [] };
                    const temp = newValue.items[rowIndex];
                    newValue.items[rowIndex] = newValue.items[rowIndex + 1];
                    newValue.items[rowIndex + 1] = temp;
                    arrayHelpers.replace(index, newValue);
                  }}
                  isFirstCol={index === 0}
                  isLastCol={!values.columns || index === values.columns.length - 1}
                />
              ))}
              <Button
                variant="success"
                onClick={() => {
                  const newGroup: PlasmicHeaderNavigationMenuColumn = {
                    name: '',
                    chunkSize: 4,
                    hasDivider: false,
                    items: [],
                  };
                  arrayHelpers.push(newGroup);
                }}
              >
                Add column
              </Button>
            </section>
          )}
        />
        <div style={{ marginBlock: 40 }}>
          {values.cta ? (
            <div style={{ background: '#f0f0f0', padding: '5px 20px 10px 10px', position: 'relative' }}>
              <h4 style={{ marginTop: 10, fontWeight: 500 }}>CTA Copy</h4>
              <div style={{ display: 'flex', gap: 15, width: '100%' }}>
                <Input placeholder="name" name="cta.name" style={{ flex: 1 }} />
                <Input placeholder="title" name="cta.title" style={{ flex: 1 }} />
                <Input placeholder="description" name="cta.description" style={{ flex: 2 }} />
              </div>
              <h4 style={{ marginTop: 10, fontWeight: 500 }}>CTA Link</h4>
              <div style={{ display: 'flex', gap: 15 }}>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <span style={{ opacity: !!values.cta.link.href ? 0.5 : 1 }}>Demo</span>
                  <Input
                    disabled={!!values.cta.link?.href}
                    type="checkbox"
                    name="cta.link.isDemo"
                    placeholder="Is this demo?"
                  />
                </div>
                <Input name="cta.link.label" placeholder="link text" />
                <Input
                  disabled={!!values.cta.link.isDemo}
                  placeholder="link"
                  name="cta.link.href"
                  value={values.cta.link.href || ''}
                  style={{ flexBasis: '30%' }}
                />
              </div>
              <h4 style={{ marginTop: 10, fontWeight: 500 }}>CTA Image</h4>
              <div style={{ display: 'flex', gap: 15 }}>
                <Input name="cta.image.src" as="select" placeholder="Src">
                  {imageOptions.map((image) => (
                    <option value={image.value} key={image.value} disabled={!image.value}>
                      {image.label}
                    </option>
                  ))}
                </Input>
                <Input placeholder="height" name="cta.image.height" type="number" />
                <Input placeholder="width" name="cta.image.width" type="number" />
                <Input placeholder="marginBottom" name="cta.image.marginBottom" type="number" />
              </div>
              <Button onClick={onDeleteCTA} variant="danger" style={{ position: 'absolute', top: -10, right: -10 }}>
                Delete
              </Button>
            </div>
          ) : (
            <Button onClick={onCreateCTA} variant="success">
              Add CTA
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', gap: 20, marginTop: 30 }}>
          <Button variant="danger" onClick={onDiscard} style={{ padding: '5px 10px' }}>
            Discard
          </Button>
          <Button variant="success" type="submit" style={{ padding: '5px 10px' }}>
            Save changes
          </Button>
        </div>
      </div>
    </Form>
  );
};
