import { Box, BoxProps, Text } from '@air/zephyr';
import { forwardRef, ReactNode } from 'react';

export interface HeaderMobileMenuPanelNavigationItemProps extends Pick<BoxProps<'a'>, 'children' | 'onClick' | 'tx'> {
  leftAdornment?: ReactNode;
}

export const HeaderMobileMenuPanelNavigationItem = forwardRef<
  HTMLAnchorElement,
  HeaderMobileMenuPanelNavigationItemProps
>(({ children, leftAdornment, tx, ...restOfProps }: HeaderMobileMenuPanelNavigationItemProps, ref) => {
  return (
    <Text
      as="a"
      ref={ref}
      tx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        width: '100%',
        border: 0,
        mb: 12,
        color: 'jay800',
        fontWeight: 'semibold',
        textDecoration: 'none',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },

        ...tx,
      }}
      variant="text-ui-16"
      {...restOfProps}
    >
      {leftAdornment && <Box tx={{ mr: 12 }}>{leftAdornment}</Box>}
      {children}
    </Text>
  );
});

HeaderMobileMenuPanelNavigationItem.displayName = 'HeaderMobileMenuPanelNavigationItem';
