import { BoxProps, Text } from '@air/zephyr';
import Link from 'next/link';
import { forwardRef } from 'react';

export interface HeaderMobileMenuNavigationItemProps
  extends Pick<BoxProps<'a' | 'button'>, 'as' | 'children' | 'onClick' | 'tx'> {
  href?: string;
}

export const HeaderMobileMenuNavigationItem = forwardRef<HTMLAnchorElement, HeaderMobileMenuNavigationItemProps>(
  ({ children, tx, href, ...restOfProps }: HeaderMobileMenuNavigationItemProps, ref) => {
    const as = restOfProps.as || (href ? 'a' : 'div');
    const navItem = (
      <Text
        as={as}
        ref={ref}
        tx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          width: '100%',
          border: 0,
          p: 0,
          mb: 24,
          color: 'jay700',
          fontWeight: 'medium',
          textDecoration: 'none',
          cursor: 'pointer',

          '&:hover': {
            textDecoration: 'underline',
          },

          ...tx,
        }}
        variant="text-heading-24"
        {...restOfProps}
      >
        {children}
      </Text>
    );
    if (as === 'a' && !!href) {
      return (
        <Link href={href} passHref>
          {navItem}
        </Link>
      );
    }
    return navItem;
  },
);

HeaderMobileMenuNavigationItem.displayName = 'HeaderMobileMenuNavigationItem';
