/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';

export const PPMori = () => {
  return (
    <Head>
      <link href="/fonts/pp-mori/pp-mori.css" rel="stylesheet" key="pp-mori" />
    </Head>
  );
};
