import Link from 'next/link';
import { useEffect, useState } from 'react';

import { CountDown } from '~/marketing/components/paige/CountDown';
import useTailwindBreakpoint from '~/marketing/utils/useTailwindBreakpoint';

export type ApplyProps = {
  applicationLink: string;
};

export const Apply = ({ applicationLink }: ApplyProps) => {
  let tailwindbrk: any = useTailwindBreakpoint();

  let [currentBg, updateBg] = useState("url('/assets/paige/apply-bg.svg')");

  useEffect(() => {
    if (['sm', 'md'].includes(tailwindbrk)) {
      updateBg("url('/assets/paige/apply-bg.svg')");
    } else {
      updateBg("url('/assets/paige/apply-bg-desktop.svg')");
    }
  }, [tailwindbrk]);

  return (
    <div
      id="apply"
      className="bg-no-repeat sm:bg-cover lg:bg-auto lg:bg-center"
      style={{
        backgroundImage: currentBg,
        backgroundPositionY: '-300px',
      }}
    >
      <div className="pt-6 px-4 xl:max-w-screen-3xl xl:mx-auto xl:grid xl:grid-cols-2 xl:gap-6 xl:pt-20">
        <div className="rounded-3xl overflow-hidden bg-gradient-to-br from-[#A486B1] to-[#7594C2] py-14 px-6 text-white relative z-10 xl:p-16">
          <h2 className="text-header-lg font-display font-light mb-10 xl:text-[90px] xl:leading-[85px]">
            Apply to Our Design Program
          </h2>
          <div className="xl:grid xl:grid-cols-2 xl:gap-6">
            <div>
              <p className="text-body-md font-body">Application Deadline:</p>
              <p className="text-body-lg font-display mb-6">Nov 15th, 2023</p>
            </div>
            <div>
              <p className="text-body-md font-body">Selecting:</p>
              <p className="text-body-lg font-display mb-6">10 applicants</p>
            </div>
            <div>
              <p className="text-body-md font-body">Cost:</p>
              <p className="text-body-lg font-display mb-6">$2,000 / month</p>
            </div>
            <div>
              <p className="text-body-md font-body">Accepted partners get:</p>
              <p className="text-body-lg font-display">50TB &amp; 50 users</p>
            </div>
          </div>
        </div>
        <div className="backdrop-blur-[15px] -mt-16 pt-32 px-14 bg-[#E9F2FD] bg-opacity-40 pb-14 -mx-4 xl:m-0 xl:p-20 xl:rounded-[24px] xl:overflow-hidden xl:flex xl:flex-col xl:justify-center">
          <p className="font-body text-20 text-pale-blue-950 leading-8 mb-8">
            Starting October 1st, we’ll be conducting a forty-five day search for design partners, offering early access
            to Paige, discounted access to Air for Enterprise, and the opportunity to customize our AI features around
            specific use cases.
          </p>
          <p className="font-body text-20 text-pale-blue-950 leading-8 mb-8">
            Each brand will be paired with a designer, a product manager, and a dedicated creative ops consultant. Only
            ten applicants will be selected.
          </p>
          <Link href={applicationLink}>
            <a
              className="capitalize rounded-full bg-pale-blue-950 py-4 px-8 text-white font-body text-base leading-5 text-center"
              target="_blank"
            >
              Apply To Gain Access
            </a>
          </Link>
        </div>
      </div>

      <CountDown />
    </div>
  );
};
