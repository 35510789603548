import { AspectRatio } from '@air/marketing-primitives';
import { usePlasmicCanvas } from '@air/plasmic';
import { AnimatePresence, useReducedMotion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { BlurbData, SectionData } from '~/lib/contentful/custom-types';
import { Container } from '~/old-marketing/components/Container';
import { Section } from '~/old-marketing/components/sections/Section';
import { SectionTitle } from '~/old-marketing/components/sections/SectionTitle';
import * as bossAnimationData from '~/old-marketing/components/sections/templates/HomeScaryQuotesSectionTemplate/home-scary-boss.json';
import * as mindlessAnimationData from '~/old-marketing/components/sections/templates/HomeScaryQuotesSectionTemplate/home-scary-mindless.json';
import * as trackingAnimationData from '~/old-marketing/components/sections/templates/HomeScaryQuotesSectionTemplate/home-scary-tracking.json';
import { PreviewModeProvider } from '~/providers/PreviewModeProvider';

import { HomeScaryQuotesSectionItemProvider } from './HomeScaryQuotesSectionItemProvider';
import * as HomeScaryQuotesSectionTemplateBlurb from './HomeScaryQuotesSectionTemplateBlurb.styles';

const Lottie = dynamic(() => import('react-lottie'), {
  ssr: false,
});

const allAnimationData = [
  { id: 'mindless', data: mindlessAnimationData },
  { key: 'tracking', data: trackingAnimationData },
  { id: 'boss', data: bossAnimationData },
];

const TEMPLATE_KEY = 'home-scary-quotes';

const TEXT_ANIMATION_VARIANTS = {
  initial: { opacity: 0, y: '50%' },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: '-90%' },
  reduceMotionInitial: { opacity: 0 },
  reduceMotionVisible: { opacity: 1 },
  reduceMotionExit: { opacity: 0 },
};

const LOTTIE_ANIMATION_VARIANTS = {
  initial: { opacity: 0, transition: { delay: 0 } },
  visible: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0 } },
};

export type HomeScaryQuotesSectionTemplateProps = Pick<SectionData, 'id'> & {
  references?: BlurbData[];
  /**
   * @default 1
   */
  version?: number;
  /**
   * Id of which blurb to show
   * @default undefined
   */
  blurbIdToShow?: string;
  children?: ReactNode;
};

export const HomeScaryQuotesSectionTemplate = ({
  id,
  version = 1,
  references: blurbs,
  blurbIdToShow,
  children,
}: HomeScaryQuotesSectionTemplateProps) => {
  const [isInView, setIsInView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const shouldReduceMotion = useReducedMotion();
  const { isEditor } = usePlasmicCanvas();

  useEffect(() => {
    if (!blurbs) {
      return;
    }

    if (isInView) {
      let blurbAnimationTimer: NodeJS.Timer;

      if (isEditor && blurbIdToShow) {
        const idx = blurbs.findIndex(({ id }) => id === blurbIdToShow);
        setActiveIndex(idx);
      } else {
        blurbAnimationTimer = setInterval(() => {
          setActiveIndex((prevState) => (prevState < blurbs.length - 1 ? prevState + 1 : 0));
        }, 5200);
      }
      return () => {
        clearInterval(blurbAnimationTimer);
      };
    }
  }, [blurbIdToShow, blurbs, isEditor, isInView, setActiveIndex]);

  // @TODO: need to decide on background image animations if any, no background noodle on mobile in figma
  const backgroundImage = blurbs?.[0].backgroundImages?.[0];

  const animationDataIndex = useMemo(
    () => (activeIndex < allAnimationData.length ? activeIndex : activeIndex % allAnimationData.length),
    [activeIndex],
  );

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: allAnimationData[animationDataIndex].data,
  };

  return (
    <PreviewModeProvider value={{ isPreview: false }}>
      <Section
        css={{
          backgroundColor: '$macaw300',

          '@desktop': {
            backgroundImage: backgroundImage ? `url(${backgroundImage?.file.url})` : undefined,
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
          },
        }}
        id={id}
        onInViewChange={setIsInView}
        templateKey={TEMPLATE_KEY}
      >
        <Container>
          {blurbs && (
            <HomeScaryQuotesSectionTemplateBlurb.Root>
              <AnimatePresence exitBeforeEnter>
                {blurbs.map((blurb, index) => {
                  const isActiveIndex = activeIndex === index;
                  if (!isActiveIndex) {
                    return null;
                  }
                  return (
                    <HomeScaryQuotesSectionTemplateBlurb.TextAnimation
                      key={blurb.id}
                      initial={shouldReduceMotion ? 'reduceMotionInitial' : 'initial'}
                      animate={shouldReduceMotion ? 'reduceMotionVisible' : 'visible'}
                      exit={shouldReduceMotion ? 'reduceMotionExit' : 'exit'}
                      transition={{
                        duration: 1.6,
                        type: 'spring',
                      }}
                      variants={TEXT_ANIMATION_VARIANTS}
                    >
                      <HomeScaryQuotesSectionTemplateBlurb.TextAnimationContent>
                        {version === 2 && !blurb.title ? (
                          <HomeScaryQuotesSectionItemProvider idToRender={blurb.id}>
                            {children}
                          </HomeScaryQuotesSectionItemProvider>
                        ) : (
                          <>
                            <HomeScaryQuotesSectionTemplateBlurb.Label>
                              {blurb.label}
                            </HomeScaryQuotesSectionTemplateBlurb.Label>
                            <SectionTitle css={{ color: '$white' }} size="heading-72">
                              {blurb.title}
                            </SectionTitle>
                          </>
                        )}
                      </HomeScaryQuotesSectionTemplateBlurb.TextAnimationContent>
                    </HomeScaryQuotesSectionTemplateBlurb.TextAnimation>
                  );
                })}
              </AnimatePresence>
              <AnimatePresence>
                {allAnimationData.map((animationData, index) => {
                  const isActiveIndex = animationDataIndex === index;
                  if (!isActiveIndex) {
                    return null;
                  }
                  return (
                    <HomeScaryQuotesSectionTemplateBlurb.LottieAnimation
                      key={animationData.id}
                      initial="initial"
                      animate="visible"
                      exit="exit"
                      transition={{
                        delay: 0.6,
                        duration: 2,
                        type: 'tween',
                      }}
                      variants={LOTTIE_ANIMATION_VARIANTS}
                    >
                      <AspectRatio ratio={1112 / 940}>
                        <Lottie options={defaultLottieOptions} isPaused={!isInView} />
                      </AspectRatio>
                    </HomeScaryQuotesSectionTemplateBlurb.LottieAnimation>
                  );
                })}
              </AnimatePresence>
            </HomeScaryQuotesSectionTemplateBlurb.Root>
          )}
        </Container>
      </Section>
    </PreviewModeProvider>
  );
};

HomeScaryQuotesSectionTemplate.templateKey = TEMPLATE_KEY;
