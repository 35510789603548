import { Text } from '@air/marketing-primitives';
import { DemoDialog } from '@air/marketing-tech-debt/src/components/dialogs/DemoDialog';
import { COMPONENT_META, Video } from '@air/plasmic/src/components/react-player/Video';
import { Formik } from 'formik';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { object, string } from 'yup';

type propsFromMetaType = {
  [key: string]: {
    defaultValue: any;
  };
};

const validationSchema = object()
  .shape({
    email: string().email('Please enter a valid email address').required('Please enter a valid email address'),
  })
  .required()
  .default({ email: '' });

const videoProps = Object.entries(COMPONENT_META.props as propsFromMetaType)
  .map(([key, value]: [string, propsFromMetaType]) => {
    return {
      [key]: value?.defaultValue,
    };
  })
  .reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  });

export type RichText = {
  __html: string;
};

export type FiftyFiftySignUpVideoProps = {
  headline?: string;
  linkHeader?: string;
  linkLabel?: string;
  submitLabel?: string;
  videoUrl?: string;
  videoType: 'wistia' | 'youtube' | 'air';
};

export const FiftyFiftySignUpVideo = ({
  headline,
  linkHeader,
  linkLabel,
  submitLabel,
  videoUrl,
  videoType,
}: FiftyFiftySignUpVideoProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });
  const [isDemoDialogOpened, setIsDemoDialogOpened] = useState(false);

  return (
    <>
      <div className="flex flex-col lg:flex-row flex-nowrap py-[72px] lg:py-[144px] overflow-x-hidden px-8">
        <div className="order-2 lg:order-1 text-jay-900 flex-grow flex-shrink-0 lg:basis-1/2 lg:my-auto">
          <div className="max-w-[432px] lg:max-w-none lg:min-w-[432px] lg:w-[calc(100%*(504/(1440/2)))] mx-auto text-center lg:text-left">
            <h2 className="font-display text-header-md font-semibold lg:text-header-lg mb-12">{headline}</h2>
            <Formik
              initialValues={validationSchema.cast(undefined)!}
              onSubmit={(values) => {
                if (values.email) {
                  window.location.href = `https://app.air.inc/sign-up?email=${encodeURIComponent(values.email)}`;
                } else {
                  window.location.href = 'https://app.air.inc/sign-up';
                }
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit} className="w-full mb-12 lg:flex flex-row flex-nowrap relative">
                  <input
                    name="email"
                    placeholder="Work email address"
                    required
                    onChange={handleChange}
                    value={values.email}
                    className="pl-input-text lg:rounded-tr-none lg:rounded-br-none mb-8 lg:mb-0 pl-box-shadow lg:h-auto"
                  />
                  <button
                    className="pl-button pl-button--submit lg:rounded-tl-none lg:rounded-bl-none whitespace-nowrap mx-auto mb-12 lg:ml-0 lg:mb-0 text-18 h-full"
                    type="submit"
                  >
                    {submitLabel}
                  </button>
                </form>
              )}
            </Formik>
            <div className="flex flex-col items-center lg:items-start">
              <Text as="p" className="text-24 text-jay-900 mb-1">
                {linkHeader}
              </Text>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setIsDemoDialogOpened(true);
                }}
                className="text-24 text-jay-500 font-semibold w-fit relative block after:block after:content-[''] after:absolute after:h-[3px] after:bg-jay-500 after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                {linkLabel}
              </button>
            </div>
          </div>
        </div>
        <div
          ref={ref}
          className="order-1 lg:order-2 lg:pl-0 flex-grow flex-shrink-0 lg:basis-1/2 aspect-video h-fit mb-10 lg:mb-0 rounded-[8px] pl-box-shadow overflow-hidden"
        >
          {inView && (
            <Video
              {...videoProps}
              className="w-full h-full overflow-hidden"
              ratioHeight={900}
              ratioWidth={1600}
              type={videoType}
              autoPlay={true}
              loop={true}
              muted={false}
              controls={true}
              wistiaAutoPlay={true}
              urlVideo={videoUrl}
              wistiaUrl={videoUrl}
              wistiaFitStrategy="cover"
              wistiaPlaybar={false}
              wistiaMuted={true}
              wistiaPlayButton={false}
              wistiaEndVideoBehavior="loop"
              wistiaControlsVisibleOnLoad={false}
              wistiaPlaysInline={true}
            />
          )}
        </div>
      </div>
      <DemoDialog isOpen={isDemoDialogOpened} onDismiss={() => setIsDemoDialogOpened(false)} />
    </>
  );
};
