import { COMPONENT_META, Video } from '@air/plasmic/src/components/react-player/Video';
import { TypeAnimation } from 'react-type-animation';

import useTailwindBreakpoint from '~/marketing/utils/useTailwindBreakpoint';

type propsFromMetaType = {
  [key: string]: {
    defaultValue: any;
  };
};

const videoProps = Object.entries(COMPONENT_META.props as propsFromMetaType)
  .map(([key, value]: [string, propsFromMetaType]) => {
    return {
      [key]: value?.defaultValue,
    };
  })
  .reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    };
  });

export type VideoTextProps = {
  videoUrlMobile?: string;
  videoUrlDesktop?: string;
  videoType: 'wistia' | 'youtube' | 'air';
};

export const VideoText = ({ videoUrlMobile, videoUrlDesktop, videoType }: VideoTextProps) => {
  let tailwindbrk: any = useTailwindBreakpoint();

  return (
    <div>
      <div className="mx-4 lg:w-10/12 2xl:w-3/5 max-w-screen-lg lg:mx-auto ">
        <div className="relative w-[362px] h-[910px] lg:pb-[65.898%] lg:w-full lg:h-0 z-20 text-white mx-auto font-display text-center rounded-3xl overflow-hidden max-w-screen-3xl 3xl:mx-auto">
          <div className="absolute inset-0 flex justify-end">
            {/* mobile aspect ratio - 2.5138121547 */}
            {/* desktop aspect ratio  - 0.6589861751*/}
            {['sm', 'md', 'lg'].includes(tailwindbrk) ? (
              <Video
                {...videoProps}
                className="w-full rounded-[30px] overflow-hidden"
                ratioHeight={362}
                ratioWidth={910}
                type={videoType}
                autoPlay={true}
                loop={true}
                muted={true}
                controls={false}
                wistiaAutoPlay={true}
                wistiaUrl={videoUrlMobile}
                wistiaFitStrategy="cover"
                wistiaPlaybar={false}
                wistiaMuted={true}
                wistiaPlayButton={false}
                wistiaEndVideoBehavior="loop"
                wistiaControlsVisibleOnLoad={false}
                wistiaPlaysInline={true}
              />
            ) : (
              <Video
                {...videoProps}
                className="w-full h-full rounded-[30px] overflow-hidden"
                ratioHeight={1302}
                ratioWidth={858}
                type={videoType}
                autoPlay={true}
                loop={true}
                muted={true}
                controls={false}
                wistiaAutoPlay={true}
                wistiaUrl={videoUrlDesktop}
                wistiaFitStrategy="cover"
                wistiaPlaybar={false}
                wistiaMuted={true}
                wistiaPlayButton={false}
                wistiaEndVideoBehavior="loop"
                wistiaControlsVisibleOnLoad={false}
                wistiaPlaysInline={true}
              />
            )}
          </div>
          <div className="absolute inset-x-6 top-0 pt-20 lg:pt-0 lg:top-[6.1%] lg:flex lg:justify-center">
            <p className="text-32 lg:mr-2">Paige helps with </p>
            <p className="font-body font-bold text-pale-blue text-32">
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  'show me photos of products',
                  850, // wait 1s before replacing "Mice" with "Hamsters"
                  'find photos of Luca',
                  760,
                  'crop photo to a square',
                  850,
                ]}
                wrapper="span"
                speed={10}
                repeat={Infinity}
                omitDeletionAnimation={true}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full relative -mt-72 z-10 px-10 rounded-b-[30px] overflow-hidden">
        <div
          className="absolute inset-0 flex justify-end bg-cover"
          style={{ backgroundImage: "url('/assets/paige/video-text.svg')" }}
        ></div>

        <p className="relative z-20 pt-80 pb-20 max-w-screen-3xl mx-auto lg:px-14 bg-gradient-to-b from-white from-70% to-pale-blue-950 to-95% text-transparent bg-clip-text bg-fixed">
          <span className="text-[34px] leading-[42px] font-display font-light mb-4 mt-6 md:text-[58px] md:leading-[64px] md:mb-8 xl:text-[90px] xl:leading-[95px] lg:mb-14 lg:pt-6 block">
            When the little tasks are automated, the big picture becomes clear.
          </span>
          <span className="text-[24px] font-display font-body font-light leading-[34px] md:text-[38px] md:leading-[48px] xl:text-[58px] xl:leading-[70px] lg:pb-10 block">
            Paige is an AI enhancement to Air’s Enterprise product. It thinks and executes creative requests the same
            way your creative team does. Air’s software is already smart, but Paige brings it to “favorite coworker”
            levels of responsiveness.
          </span>
        </p>
      </div>
    </div>
  );
};
