import 'keen-slider/keen-slider.min.css';

import { Video } from '@air/plasmic/src/components/react-player/Video';
import { motion } from 'framer-motion';
import { useKeenSlider } from 'keen-slider/react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { videoTabsData } from '~/marketing/constants/VideoTabs';

// type VideoTab = {
//   headline: string;
//   copy: string;
//   video: VideoProps;
// };

export const VideoTabs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(true);
  const [slideCount, setSlideCount] = useState(0);

  const tabs = videoTabsData;

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    rubberband: false,
    slides: {
      perView: 1.2,
      origin: 'center',
    },
    defaultAnimation: {
      duration: 650,
    },
    slideChanged(slider) {
      if (!slider) return;
      setActiveIndex(slider.track.details.rel);
    },
    animationStarted() {
      setAnimationEnded(false);
    },
    animationEnded() {
      setAnimationEnded(true);
    },
    created(slider) {
      setLoaded(true);
      setSlideCount(slider.slides.length);
    },
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });
  const handleSelect = (index: number) => {
    instanceRef.current?.moveToIdx(index);
  };

  return (
    <div className="py-[72px] text-jay-900 relative overflow-x-hidden">
      <div ref={ref}>
        <div className="w-full mx-auto relative">
          <div className="keen-slider" ref={sliderRef}>
            {tabs?.map((tab, index) => {
              return (
                <div key={`video-wrapper-${index}`} className="keen-slider__slide w-full bg-white">
                  <div className="flex justify-center items-start max-w-full overflow-hidden">
                    {/* set wrapper aspect to largest width and height of design vids to avoid layout shift on tab select */}
                    <div className="aspect-[1440/676] min-w-[400px] max-w-[1196px] w-3/4 ">
                      <Video
                        {...tab.video}
                        className="h-full w-full object-cover"
                        type={tab.video.type}
                        autoPlay={true}
                        ratioWidth={1196}
                        ratioHeight={574}
                        loop={true}
                        muted={true}
                        controls={false}
                        wistiaAutoPlay={true}
                        urlVideo={tab.video.urlVideo}
                        wistiaUrl={tab.video.urlVideo}
                        useAspectRatio={true}
                        wistiaFitStrategy="cover"
                        wistiaPlaybar={false}
                        wistiaMuted={true}
                        wistiaPlayButton={false}
                        wistiaEndVideoBehavior="loop"
                        wistiaControlsVisibleOnLoad={false}
                        wistiaPlaysInline={true}
                        playOnVisible={inView && index === activeIndex}
                      />
                    </div>
                  </div>

                  <div className="lg:hidden relative">
                    <div className="pr-6 relative">
                      <div
                        className="bg-jay-900 opacity-20 absolute top-0 left-0 right-0 h-1 w-full"
                        style={{
                          transform:
                            index === 0
                              ? 'translateX(2rem)'
                              : index === slideCount - 1
                              ? 'translateX(-2rem)'
                              : 'translateX(0)',
                        }}
                      ></div>
                      <div
                        className={`border-t-[8px] border-jay-900 transition-colors duration-200 ${
                          activeIndex === index ? 'opacity-100' : 'opacity-50'
                        }`}
                      >
                        <button
                          className="my-6 text-body-lg font-display font-semibold"
                          onClick={() => handleSelect(index)}
                        >
                          {tab.headline}
                        </button>
                        <p className="text-body-md">{tab.copy}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="hidden lg:grid max-w-[1248px] mx-auto grid-cols-3 px-8 absolute -bottom-2 left-0 right-0">
            <motion.div
              initial={false}
              layout
              className={`h-2 col-span-1 bg-jay-900`}
              style={{
                gridColumnStart: activeIndex + 1,
                maxWidth: activeIndex === tabs.length - 1 ? '100%' : '90%',
              }}
            ></motion.div>
          </div>
        </div>
        <div className="hidden lg:block max-w-[1248px] mx-auto px-8">
          <div className="grid grid-cols-3 pt-6 border-t-[4px] border-jay-900 border-opacity-20">
            {tabs &&
              loaded &&
              instanceRef.current &&
              tabs.map((tab, index) => {
                return (
                  <div
                    onClick={() => handleSelect(index)}
                    key={`tab--${index}`}
                    className={`pr-4 transition-all hover:opacity-100 duration-200 ease-out cursor-pointer text-jay-900 ${
                      activeIndex === index && animationEnded ? 'opacity-100' : 'opacity-60'
                    }`}
                  >
                    <div className="text-header-md font-display font-semibold mb-5 max-w-[355px] mx-auto">
                      {tab.headline}
                    </div>
                    <motion.p
                      initial={{ opacity: activeIndex === index && animationEnded ? 1 : 0.5 }}
                      animate={{ opacity: activeIndex === index && animationEnded ? 1 : 0.5 }}
                      transition={{
                        ease: 'easeOut',
                        duration: 0.25,
                      }}
                      className="text-body-md lg:pr-10 2xl:pr-16 max-w-[355px] mx-auto"
                    >
                      {tab.copy}
                    </motion.p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
