import { styled } from '@air/marketing-primitives';
import { motion } from 'framer-motion';

import { clamp } from '~/utils/clamp';

export const Root = styled('div', {
  height: 586,
  paddingY: 0,
  position: 'relative',
  width: '100%',

  '@desktop': { height: 472, paddingY: 68 },
});

export const TextAnimation = styled(motion.div, {
  display: 'flex',
  paddingTop: 64,
  position: 'absolute',

  '@desktop': {
    paddingTop: 48,
  },
});

export const TextAnimationContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 686,
});

export const Label = styled('text', {
  color: '$cuckoo200',
  fontSize: clamp(14, 18),
  textTransform: 'uppercase',
  fontWeight: '$bold',
  marginBottom: 24,

  '@desktop': {
    marginBottom: 16,
  },
});

export const LottieAnimation = styled(motion.div, {
  bottom: 0,
  maxHeight: '100%',
  opacity: 0,
  position: 'absolute',
  right: 0,
  width: '100%',
  zIndex: -1,

  // @TODO: check this responsive styling
  '@tablet': { width: '60%' },
  '@desktop': {
    width: '55%',
  },
});
