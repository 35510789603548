import { Plus } from '@air/icons';
import { useState } from 'react';

import { PlasmicHeaderNavigationItemType } from '../../../constants/plasmic-navigation';
import { Button } from './form/Button';
import { NavigationBuilderForm } from './form/NavigationBuilderForm';
import { NavigationBuilderModalProps } from './NavigationBuilderModal';

export default function NavigationModalContent({
  navigation,
  updateNavigation,
  closeModal,
}: Omit<NavigationBuilderModalProps, 'open'>) {
  const navs = navigation.map((nav, index) => ({ name: nav.name, index }));
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);

  const onSubmit = (values: PlasmicHeaderNavigationItemType) => {
    if (hasChanged) {
      const navigationNew = [...navigation];
      navigationNew[activeIndex] = values;
      updateNavigation(navigationNew);
      setHasChanged(false);
    }
  };

  const onAddNavigationItem = () => {
    if (hasChanged) {
      alert('Please save your changes before adding a new navigation item.');
    } else {
      const navigationNew = [...navigation];
      navigationNew.push({ name: 'New Navigation', href: '' });
      updateNavigation(navigationNew);
      setActiveIndex(navigationNew.length - 1);
    }
  };

  const onMoveUpNavigationItem = () => {
    if (hasChanged) {
      alert('Please save or discard your changes before changing order');
    }
    if (activeIndex > 0) {
      const navigationNew = [...navigation];
      const temp = navigationNew[activeIndex - 1];
      navigationNew[activeIndex - 1] = navigationNew[activeIndex];
      navigationNew[activeIndex] = temp;
      updateNavigation(navigationNew);
      setActiveIndex(activeIndex - 1);
    }
  };

  const onMoveDownNavigationItem = () => {
    if (hasChanged) {
      alert('Please save or discard your changes before changing order');
    } else if (activeIndex < navigation.length - 1) {
      const navigationNew = [...navigation];
      const temp = navigationNew[activeIndex + 1];
      navigationNew[activeIndex + 1] = navigationNew[activeIndex];
      navigationNew[activeIndex] = temp;
      updateNavigation(navigationNew);
      setActiveIndex(activeIndex + 1);
    }
  };

  const onRemoveNavigationItem = () => {
    const navigationNew = [...navigation];
    navigationNew.splice(activeIndex, 1);
    updateNavigation(navigationNew);
    if (activeIndex === navigationNew.length) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onDuplicateNavigationItem = () => {
    if (hasChanged) {
      alert('Please save or discard your changes before duplicating');
    } else {
      const navigationNew = [...navigation];
      navigationNew.splice(activeIndex + 1, 0, navigationNew[activeIndex]);
      updateNavigation(navigationNew);
    }
  };

  const onSwitchTabs = (navIndex: number) => {
    if (hasChanged) {
      alert('Please save or discard your changes before switching tabs');
      return;
    } else {
      setActiveIndex(navIndex);
    }
  };

  const onClose = () => {
    if (hasChanged) {
      alert('Please save or discard your changes before closing modal');
      return;
    } else {
      closeModal();
    }
  };

  return (
    <div
      style={{
        boxSizing: 'border-box',
        backgroundColor: 'white',
        marginInline: 'auto',
        marginBlock: 20,
        padding: '20px 40px',
        width: '90%',
        maxWidth: 1200,
        overflowY: 'auto',
      }}
    >
      <div style={{ display: 'flex', marginBottom: 20, gap: 10, position: 'relative' }}>
        {navs.map((nav) => (
          <Button
            onClick={() => onSwitchTabs(nav.index)}
            style={{ background: nav.index === activeIndex ? 'aquamarine' : '' }}
          >
            {nav.name}
          </Button>
        ))}
        <Button onClick={onAddNavigationItem}>
          <Plus width={25} />
        </Button>
        <Button
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 20,
            top: 10,
            background: 'transparent',
            color: 'red',
            border: '1px solid red',
            fontSize: 20,
            padding: '5px 10px',
          }}
        >
          X
        </Button>
      </div>
      {navigation.length > 0 && navigation.length > activeIndex && (
        <NavigationBuilderForm
          navigationItem={navigation[activeIndex]}
          onSubmit={onSubmit}
          setHasChanged={setHasChanged}
          onMoveUpNavigationItem={onMoveUpNavigationItem}
          onMoveDownNavigationItem={onMoveDownNavigationItem}
          onRemoveNavigationItem={onRemoveNavigationItem}
          onDuplicateNavigationItem={onDuplicateNavigationItem}
        />
      )}
    </div>
  );
}
